import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { DadosPluviometriaRelatorioSudene } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../services/relatorios.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DateTimeUtils } from '@utils/datetime-util';
import { numberToBrNumber, verificaSePDF } from '@utils';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';

type Localizacao = {
	latitude: number;
	longitude: number;
	altitude: number;
};

type RelatorioSudenePosto = {
	posto: string;
	dados: Omit<DadosPluviometriaRelatorioSudene, 'posto'>[];
	total: () => number;
	localizacao: Localizacao;
};

@Component({
	selector: 'seira-tabela-relatorio-sudene',
	templateUrl: './tabela-relatorio-sudene.component.html',
	styleUrls: ['./tabela-relatorio-sudene.component.scss'],
})
export class TabelaRelatorioSudeneComponent
	implements InstanciaRelatorio, OnInit, AfterViewChecked, OnDestroy
{
	inputs = inject(INPUTS_RELATORIOS);
	@Input() dadosSudene?: DadosPluviometriaRelatorioSudene[];
	@Input() dadosSudeneSeparadosPorPosto?: RelatorioSudenePosto[];
	@ViewChild('tabelaRelatorioSudene', { static: false })
	tabela?: PublicTableComponent;
	taCarregando = false;
	private subscription = new Subscription();
	tituloTabela = '';
	tipoPeriodoRelatorioGerado?: string;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT();
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	readonly colunas: ADTColumns[] = [
		{
			data: 'data',
			title: 'Data',
			type: 'date',
			className: 'text-center',
			render(data, tipo) {
				const [somenteData] = data.split('T');
				const displayData = somenteData.split('-').reverse().join('/');
				return tipo === 'display' || tipo === 'filter' ? displayData : data;
			},
		},
		{
			data: 'valor',
			title: 'Precipitação (mm)',
			type: 'num-fmt',
			className: 'text-center',
			render: texto => (texto as number).toFixed(1).replaceAll('.', ','),
		},
	];

	descricaoRelatorio =
		'O histórico da SUDENE refere-se ao conjunto de dados pluviométricos diários e mensais pertencentes a todo o banco de dados da SUDENE para o estado da Paraíba.';

	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.setValidators();
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	private get tipoPeriodo(): string {
		return this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	setValidators(): void {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.POSTO_SUDENE)
			?.setValidators(Validators.required);
	}

	private limparValidador(campoForm: FormularioRelatorio): void {
		this.inputs.form.get(campoForm)?.clearValidators();
		this.inputs.form.get(campoForm)?.setErrors(null);
	}

	ngOnDestroy(): void {
		this.limparValidador(FormularioRelatorio.DATA_INICIO);
		this.limparValidador(FormularioRelatorio.DATA_FIM);
		this.limparValidador(FormularioRelatorio.PERIODO_BUSCA);
		this.limparValidador(FormularioRelatorio.POSTO_SUDENE);
		this.subscription.unsubscribe();
	}

	verificarColunaData() {
		const getColuna = () => {
			switch (this.tipoPeriodo) {
				case 'periodo':
					return 'Data';
				case 'mensal':
					return 'Mês/ano';
				default:
					return 'Ano';
			}
		};

		return getColuna();
	}

	formatarData(data: Date) {
		return DateTimeUtils.formatarData(data.toLocaleString(), 'dd/MM/yyyy');
	}

	gerarRelatorio(): void {
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const diaInicio = dataInicio;
		const diaFim = dataFim;
		const periodoAgrupamento = this.tipoPeriodo;

		this.inputs.setLoading(true);
		this.taCarregando = true;
		this.tipoPeriodoRelatorioGerado = this.tipoPeriodo;
		this.tituloTabela = `Histórico da SUDENE - ${
			periodoAgrupamento === 'anual' &&
			DateTimeUtils.getAno(diaInicio) == new Date().getFullYear()
				? DateTimeUtils.getAno(diaInicio)
				: DateTimeUtils.formatarDataPeriodo(
						diaInicio,
						diaFim,
						periodoAgrupamento
				  )
		}`;

		dataFim.setHours(0, 0, 0, 0);

		this.relatoriosService
			.buscarRelatorioSudene(
				this.inputs.form.get(FormularioRelatorio.POSTO_SUDENE)?.value,
				dataInicio.toISOString(),
				dataFim.toISOString()
			)
			.subscribe({
				next: resp => {
					this.relatoriosService.verificaExistenciaDados(resp);
					this.dadosSudene = (resp as DadosPluviometriaRelatorioSudene[]).map(
						pluv => ({
							...pluv,
							data: pluv.data,
						})
					);
					const postos = new Set(this.dadosSudene?.map(ds => ds.posto));
					const result: RelatorioSudenePosto[] = [];
					postos.forEach(e => {
						const dados = this.dadosSudene?.filter(ds => ds.posto === e)!;
						result.push({
							posto: e,
							dados: dados,
							total: () => dados.reduce((sum, item) => sum + item.valor, 0),
							localizacao: {
								latitude: dados[0].latitude,
								longitude: dados[0].longitude,
								altitude: dados[0].altitude,
							},
						});
					});
					this.dadosSudeneSeparadosPorPosto = result;
					this.dadosSudeneSeparadosPorPosto.sort((a, b) => {
						a.dados.sort((a, b) =>
							DateTimeUtils.ehAntes(new Date(b.data), new Date(a.data)) ? 1 : -1
						);
						return a.posto > b.posto ? 1 : -1;
					});
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
				error: error => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório', error);
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
			});
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];

		const colunas = [
			{ text: 'Posto', fillColor: '#DCDCDC' },
			{ text: 'Precipitação (mm)', fillColor: '#DCDCDC' },
			{
				text: this.verificarColunaData(),
				fillColor: '#DCDCDC',
			},
		];

		verificaSePDF(tableData, colunas, isPdf);

		this.dadosSudene?.forEach((item: DadosPluviometriaRelatorioSudene) => {
			const rowData = [
				item.posto,
				numberToBrNumber(item.valor, 1),
				this.formatarData(item.data),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	retornarHistoricoSudeneParaXLSX() {
		const iapms: any[] = [];

		this.dadosSudene?.forEach(sudene => {
			iapms.push({
				posto: sudene.posto,
				valor: numberToBrNumber(sudene.valor, 1),
				data: this.formatarData(sudene.data),
			});
		});

		return iapms;
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: this.tituloTabela,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.dadosSudene) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		documentDefinition.content.push({
			table: {
				widths: ['*', '*', '*'],
				body: this.getDadosTabelaParaExportacao(true),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		DocumentExporter.gerarCSV(
			this.getDadosTabelaParaExportacao(false),
			this.tituloTabela
		);
	}

	exportarTXT() {
		let txtData = '';

		this.dadosSudene?.forEach(dadoRelatorio => {
			txtData +=
				`Posto: ${dadoRelatorio.posto}\n` +
				`Precipitação (mm): ${numberToBrNumber(dadoRelatorio.valor, 1)}\n` +
				`${this.verificarColunaData()}: ${this.formatarData(
					dadoRelatorio.data
				)}\n\n`;
		});

		DocumentExporter.gerarTXT(txtData, this.tituloTabela);
	}

	exportarXLSX() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = [
			{
				label: 'posto',
				text: 'Posto',
				size: 24,
			},
			{
				label: 'valor',
				text: 'Precipitação (mm)',
				size: 24,
			},
			{
				label: 'data',
				text: this.verificarColunaData(),
				size: 24,
			},
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarHistoricoSudeneParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: this.tituloTabela,
						styleId: 'title',
					},
				},
			],
			fileName: this.tituloTabela,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
