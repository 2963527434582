import { Injectable } from '@angular/core';
import {
	FormularioRelatorio,
	ListaRelatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { EvapotranspiracaoTabelaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/evapotranspiracao-tabela/evapotranspiracao-tabela.component';
import { PluviometroTabelaPrecipitacaoDiariaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-diaria/pluviometro-tabela-precipitacao-diaria.component';
import { VariaveisMeteorologicasTabelaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/variaveis-meteorologicas-tabela/variaveis-meteorologicas-tabela.component';
import { MapaQuantisComponent } from '../componentes/relatorios/mapa-quantis/mapa-quantis.component';
import { PluviometroTabelaEstatisticaMensalComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-estatistica-mensal/pluviometro-tabela-estatistica-mensal.component';
import {
	PERIODO_MAXIMO_SPI_EM_ANOS,
	PERIODO_MINIMO_SPI_EM_ANOS,
	PluviometroGraficoSpiComponent,
} from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { MapaPluviometriaInterpoladaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-pluviometria-interpolada/mapa-pluviometria-interpolada.component';
import { PluviometroTabelaPrecipitacaoMensalComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-mensal/pluviometro-tabela-precipitacao-mensal.component';
import { PluviometroTabelaPrecipitacaoAnualComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-precipitacao-anual/pluviometro-tabela-precipitacao-anual.component';
import { MapaIapmComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-iapm/mapa-iapm.component';
import { GraficoVariaveisMeteorologicasComponent } from '../componentes/relatorios/grafico-variaveis-meteorologicas/grafico-variaveis-meteorologicas.component';
import { VariaveisMetorologicasMapaComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/mosaico-chuvas/mosaico-chuvas';
import { PluviometroTabelaDesviosMensaisAnuaisComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-tabela-desvios-mensais-anuais/pluviometro-tabela-desvios-mensais-anuais.component';
import { MapaPluviometriaObservadaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-pluviometria-observada/mapa-pluviometria-observada.component';
import { MapaDesvioComponent } from '../componentes/relatorios/mapa-desvio/mapa-desvio.component';
import { GraficoPluviometriaObservadaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada/grafico-pluviometria-observada.component';
import { MapaInterpolacaoEstacoesPCDSComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-interpolacao-estacoes-pcds/mapa-interpolacao-estacoes-pcds.component';
import { MapaClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-climatologia/mapa-climatologia.component';
import { GraficoClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/grafico-climatologia.component';
import { TabelaRelatorioSudeneComponent } from '../componentes/tabela-relatorio-sudene/tabela-relatorio-sudene.component';
import { GraficoNdcComponent } from '../componentes/relatorios/grafico-ndc/grafico-ndc.component';
import { TabelaNdcComponent } from '../componentes/relatorios/tabela-ndc/tabela-ndc.component';
import { PluviometroTabelaQuantisComponent } from '../componentes/relatorios/pluviometro-tabela-quantis/pluviometro-tabela-quantis.component';
import { PluviometroGraficoQuantisComponent } from '../componentes/relatorios/pluviometro-grafico-quantis/pluviometro-grafico-quantis.component';
import { GraficoDesvioComponent } from '../componentes/relatorios/grafico-desvio/grafico-desvio.component';
import { TabelaVecComponent } from '../componentes/relatorios/tabela-vec/tabela-vec.component';
import { TabelaRelatorioDesvioComponent } from '../componentes/tabela-relatorio-desvio/tabela-relatorio-desvio.component';
import { GraficoDesvioPorPeriodoComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-desvio-por-periodo/grafico-desvio-por-periodo.component';

import { TabelaClimatologiaComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-climatologia/tabela-climatologia.component';
import { TabelaSPIComponent } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-spi/tabela-spi.component';
import { TabelaPluviometriaObservadaComponent } from '../componentes/relatorios/tabela-pluviometria-observada/tabela-pluviometria-observada.component';
import { PluviometroMapaNdcComponent } from '../componentes/relatorios/pluviometro-mapa-ndc/pluviometro-mapa-ndc.component';
import {
	PERIODO_MAXIMO_IAPM_EM_ANOS,
	PERIODO_MAXIMO_IAPM_EM_DIAS,
	PERIODO_MAXIMO_IAPM_EM_MESES,
	PERIODO_MINIMO_IAPM_EM_ANOS,
	PERIODO_MINIMO_IAPM_EM_DIAS,
	PERIODO_MINIMO_IAPM_EM_MESES,
	PluviometriaGraficoIapmComponent,
} from '../componentes/relatorios/pluviometria-grafico-iapm/pluviometria-grafico-iapm.component';
import { PluviometriaTabelaIapmComponent } from '../componentes/relatorios/pluviometria-tabela-iapm/pluviometria-tabela-iapm.component';
import { EPeriodosBusca } from '../enum';
import { ValoresExtremosTabelaComponent } from '../componentes/relatorios/valores-extremos-tabela/valores-extremos-tabela.component';
import { TabelaDesvioPorPeriodoComponent } from '@modulos/home/submodulos/dados-meteorologicos/componentes/relatorios/tabela-desvio-por-periodo/tabela-desvio-por-periodo.component';
import { MosaicoVariavelMeteorologicaComponent } from '../submodulos/monitoramento/pages/variaveis/componentes/mosaico-variavel-meteorologica/mosaico-variavel-meteorologica.component';

@Injectable({
	providedIn: 'root',
})
export class ComponentesRelatoriosService {
	getRelatorios(): ListaRelatorios {
		return {
			PLUVIOMETRICO: {
				GRAFICO: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: GraficoPluviometriaObservadaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: GraficoNdcComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
						},
					},
					CLIMATOLOGIA: {
						componente: GraficoClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					DESVIO_MILIMETRO: {
						componente: GraficoDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_MILIMETRO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					DESVIO_PORCENTAGEM: {
						componente: GraficoDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					IAPM: {
						componente: PluviometriaGraficoIapmComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							mensal: {
								unit: 'month',
								minAmount: PERIODO_MINIMO_IAPM_EM_MESES,
								amount: PERIODO_MAXIMO_IAPM_EM_MESES,
							},
							anual: {
								unit: 'year',
								minAmount: PERIODO_MINIMO_IAPM_EM_ANOS,
								amount: PERIODO_MAXIMO_IAPM_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					SPI: {
						componente: PluviometroGraficoSpiComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							anual: {
								unit: 'year',
								amount: PERIODO_MAXIMO_SPI_EM_ANOS,
								minAmount: PERIODO_MINIMO_SPI_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val =>
								val[FormularioRelatorio.TIPO] === 'SPI' &&
								val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO',
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					DESVIO_POR_PERIODO_PORCENTAGEM: {
						componente: GraficoDesvioPorPeriodoComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'DESVIO_POR_PERIODO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'DESVIO_POR_PERIODO_PORCENTAGEM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					DESVIO_POR_PERIODO_MM: {
						componente: GraficoDesvioPorPeriodoComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_POR_PERIODO_MM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_POR_PERIODO_MM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
				},
				MAPA: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: MapaPluviometriaObservadaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					PLUVIOMETRIA_INTERPOLADA: {
						componente: MapaPluviometriaInterpoladaComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
						},
					},
					MOSAICO_CHUVAS: {
						componente: VariaveisMetorologicasMapaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'MOSAICO_CHUVAS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: PluviometroMapaNdcComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					CLIMATOLOGIA: {
						componente: MapaClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
					},
					DESVIO_MILIMETRO: {
						componente: MapaDesvioComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
					},
					DESVIO_PORCENTAGEM: {
						componente: MapaDesvioComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
					},
					IAPM: {
						componente: MapaIapmComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							diario: {
								unit: 'day',
								minAmount: PERIODO_MINIMO_IAPM_EM_DIAS,
								amount: PERIODO_MAXIMO_IAPM_EM_DIAS,
							},
						},
					},
				},
				TABELA: {
					PLUVIOMETRIA_OBSERVADA: {
						componente: TabelaPluviometriaObservadaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PLUVIOMETRIA_OBSERVADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
						},
					},
					NUMERO_DIAS_COM_CHUVA: {
						componente: TabelaNdcComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'NUMERO_DIAS_COM_CHUVA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					CLIMATOLOGIA: {
						componente: TabelaClimatologiaComponent,
						periodos: {
							mensal_por_nome: {},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'CLIMATOLOGIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					DESVIO: {
						componente: TabelaRelatorioDesvioComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO'
								);
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO'
								);
							},
						},
					},
					IAPM: {
						componente: PluviometriaTabelaIapmComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							mensal: {
								unit: 'month',
								minAmount: PERIODO_MINIMO_IAPM_EM_MESES,
								amount: PERIODO_MAXIMO_IAPM_EM_MESES,
							},
							anual: {
								unit: 'year',
								minAmount: PERIODO_MINIMO_IAPM_EM_ANOS,
								amount: PERIODO_MAXIMO_IAPM_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'IAPM' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
					SPI: {
						componente: TabelaSPIComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							anual: {
								unit: 'year',
								amount: PERIODO_MAXIMO_SPI_EM_ANOS,
								minAmount: PERIODO_MINIMO_SPI_EM_ANOS,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] ===
										'REGIAO_PLUVIOMETRICA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'SPI' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA' &&
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
						},
					},
					HISTORICO_DA_SUDENE: {
						componente: TabelaRelatorioSudeneComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'HISTORICO_DA_SUDENE' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.POSTO_SUDENE]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'HISTORICO_DA_SUDENE' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_DIARIA: {
						componente: PluviometroTabelaPrecipitacaoDiariaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_DIARIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_MENSAL: {
						componente: PluviometroTabelaPrecipitacaoMensalComponent,
						periodos: {
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_MENSAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					PRECIPITACAO_ANUAL: {
						componente: PluviometroTabelaPrecipitacaoAnualComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'PRECIPITACAO_ANUAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					ESTATISTICA_MENSAL: {
						componente: PluviometroTabelaEstatisticaMensalComponent,
						periodos: {
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.RADIO_POSTOS]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'ESTATISTICA_MENSAL' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					DESVIOS_MENSAIS_ANUAIS: {
						componente: PluviometroTabelaDesviosMensaisAnuaisComponent,
						usaSetagemPropriaDatas: true,
						periodos: {
							mensal_unico: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
						},
					},
					VALORES_EXTREMOS_PLUVIOMETRIA: {
						componente: TabelaVecComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VALORES_EXTREMOS_PLUVIOMETRIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VALORES_EXTREMOS_PLUVIOMETRIA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
					DESVIO_POR_PERIODO: {
						componente: TabelaDesvioPorPeriodoComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_POR_PERIODO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'DESVIO_POR_PERIODO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MICRORREGIAO';
							},
							[FormularioRelatorio.MUNICIPIO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO';
							},
							[FormularioRelatorio.MESORREGIAO]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'MESORREGIAO';
							},
							[FormularioRelatorio.BACIA]: val => {
								return val[FormularioRelatorio.AGRUPAMENTO] === 'BACIA';
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
									'REGIAO_PLUVIOMETRICA'
								);
							},
						},
					},
				},
			},
			PCD: {
				MAPA: {
					VARIAVEL_METEOROLOGICA_INTERPOLADA: {
						componente: MapaInterpolacaoEstacoesPCDSComponent,
						condicoesMostrarItensForm: {
							[FormularioRelatorio.JANELA_TEMPO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VARIAVEL_METEOROLOGICA_INTERPOLADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.VARIAVEL_MAPA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'VARIAVEL_METEOROLOGICA_INTERPOLADA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
					MOSAICO_VARIAVEL_METEOROLOGICA: {
						componente: MosaicoVariavelMeteorologicaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'MOSAICO_VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.VARIAVEIS_MOSAICO]: val => {
								return (
									val[FormularioRelatorio.TIPO] ===
										'MOSAICO_VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
				},
				GRAFICO: {
					VARIAVEL_METEOROLOGICA: {
						componente: GraficoVariaveisMeteorologicasComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.VARIAVEL_GRAFICO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
						},
					},
				},
				TABELA: {
					EVAPOTRANSPIRACAO: {
						componente: EvapotranspiracaoTabelaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'EVAPOTRANSPIRACAO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
						},
					},
					VALORES_EXTREMOS: {
						componente: ValoresExtremosTabelaComponent,
						periodos: {
							mensal_completo: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							mensal_parcial: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual_completo: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
							anual_parcial: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VALORES_EXTREMOS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VALORES_EXTREMOS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO'
								);
							},
						},
					},
					VARIAVEL_METEOROLOGICA: {
						componente: VariaveisMeteorologicasTabelaComponent,
						periodos: {
							diario: {
								unit: 'day',
								amount: EPeriodosBusca.DIARIO,
							},
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'VARIAVEL_METEOROLOGICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
				},
			},
			QUANTIS: {
				MAPA: {
					QUANTIS: {
						componente: MapaQuantisComponent,
						periodos: {
							mensal_unico: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
							},
							anual_unico: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
							[FormularioRelatorio.MICRORREGIAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'MAPA'
								);
							},
						},
					},
				},
				GRAFICO: {
					QUANTIS: {
						componente: PluviometroGraficoQuantisComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
								apenasMesmoAno: true,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.AGRUPAMENTO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] === 'MUNICIPIO_POSTO' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
							[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: val => {
								return (
									val[FormularioRelatorio.AGRUPAMENTO] ===
										'REGIAO_PLUVIOMETRICA' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'GRAFICO'
								);
							},
						},
					},
				},
				TABELA: {
					QUANTIS: {
						componente: PluviometroTabelaQuantisComponent,
						periodos: {
							mensal: {
								unit: 'month',
								amount: EPeriodosBusca.MENSAL,
								apenasMesmoAno: true,
							},
							anual: {
								unit: 'year',
								amount: EPeriodosBusca.ANUAL,
							},
						},
						condicoesMostrarItensForm: {
							[FormularioRelatorio.PERIODO_BUSCA]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
							[FormularioRelatorio.ESTACAO]: val => {
								return (
									val[FormularioRelatorio.TIPO] === 'QUANTIS' &&
									val[FormularioRelatorio.TIPO_VISUALIZACAO] === 'TABELA'
								);
							},
						},
					},
				},
			},
		};
	}
}
