import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';

export interface VariaveisMeteorologicasPorPeriodo {
	data: Date;
	historico: Historico[];
}
export interface VariaveisMeteorologicasPorPeriodoETipoVariavel {
	tipoVariavel: ValuesVariaveis;
	data: Date;
	historico: {
		periodo: keyof typeof TiposPeriodosVariaveisMeteorologicas;
		geocodigo: number;
		valor: number;
		nomeEstacao: string;
		tipoEstacao: keyof typeof TipoEstacao;
		nomeMunicipio: string;
	}[];
}

export enum LabelsHistoricoVariaveisMeteorologicas {
	PRECIPITACAO = 'Precipitação acumulada (mm)',
	TEMPERATURA = 'Temperatura média (°C)',
}

export enum TiposPeriodosVariaveisMeteorologicas {
	DIARIO = 'DIARIO',
	ANUAL = 'ANUAL',
	MENSAL = 'MENSAL',
}

interface Historico {
	id: number;
	geocodigo: number;
	periodo: string;
	precipitacaoAcumulada: number;
	temperaturaMedia: number;
	nomeEstacao: string;
	tipoEstacao: keyof typeof TipoEstacao;
	nomeMunicipio: string;
}
