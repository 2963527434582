import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import {
	filterSelectOptions,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import {
	RelatorioDesvio,
	RelatorioDesvioMunicipioDTO,
} from '../../interfaces/response';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '../../interfaces/tipos-relatorios';
import { RelatoriosService } from '../../services/relatorios.service';
import { Subject, takeUntil } from 'rxjs';
import moment from 'moment';
import { format } from 'date-fns';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/interfaces';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';

interface OpcaoColuna {
	name: string;
	isFixed: boolean;
}

@Component({
	selector: 'seira-tabela-relatorio-desvio',
	templateUrl: './tabela-relatorio-desvio.component.html',
	styleUrls: ['./tabela-relatorio-desvio.component.scss'],
})
export class TabelaRelatorioDesvioComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como desvio (mm), a diferença entre a quantidade de chuva registrada e a sua climatologia para um mesmo período, em um determinado local ou região. Já o desvio (%) representa a variação da precipitação em relação à climatologia expresso em porcentagem, indicando se o acumulado de chuva foi superior ou inferior à climatologia.';
	relatoriosDTO: RelatorioDesvioMunicipioDTO[] = [];
	relatorios: RelatorioDesvio[] = [];
	taCarregando = true;
	private readonly inputs = inject(INPUTS_RELATORIOS);
	@ViewChild('tabelaDesvios', { static: false })
	tabela: PublicTableComponent;

	jaGerouAoMenosUmaVez = false;
	agrupamento: Select<string>[];
	isMunicipioPosto = false;
	opcoesColunas: OpcaoColuna[] = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Desvio (mm)', isFixed: true },
		{ name: 'Desvio (%)', isFixed: true },
		{ name: 'Data', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	disabledSelectList = false;
	tituloColunaAgrupamento = 'Nome';
	colunasSelecionadas: string[] = [];
	colunas: ADTColumns[];
	periodoTitulo = '';
	tituloTabela = '';
	periodoAgrupamento = ';';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.relatorios,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	private agrupamentoSelecionado: string;
	_destroyed = new Subject();
	tituloArquivoExportacao = '';

	constructor(
		private toastr: ToastrService,
		private readonly relatoriosService: RelatoriosService
	) {
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.agrupamento = filterSelectOptions(
			Agrupamento,
			Agrupamento.BACIA,
			Agrupamento.MESORREGIAO,
			Agrupamento.MICRORREGIAO,
			Agrupamento.MUNICIPIO,
			Agrupamento.MUNICIPIO_POSTO,
			Agrupamento.REGIAO_PLUVIOMETRICA
		);
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.clearValidators();
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	setColunasSelecionadas(colunasSelecionadas: string[]): void {
		this.colunasSelecionadas = colunasSelecionadas;
		this.gerarRelatorio();
	}

	atualizarTituloColuna(): void {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}

	private normalizarAgrupamento(agrupamento: string): string {
		agrupamento = agrupamento.toLowerCase();
		if (agrupamento === 'regiao_pluviometrica') {
			return 'regiaoPluviometrica';
		}
		if (agrupamento === 'subbacia') {
			return 'subBacia';
		}
		return agrupamento;
	}

	extrairDatasResultado(
		preciptacaoAgrupada: RelatorioDesvio,
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacaoAgrupada.desvios
					.filter((el: any) => el.data)
					.map((el: any) => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a: any, b: any) => +a - +b)
					.map((data: any) => {
						let formato = 'MM/yyyy';
						if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato);
					})
			),
		];
	}

	gerarRelatorio(): void {
		this.jaGerouAoMenosUmaVez = true;
		if (this.inputs.form.invalid) {
			return;
		}
		this.relatorios = [];
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		this.periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value;

		const chave: keyof typeof FormularioRelatorio =
			agrupamento === 'MUNICIPIO_POSTO' ? 'ESTACAO' : agrupamento;

		if (
			!agrupamento ||
			!dataInicio ||
			!dataFim ||
			!this.periodoAgrupamento ||
			!chave
		) {
			return;
		}

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			this.periodoAgrupamento
		);
		const periodoAnualUnicoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			this.periodoAgrupamento,
			true
		);
		this.taCarregando = true;
		this.inputs.setLoading(true);

		this.agrupamentoSelecionado = this.normalizarAgrupamento(agrupamento);

		this.isMunicipioPosto = agrupamento === 'MUNICIPIO_POSTO';

		const isSearchWithId =
			this.isMunicipioPosto ||
			agrupamento === 'MUNICIPIO' ||
			agrupamento === 'MICRORREGIAO';

		this.tituloArquivoExportacao = `Desvios - ${
			this.periodoAgrupamento === 'anual' &&
			new Date(dataInicio).getFullYear() ===
				new Date(moment.now()).getFullYear()
				? periodoAnualUnicoTitulo
				: this.periodoTitulo
		}`;

		this.relatoriosService
			.buscarRelatorioDesvio({
				agrupamento: agrupamento,
				idEntidade:
					chave && isSearchWithId
						? this.inputs.form.get(FormularioRelatorio[chave])?.value
						: null,
				dataFim: dataFim,
				dataInicio: dataInicio,
				periodo: this.periodoAgrupamento.toUpperCase(),
				incluirDetalhes: this.isMunicipioPosto,
			})
			.subscribe({
				next: relatorios => {
					this.relatoriosService.verificaExistenciaDados(relatorios);
					if (relatorios.length === 0) {
						this.toastr.info(
							'Não há desvios para os parametros selecionados',
							'Sem dados'
						);
					}
					this.tituloTabela = `Desvios - ${this.periodoTitulo}`;
					this.atualizarTituloColuna();
					this.atualizarColunasTabelaDesvios();

					const dataResultado = this.extrairDatasResultado(
						relatorios[0],
						this.tipoPeriodo
					);

					relatorios.forEach(data => {
						this.relatorios.push(
							...(this.tipoPeriodo === 'anual'
								? this.getRelatorioDesviosPorAno(
										data,
										dataResultado,
										this.agrupamentoSelecionado
								  )
								: this.formatarResultados(data, this.agrupamentoSelecionado))
						);
					});
				},
				error: err => {
					this.inputs.setLoading(false);
					this.taCarregando = false;
					const msgErro = obter_erro_request(err);
					this.toastr.error(
						msgErro,
						'Erro ao obter informações de desvio no servidor'
					);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.taCarregando = false;
				},
			});
	}

	private formatarResultados(resultados: any, agrupamento: string) {
		return resultados.desvios.map((resultado: any) => {
			if (agrupamento === 'municipio_posto') {
				return {
					...this.obterDetalhesPosto(resultados),
					data: resultado.data,
					desvioMilimetro: numberToBrNumber(resultado.desvioMilimetro, 1),
					desvioPorcentagem: numberToBrNumber(resultado.desvioPorcentagem, 1),
				};
			}
			return {
				nome: resultados[agrupamento as keyof RelatorioDesvio],
				data: resultado.data,
				desvioMilimetro: numberToBrNumber(resultado.desvioMilimetro, 1) || '-',
				desvioPorcentagem:
					numberToBrNumber(resultado.desvioPorcentagem, 1) || '-',
			};
		});
	}

	private getRelatorioDesviosPorAno(
		relatorios: RelatorioDesvio,
		dataResultado: string[],
		agrupamento: string
	) {
		return dataResultado.map(ano => {
			const dadosDoAno = relatorios.desvios.filter((item: any) =>
				item.data.startsWith(ano)
			);
			const calculos = this.calcularTotaisAnuais(dadosDoAno);
			return {
				nome: relatorios[agrupamento as keyof RelatorioDesvio],
				data: ano,
				...calculos,
				...(agrupamento === 'municipio_posto'
					? this.obterDetalhesPosto(relatorios)
					: {}),
			};
		});
	}

	private calcularTotaisAnuais(dados: any[]) {
		const climatologiaAnual = dados.reduce(
			(total, item) => total + item.climatologia,
			0
		);
		const desvioMilimetroAnual = dados.reduce(
			(total, item) => total + item.desvioMilimetro,
			0
		);
		const desvioPorcentagemAnual =
			climatologiaAnual !== 0
				? (desvioMilimetroAnual / climatologiaAnual) * 100
				: 0;

		return {
			desvioMilimetro: numberToBrNumber(desvioMilimetroAnual, 1) || '-',
			desvioPorcentagem: numberToBrNumber(desvioPorcentagemAnual, 1) || '-',
		};
	}

	private obterDetalhesPosto(dadosPosto: any) {
		return {
			nome:
				dadosPosto.municipio === dadosPosto.estacao
					? dadosPosto.municipio
					: `${dadosPosto.municipio}/${dadosPosto.estacao}`,
			municipio: dadosPosto.municipio || '-',
			latitude: numberToBrNumber(dadosPosto.latitude, 4),
			longitude: numberToBrNumber(dadosPosto.longitude, 4),
			altitude: numberToBrNumber(dadosPosto.altitude, 1),
			microrregiao: dadosPosto.microrregiao || '-',
			mesorregiao: dadosPosto.mesorregiao || '-',
			regiaoPluviometrica: dadosPosto.regiaoPluviometrica || '-',
			bacia: dadosPosto.bacia || '-',
			subBacia: dadosPosto.subBacia || '-',
		};
	}

	private get tipoPeriodo(): PeriodosLabel {
		return this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	private atualizarColunasTabelaDesvios() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: 'Município/Posto',
					type: 'string',
					className: 'text-left',
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiaoPluviometrica',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'subBacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna => {
				return this.colunasSelecionadas.includes(coluna.title);
			});

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'data',
					title: 'Data',
					type: 'number',
					className: 'text-center',
					render: (value: string) => {
						if (!value) return '-';

						if (periodo === 'mensal') {
							const dataFormatada = new Date(value);
							const mes = String(dataFormatada.getMonth() + 1).padStart(2, '0');
							const ano = dataFormatada.getFullYear();
							return `${mes}/${ano}`;
						}

						return value;
					},
				},
				{
					data: 'desvioPorcentagem',
					title: 'Desvio (%)',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'desvioMilimetro',
					title: 'Desvio (mm)',
					type: 'number',
					className: 'text-center',
				},
			]
		);
	}

	private clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}
}
