import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'seira-public-button',
	templateUrl: './public-button.component.html',
	styleUrls: ['./public-button.component.scss'],
})
export class PublicButtonComponent {
	@Input() loading = false;
	@Input() link: string | null = null;
	@Input() disabledButton = false;
	@Input() type = 'button';
	@Input() icon = '';
	@Input() buttonStyle: 'fill' | 'outline' = 'fill';
	@Input() customClass = '';
	@Input() idButton = `button-${uuidv4()}`;
	@Output() clicked = new EventEmitter<Event>();
}
