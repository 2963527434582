import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { FeatureCollection, Polygon } from 'geojson';
import { HttpClient } from '@angular/common/http';
import { Position } from 'pdfmake/interfaces';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	TipoValor,
	ValoresBusca,
} from '@utils/interpolacao/interpolacao';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { DocumentExporter } from '@utils/document-exporter';
import pdfMake from 'pdfmake/build/pdfmake';
import { AgrupamentoResponse, Meses } from '../../../interfaces';
import html2canvas from 'html2canvas';
import * as pdfseira from '@utils/pdf-seira';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import {
	compararStrings,
	compararStringsIguais,
	numberToBrNumber,
	verificaSePDF,
} from '@utils';
import { bootstrapLarguraSM } from '@utils/constants';

@Component({
	selector: 'seira-mapa-climatologia',
	templateUrl: './mapa-climatologia.component.html',
	styleUrls: ['./mapa-climatologia.component.scss'],
})
export class MapaClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	climatologias: AgrupamentoResponse[] = [];
	regiao?: Position[][];
	carregando = true;
	interpolacao?: InterpolacaoCanvasBounds;
	descricaoRelatorio =
		'Define-se como climatologia pluviométrica, a média histórica de pluviometria para períodos longos, preferencialmente de 30 anos.';
	periodoTitulo = '';
	mapaId = 'climatologia';
	relatorioService = inject(RelatoriosService);
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private httpClient: HttpClient,
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.initialValues();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.MES_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MES_FIM)?.clearValidators();
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		if (mesInicio === mesFim) {
			this.mapaId = 'climatologiaMes';
		} else if (
			compararStringsIguais(mesInicio, Meses.JANEIRO) &&
			compararStringsIguais(mesFim, Meses.DEZEMBRO)
		) {
			this.mapaId = 'climatologiaAno';
		} else {
			this.mapaId = 'climatologiaMeses';
		}

		this.inputs.setLoading(true);
		this.carregando = true;

		const mesInicioFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesInicio)
		);
		const mesFimFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesFim)
		);

		this.periodoTitulo = ` ${mesInicioFormatado} a ${mesFimFormatado}`;

		this.relatoriosService
			.buscarClimatologia(mesInicio.toLowerCase(), mesFim.toLowerCase())
			.subscribe({
				next: (resp: []) => {
					this.relatorioService.verificaExistenciaDados(resp);
					this.climatologias = resp;
				},
				error: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	interpolarValores() {
		this.carregando = true;
		if (this.climatologias.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
			this.toastr.warning('Não existem dados a serem exibidos.');
		}
	}

	criarImagemInterpolacao() {
		const valores: ValoresBusca[] = this.climatologias.map((value: any) => {
			return {
				lat: value.latitude,
				lng: value.longitude,
				value: value.total,
				municipio: value.nome,
				unidade: 'mm',
				tipoValor: TipoValor.CLIMATOLOGIA,
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valores,
				this.mapaId as VariavelMeteorologica,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	getDadosTabelaParaExportacao(dados: AgrupamentoResponse[], isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas = [
			{ text: 'Município', fillColor: '#DCDCDC' },
			{ text: 'Climatologia (mm)', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: AgrupamentoResponse) => {
			const rowData = [
				item.nomeOriginal ?? item.nome,
				numberToBrNumber(item.total, 1),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	async exportarPdf() {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Climatologia (mm) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: window.innerWidth > bootstrapLarguraSM ? 500 : 200,
				height: window.innerWidth > bootstrapLarguraSM ? 400 : undefined,
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(
			this.climatologias,
			true
		);

		documentDefinition.content.push({
			table: {
				widths: ['50%', '50%'],
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.climatologias,
			false
		);

		DocumentExporter.gerarCSV(
			tableData,
			`Climatologia (mm) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.climatologias,
			false
		);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Climatologia (mm) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `Climatologia (mm) - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'nome',
				text: 'Município',
				size: 48,
			},
			{ label: 'total', text: 'Climatologia (mm)', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.climatologias.map(({ nome, total }) => {
						return {
							nome: nome,
							total: numberToBrNumber(total, 1),
						};
					}),
					columns: [
						{ key: 'nome' },
						{
							key: 'total',
							style: { numFmt: '0.0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 2,
						text: nomeArquivo,
						styleId: 'title',
						alignment: 'center',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
