<label class="text-tertiary w-100 text-center fw-semibold mt-3">
	Desvios - {{ periodoTitulo }}
</label>
<div class="row">
	<div
		class="d-flex flex-row justify-content-between my-3 align-items-end gap-2">
		<seira-card-info [texto]="descricaoRelatorio" />

		<seira-select-list-menu
			[opcoes]="opcoesColunas"
			[opcoesSelecionadas]="colunasSelecionadas"
			[disabled]="taCarregando || disabledSelectList"
			[tituloBotao]="'Personalizar colunas'"
			(atualizarOpcoesSelecionadas)="
				setColunasSelecionadas($event)
			"></seira-select-list-menu>
	</div>

	<div
		*ngIf="taCarregando; else tabela"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #tabela>
		<div style="max-width: 1300px" *ngIf="relatoriosDTO; else semDados">
			<div class="mb-5">
				<seira-public-table
					#tabelaDesvios
					id="tabelaDesvios"
					[hasFooter]="true"
					[hasHeader]="false"
					[loading]="taCarregando"
					[info]="true"
					[paging]="true"
					[searching]="false"
					[scrollX]="true"
					[spaceReduceTh]="true"
					[columns]="colunas"
					[order]="[1, 'asc']"
					[data]="relatorios"></seira-public-table>
			</div>
		</div>
	</ng-template>

	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
