import { Component, Input } from '@angular/core';
import { DropdownOption } from './interfaces/DropdownOption';

@Component({
	selector: 'seira-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
	@Input() options!: DropdownOption[];
	@Input() isPositionFixed = false;
}
