import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	HistoricosReservatorio,
	ReservatorioVolume,
	ShortReservatorio,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/reservatorios';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { numberToBrNumber } from '@utils';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import * as Highcharts from 'highcharts/highstock';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { TableExportsType } from '@componentes/public-table/table-utils';
import { TiposDatePicker } from '@shared/interfaces/date-picker';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { documentDefinitions } from '@utils/pdf-seira';
import { ReservatorioService } from '@modulos/recursos-hidricos/submodulos/reservatorio/services/reservatorio.service';
import moment from 'moment';
import { HistoricoReservatorioResponse } from '@modulos/recursos-hidricos/submodulos/reservatorio/interfaces/bacia-reservatorio-response';

@Component({
	selector: 'seira-modal-reservatorios',
	templateUrl: './modal-reservatorios.component.html',
	styleUrls: ['./modal-reservatorios.component.scss'],
})
export class ModalReservatoriosComponent implements OnInit {
	@Input() close!: () => void;
	@Input() reservatorio!: ShortReservatorio;
	@Input() historioReservatorio?: HistoricoReservatorioResponse;
	@ViewChild('tabelaModal', { static: false })
	tabela!: PublicTableComponent;
	reservatorios: ShortReservatorio[] = [];
	dadosGrafico!: HistoricosReservatorio;
	loading = false;
	loadingReservatorios = false;
	unidadesGrafico = [
		{ label: 'Porcentagem', value: 'porcentagem' },
		{ label: 'Metros Cúbicos', value: 'volumeAtual' },
	];
	tiposGrafico = [
		{ label: 'Colunas', value: 'column' },
		{ label: 'Linha', value: 'line' },
		{ label: 'Área', value: 'area' },
		{ label: 'Passos', value: 'steps' },
	];
	periodos = [
		{ label: 'Dia', value: 'day' },
		{ label: 'Mês', value: 'month' },
		{ label: 'Ano', value: 'year' },
	];

	form!: FormGroup;
	opcoesVisualizacao: OptionRadio<'grafico' | 'tabelaModal'>[] = [
		{ label: 'Gráfico', value: 'grafico' },
		{ label: 'Tabela', value: 'tabelaModal' },
	];

	formGrafico = this.fb.group({
		unidade: new FormControl('porcentagem'),
		tipoGrafico: new FormControl('column'),
	});
	formTabela = this.fb.group({
		busca: new FormControl(''),
	});
	chartVolume?: Highcharts.Chart;
	buttonsGrafico: GroupButton[] = [
		{
			label: '.png',
			icon: 'ph-file-png',
			size: 'small',
			onClick: () => {
				this.exportChartVolumePng(this.chartVolume);
			},
		},
	];
	buttonsTabela: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportPdf();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: async () => {
				await this.tabela.gerar(TableExportsType.CSV);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: async () => {
				await this.tabela.gerar(TableExportsType.TXT);
			},
		},
	];

	colunasTabela: ADTColumns[] = [
		{
			data: 'dataColeta',
			title: 'Data do registro',
			className: 'text-center',
			render: (value: ReservatorioVolume['dataColeta'], type: string) => {
				const data = new Date(value);
				const dataFormatada = format(data, 'dd/MM/yyyy', {
					locale: ptBrLocale,
				});
				return type === 'display' ? dataFormatada : value;
			},
		},
		{
			title: 'Volume (%)',
			data: 'porcentagem',
			className: 'text-center',
			render: function (porcentagem: ReservatorioVolume['porcentagem']) {
				return porcentagem ? numberToBrNumber(porcentagem, 2) : '-';
			},
		},
		{
			title: 'Volume (m³)',
			data: 'volumeAtual',
			className: 'text-center',
			render: function (volumeAtual: ReservatorioVolume['volumeAtual']) {
				return volumeAtual ? numberToBrNumber(Math.round(volumeAtual)) : '-';
			},
		},
		{
			title: 'Afluência / defluência',
			data: 'afluencia',
			className: 'text-center',
			render: function (afluencia: ReservatorioVolume['afluencia']) {
				return afluencia ? numberToBrNumber(afluencia) : '-';
			},
		},
	];

	constructor(
		private fb: FormBuilder,
		private reservatorioService: ReservatorioService
	) {}

	ngOnInit() {
		this.setForm();
		this.buscarHistorico();
		this.buscaReservatorios();
		(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
		this.observeForm();
		this.observeTabela();
	}

	observeForm() {
		this.form.get('periodo')?.valueChanges.subscribe({
			next: () => {
				this.buscarHistorico();
			},
		});
		this.form.get('dataInicio')?.valueChanges.subscribe({
			next: () => {
				this.buscarHistorico();
			},
		});
		this.form.get('dataFim')?.valueChanges.subscribe({
			next: () => {
				this.buscarHistorico();
			},
		});
	}

	buscaReservatorios() {
		this.loadingReservatorios = true;
		this.reservatorioService.listShortReservatorios().subscribe({
			next: reservatorios => {
				this.reservatorios = reservatorios;
				this.loadingReservatorios = false;
			},
			error: () => {
				this.loadingReservatorios = true;
			},
		});
	}
	get modoVisualizacao() {
		return this.form.get('modoVisualizacao')?.value;
	}

	setForm() {
		const dataInicio = new Date();
		const dataFim = new Date();
		dataInicio.setDate(dataInicio.getDate() - 7);
		this.form = this.fb.group({
			reservatorio: new FormControl(
				this.historioReservatorio?.id ?? this.reservatorio.id
			),
			periodo: new FormControl<TiposDatePicker>('day'),
			dataInicio: new FormControl(moment(dataInicio)),
			dataFim: new FormControl(moment(dataFim)),
			modoVisualizacao: new FormControl('grafico'),
		});
	}

	observeTabela() {
		this.formTabela.get('busca')?.valueChanges.subscribe({
			next: async search => {
				await this.tabela.pesquisar(search || '');
			},
		});
	}

	buscarHistorico() {
		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;
		const reservatorio = this.form.get('reservatorio')?.value;
		const periodo: TiposDatePicker = this.form.get('periodo')?.value;

		let id = this.historioReservatorio?.id ?? this.reservatorio.id;
		if (reservatorio) {
			id = parseInt(reservatorio);
		}
		if (dataInicio && dataFim) {
			this.loading = true;
			this.reservatorioService
				.listHistoricoPublicoPorReservatorio(id, dataInicio, dataFim, periodo)
				.subscribe({
					next: historico => {
						this.reservatorio = historico.reservatorio;
						this.dadosGrafico = historico;
						this.loading = false;
					},
					error: () => {
						this.loading = false;
					},
				});
		}
	}

	get tipoPeriodo(): TiposDatePicker {
		return this.form.get('periodo')!.value || 'day';
	}

	get dataFim() {
		return this.form.get('dataFim')!.value || new Date();
	}

	getFormItemValue(formValue: string) {
		return this.form.get(formValue)!.value;
	}

	getFormGraficoValue(formValue: string) {
		return this.formGrafico.get(formValue)!.value;
	}

	async exportPdf() {
		if (!this.dadosGrafico) return;
		const pdfConfig: any = await documentDefinitions();
		const tabela: any = [];

		tabela.push(
			this.colunasTabela.map(coluna => ({
				text: coluna.title,
				fillColor: '#DCDCDC',
			}))
		);

		this.dadosGrafico.historico.forEach(dadosTabela => {
			tabela.push([
				new Date(dadosTabela.dataColeta).toLocaleString('pt-BR', {
					dateStyle: 'short',
				}),
				dadosTabela.porcentagem
					? numberToBrNumber(dadosTabela.porcentagem, 2)
					: '-',
				dadosTabela.volumeAtual
					? numberToBrNumber(dadosTabela.volumeAtual)
					: '-',
				dadosTabela.afluencia ? numberToBrNumber(dadosTabela.afluencia) : '-',
			]);
		});

		pdfConfig.content.push({
			text: `Açude: ${this.dadosGrafico.reservatorio.nome}`,
			alignment: 'center',
			margin: [0, 15, 5, 15],
		});

		pdfConfig.content.push({
			columns: [
				{
					alignment: 'center',
					text: `Bacia: ${this.dadosGrafico.reservatorio.bacia.nome} `,
					margin: [0, 0, 0, 8],
					fontSize: 10,
				},
				{
					alignment: 'center',
					text: `Capacidade: ${numberToBrNumber(
						this.dadosGrafico.reservatorio.capacidade,
						2
					)} m³`,
					margin: [0, 0, 0, 8],
					fontSize: 10,
				},
				{
					alignment: 'center',
					text: `Município: ${this.dadosGrafico.reservatorio.municipio.nome}`,
					fontSize: 10,
					margin: [0, 0, 0, 8],
				},
			],
		});

		pdfConfig.content.push({
			columns: [
				{ width: '*', text: '' },
				{
					width: 'auto',
					table: {
						alignment: 'center',
						headerRows: 1,
						body: tabela,
						layout: {
							noWrap: false,
							fontSize: 5,
						},
					},
				},
				{ width: '*', text: '' },
			],
		} as any);
		const pdfDocGenerator = pdfMake.createPdf(pdfConfig);
		pdfDocGenerator.open();
	}

	exportChartVolumePng(chartVolume?: Highcharts.Chart) {
		chartVolume?.exportChart(
			{
				type: 'image/png',
				filename: 'seira-volume-acude',
			},
			{
				chart: {
					height: '500px',
					backgroundColor: 'white',
				},
			}
		);
	}

	setChartRefVolume(chart: Highcharts.Chart) {
		this.chartVolume = chart;
	}

	protected readonly numberToBrNumber = numberToBrNumber;
}
