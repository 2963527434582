<div class="d-flex flex-column align-items-center">
	<label class="fw-light text-tertiary-light mb-1 font-size-18">{{
		options.label
	}}</label>
	<div class="d-inline-flex w-100">
		<div
			class="w-100 legenda-item shadow-lg"
			style="height: 20px"
			*ngFor="let item of options.items"
			[style.background-color]="item.color"></div>
	</div>
	<div
		class="d-inline-flex mx-1 w-100 justify-content-center align-items-center">
		<ng-container *ngFor="let item of legendas; let i = index">
			<div
				class="legenda-number d-flex"
				[ngClass]="i === 0 ? 'justify-content-center' : 'justify-content-start'"
				[style.width]="percentage()">
				{{ item.numberCurrentAndNext }}
			</div>
		</ng-container>
	</div>
</div>
