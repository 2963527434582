import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
	selector: 'seira-circular-progress-percentege',
	templateUrl: './circular-progress-percentege.component.html',
	styleUrls: ['./circular-progress-percentege.component.scss'],
})
export class CircularProgressPercentegeComponent implements OnInit, OnChanges {
	@Input() percentage = 0;
	@Input() color = '#0D6A92';
	@Input() radius = 90;

	circumference!: number;
	offset!: number;

	constructor() {}

	ngOnInit(): void {
		this.updateCircle();
	}

	ngOnChanges(): void {
		this.updateCircle();
	}

	private updateCircle(): void {
		this.circumference = 2 * Math.PI * this.radius;
		let auxVal = this.percentage;
		if (this.percentage > 100) auxVal = 100;
		this.offset = this.circumference - (auxVal / 100) * this.circumference;
	}
}
