import { obterFabricaSerie } from '@componentes/charts/fabrica-serie-variavel';
import { DadosPrecipitacao } from '@componentes/charts/series/serie-precipitacao';
import { DadosPressao } from '@componentes/charts/series/serie-pressao';
import { DadosTemperatura } from '@componentes/charts/series/serie-temperatura';
import { DadosUmidade } from '@componentes/charts/series/serie-umidade';
import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
} from '@componentes/charts/series/serie-variavel-meteorologica';
import { RespostaGraficoVariaveis } from '../../../interfaces/VariaveisMeteorologicas';
import { DadosVento } from '@componentes/charts/series/serie-vento';
import { DadosAgua } from '@componentes/charts/series/serie-agua';
import { isNotNuloOuUndefined } from '@utils';
import { DadosRadiacaoSolar } from '@componentes/charts/series/radiacao-solar';

function roundData(dados: number[]) {
	return dados.map(value =>
		isNotNuloOuUndefined(value) ? Number(value.toFixed(2)) : value
	);
}

/**
 * Transforma uma resposta com dados de historico das variável para uma serie de uma variável especifica
 *
 * @param {string} variavel a variável desejada
 * @param {number} indexEixo o indice do eixo na qual a série será inserida no gráfico
 * @param {RespostaGraficoVariaveis} dados os dados de historico
 * @returns {DadosSerieVariavelMeteorologica} a serie a ser inserida no gráfico
 */
export function obterDadosSeriePorResultado(
	variavel: string,
	indexEixo: number,
	dados: RespostaGraficoVariaveis
): DadosSerieVariavelMeteorologica {
	const fabricaSerie = obterFabricaSerie(variavel);
	const dadosGraficos = transformarDados(variavel, dados);
	if (fabricaSerie && dadosGraficos.datas.length > 0) {
		return fabricaSerie.obterDadosSerie(dadosGraficos, indexEixo);
	}
	return { eixo: {}, serie: [] };
}

export function transformarDados(
	variavel: string,
	dados: RespostaGraficoVariaveis
): DadosGraficoVariaveis {
	switch (variavel) {
		case 'PRECIPITACAO':
			if (possuiDados(dados.precipitacao)) {
				return {
					datas: dados.data,
					precipitacao: roundData(dados.precipitacao),
				} as DadosPrecipitacao;
			}
			break;

		case 'PRESSAO_ATMOSFERICA':
			if (possuiDados(dados.pressaoAtmosferica)) {
				return {
					datas: dados.data,
					pressao: roundData(dados.pressaoAtmosferica),
				} as DadosPressao;
			}
			break;

		case 'TEMPERATURA_DO_AR':
			if (possuiDados(dados.temperaturaAr)) {
				return {
					datas: dados.data,
					temperatura: roundData(dados.temperaturaAr),
				} as DadosTemperatura;
			}
			break;

		case 'TEMPERATURA_DO_SOLO':
			if (possuiDados(dados.temperaturaSolo)) {
				return {
					datas: dados.data,
					temperatura: roundData(dados.temperaturaSolo),
				} as DadosTemperatura;
			}
			break;

		case 'UMIDADE':
			if (possuiDados(dados.umidadeInstantanea)) {
				return {
					datas: dados.data,
					umidade: roundData(dados.umidadeInstantanea),
				} as DadosUmidade;
			}
			break;

		case 'RADIACAO_SOLAR':
			if (possuiDados(dados.radiacao)) {
				return {
					datas: dados.data,
					radiacao: roundData(dados.radiacao),
				} as DadosRadiacaoSolar;
			}
			break;

		case 'TEMPERATURA_SOLO_10CM':
		case 'TEMPERATURA_SOLO_20CM':
		case 'TEMPERATURA_SOLO_30CM':
		case 'TEMPERATURA_SOLO_40CM':
		case 'TEMPERATURA_SOLO_50CM':
		case 'TEMPERATURA_SOLO_60CM': {
			const profundidade = variavel.split('_')[2].toLowerCase();
			const campo = `temperaturaSolo${profundidade}` as keyof typeof dados;
			if (possuiDados(dados[campo])) {
				return {
					datas: dados.data,
					temperatura: roundData(dados[campo]),
				} as DadosTemperatura;
			}
			break;
		}

		case 'CONTEUDO_AGUA_SOLO_10CM':
		case 'CONTEUDO_AGUA_SOLO_20CM':
		case 'CONTEUDO_AGUA_SOLO_30CM':
		case 'CONTEUDO_AGUA_SOLO_40CM':
		case 'CONTEUDO_AGUA_SOLO_50CM':
		case 'CONTEUDO_AGUA_SOLO_60CM': {
			const profundidade = variavel.split('_')[3].toLowerCase();
			const campo = `conteudoAguaSolo${profundidade}` as keyof typeof dados;
			if (possuiDados(dados[campo])) {
				return {
					datas: dados.data,
					conteudo: roundData(dados[campo]),
				} as DadosAgua;
			}
			break;
		}

		case 'UMIDADE_INTERNA':
			if (possuiDados(dados.umidadeInterna)) {
				return {
					datas: dados.data,
					umidade: roundData(dados.umidadeInterna),
				} as DadosUmidade;
			}
			break;

		case 'UMIDADE_SOLO':
			if (possuiDados(dados.umidadeSolo)) {
				return {
					datas: dados.data,
					umidade: roundData(dados.umidadeSolo),
				} as DadosUmidade;
			}
			break;

		case 'TEMPERATURA_INTERNA':
			if (possuiDados(dados.temperaturaInterna)) {
				return {
					datas: dados.data,
					temperatura: roundData(dados.temperaturaInterna),
				} as DadosTemperatura;
			}
			break;

		case 'VENTO':
			if (possuiDados(dados.ventoVelocidade)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade),
					direcaoVento: roundData(dados.ventoDirecao),
				} as DadosVento;
			}
			break;

		case 'VENTO_2M':
			if (possuiDados(dados.vento2m)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.vento2m),
					direcaoVento: roundData(dados.ventoDirecao2),
				} as DadosVento;
			}
			break;

		case 'VENTO_1_MAXIMA':
			if (possuiDados(dados.ventoVelocidade1Maxima)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade1Maxima),
					direcaoVento: roundData(dados.ventoDirecao1Maxima),
				} as DadosVento;
			}
			break;

		case 'VENTO_2_MAXIMA':
			if (possuiDados(dados.ventoVelocidade2Maxima)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade2Maxima),
					direcaoVento: roundData(dados.ventoDirecao2Maxima),
				} as DadosVento;
			}
			break;

		case 'VENTO_DIRECAO_1_DESVIO_PADRAO':
			if (possuiDados(dados.ventoVelocidade)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade),
					direcaoVento: roundData(dados.ventoDirecao1DesvioPadrao),
				} as DadosVento;
			}
			break;

		case 'VENTO_DIRECAO_2_DESVIO_PADRAO':
			if (possuiDados(dados.ventoVelocidade)) {
				return {
					datas: roundData(dados.data),
					velocidadeVento: roundData(dados.ventoVelocidade),
					direcaoVento: roundData(dados.ventoDirecao2DesvioPadrao),
				} as DadosVento;
			}
			break;
	}
	return { datas: [] };
}

function possuiDados(listaDados: number[]): boolean {
	return listaDados.filter(value => value != null).length > 0;
}
