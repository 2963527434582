import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { Usuario } from '@administracao/submodulos/usuario/interfaces/usuario';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import {
	KEY_LOCAL_STORAGE_PERMISSIONS,
	PermissoesEnum,
} from '@administracao/submodulos/grupo/enums/permissoesEnum';
import { Rotas } from '@core/enums/routes';
import { ReverseGeocode } from '@modulos/home/submodulos/previsao-numerica-tempo/interfaces/meteograma';

@Injectable({
	providedIn: 'root',
})
export class UsuarioService {
	constructor(private http: HttpClientProtectedService) {}
	listarPermissoesDoUsuario() {
		return this.http.get<PermissoesEnum[]>(Rotas.PERMISSOES_USUARIO).pipe(
			map(permissions => {
				localStorage.setItem(
					KEY_LOCAL_STORAGE_PERMISSIONS.PERMISSIONS,
					permissions.join(';')
				);
				return permissions;
			})
		);
	}

	adicionar(newUsuario: Usuario): Observable<Usuario> {
		return this.http.post<Usuario, Usuario>(Rotas.USUARIO, newUsuario);
	}

	editar(editUsuario: Usuario): Observable<Usuario> {
		return this.http.patch<Usuario, any>(
			Rotas.USUARIO + '/' + editUsuario.id,
			editUsuario
		);
	}

	remover(usuarioId: number): Observable<any> {
		return this.http.delete<Usuario>(Rotas.USUARIO + '/' + usuarioId);
	}

	buscarPorId(usuarioId: number): Observable<Usuario> {
		return this.http.get<Usuario>(
			Rotas.USUARIO + '/' + usuarioId + '?projection=usuarioProjectionAll'
		);
	}

	list(): Observable<any> {
		return this.http.get<Usuario[]>(Rotas.USUARIO);
	}

	private async getUserLocation(): Promise<GeolocationPosition> {
		return new Promise(function (resolve, reject) {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});
	}

	async getUserLocationInfo(): Promise<ReverseGeocode> {
		const location = await this.getUserLocation();
		return lastValueFrom(
			this.buscarLozalizacaoPorGeoloc(
				location.coords.latitude,
				location.coords.longitude
			)
		);
	}

	buscarLozalizacaoPorGeoloc(latitude: number, longitude: number) {
		return this.http.get<ReverseGeocode>(
			'meteograma/municipio-por-coordenadas',
			{
				latitude,
				longitude,
			}
		);
	}
}
