import {
	AfterViewInit,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';
import { defaultLanguageOptionsDatatablesPtBr } from '@utils';
import { DataTableDirective } from 'angular-datatables';
import {
	ADTSettingsWithButton,
	HeaderTableStyle,
	TableButtons,
} from './interfaces/menuOptions';
import { Subject } from 'rxjs';

declare let $: any;

@Component({
	selector: 'seira-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent
	implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
	@Input() data: any[] = [];
	@Input() columns!: ADTColumns[];
	@ViewChild(DataTableDirective, { static: false }) tabela!: DataTableDirective;
	@Input() buttons = new TableButtons();
	@Input() class = 'table table-xs dt-responsive nowrap';
	@Input() searching = true;
	@Input() paging = true;
	@Input() scrollX = false;
	@Input() info = true;
	@Input() hasFooter = true;
	@Input() hasHeader = true;
	@Input() styling: HeaderTableStyle = {
		thClass: '',
		radius: false,
		radiusCoeficient: '',
	};
	@Input() order?: ADTSettings['order'];
	isMobile = false;

	dtOptions: ADTSettingsWithButton = {};
	dtTrigger: Subject<any> = new Subject();
	constructor() {}

	async removeRow(pk: any, field: string) {
		(await this.tabela.dtInstance)
			.rows(function (idx: any, data: any) {
				return data[field] === pk;
			})
			.remove()
			.draw();
	}

	borderRadius(index: number) {
		if (!this.styling.radius) return '';
		if (index === 0)
			return `border-radius: ${this.styling.radiusCoeficient || '5px'} 0 0 0`;
		if (index === this.columns.length - 1)
			return `border-radius: 0 ${this.styling.radiusCoeficient || '5px'} 0 0`;
		return '';
	}

	generateOption(data: any[]): ADTSettings {
		this.isMobile = window.innerWidth < 560;
		return {
			data,
			lengthChange: true,
			searching: this.searching,
			scrollX: this.scrollX,
			paging: this.paging,
			pagingType: this.isMobile ? 'full' : 'simple_numbers',
			info: this.info,
			order: this.order,
			dom: `${
				this.hasHeader
					? '<"datatable-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between text-center"fB>'
					: ''
			}<"datatable-scroll"t>
			<"datatable-footer d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-between text-center"i<"d-flex flex-column flex-sm-row flex-wrap gap-3"l p>>`,
			language: defaultLanguageOptionsDatatablesPtBr,
			columns: this.columns,
			// @ts-ignore
			buttons: this.generateButtons(this.buttons),
		};
	}

	ngOnInit(): void {
		this.dtOptions = this.generateOption(this.data);

		const parseNumericData = (data: any): number | null => {
			if (!data) return null;
			if (typeof data === 'number') return data;

			const withoutThousandSeparator = data.replace(/\./g, '');
			const cleanedData = withoutThousandSeparator.replace(/,/g, '.');
			const finalData = cleanedData.replace(/[^0-9.-]/g, '');

			if (finalData === '-') return -Infinity;

			const num = parseFloat(finalData);
			return isNaN(num) ? null : num;
		};

		$.fn.dataTable.ext.type.order['number-pre'] = function (data: any) {
			return parseNumericData(data) ?? data;
		};

		$.fn.dataTable.ext.type.order['string-pre'] = function (
			data: string
		): string | number {
			const num = parseNumericData(data);
			if (num !== null) return num;

			if (typeof data !== 'string') return data;

			return data
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase();
		};

		setTimeout(() => {
			this.dtOptions = this.generateOption(this.data);
		});
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next(undefined);
	}

	generateButtons(props: TableButtons) {
		const setAble = (value: boolean) => (value ? '' : 'disabled');
		const buttons = [
			{
				className: `btn btn-secondary buttons-select-all btn-light ${setAble(
					props.selectAll
				)}`,
				text: '<span><i class="ph-list-checks"></i></span>',
				action: async () => {
					// (await this.tabela.dtInstance).rows({page: 'current'}).select();
				},
			},
			{
				className: `btn btn-secondary buttons-select-none btn-light ${setAble(
					props.unSelectAll
				)}`,
				text: '<span><i class="ph-list"></i></span>',
				action: async () => {},
			},
			{
				className: `btn btn-secondary disabled btn_aceitar_ponto btn-light ${setAble(
					props.aprove
				)}`,
				text: '<span><i class="ph-checks"></i> Aprovar</span>',
				action: async () => {},
			},
			{
				extend: 'csvHtml5',
				className: `btn btn-secondary disabled btn_remove btn-light ${setAble(
					props.remove
				)}`,
				text: '<span><i class="ph-trash"></i> Remover</span>',
				action: () => {},
			},
			{
				extend: 'copyHtml5',
				className: `btn btn-secondary buttons-copy buttons-html5 btn-light ${setAble(
					props.copy
				)}`,
				text: '<span><i class="ph-copy me-2"></i> Copiar</span>',
				bom: true,
				exportOptions: {
					columns: this.columns
						.map((_column, index) => {
							if (_column.title !== 'Ações') {
								return index;
							}
							return this.columns.length;
						})
						.filter(index => index < this.columns.length - 1),
				},
			},
			{
				extend: 'csvHtml5',
				className: `btn btn-secondary buttons-csv buttons-html5 btn-light ${setAble(
					props.csv
				)}`,
				text: '<span><i class="ph-file-csv me-2"></i> CSV</span>',
				bom: true,
				fieldSeparator: ';',
				exportOptions: {
					columns: this.columns
						.map((_column, index) => {
							if (_column.title !== 'Ações') {
								return index;
							}
							return this.columns.length;
						})
						.filter(index => index <= this.columns.length - 1),
				},
			},
		];

		return buttons.filter(button => !button.className.includes('disabled'));
	}

	refreshTable() {
		this.tabela.dtInstance.then(instance => {
			instance.destroy();
			this.tabela.dtTrigger.next(this.generateOption(this.data));
		});
	}
	ngOnDestroy(): void {
		this.dtTrigger.unsubscribe();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if ((changes['data'] || changes['columns']) && this.tabela) {
			this.refreshTable();
		}
	}
}
