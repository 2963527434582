<div class="mb-2" style="position: relative">
	<label *ngIf="label" class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span></label
	>
	<input
		class="px-1"
		#inputElement
		seiraInputType
		decimalMarker=","
		[inputType]="inputType"
		[maxlength]="maxlength"
		[minlength]="minlength"
		decimalMarker=","
		thousandSeparator="."
		[mask]="mask"
		[type]="type"
		[step]="step"
		[formControl]="control"
		[placeholder]="placeholder"
		[accept]="accept"
		[class]="classInput"
		[id]="id"
		(focus)="(focus)"
		[ngClass]="showsTextRequired || seiraError ? 'is-invalid' : ''"
		(focus)="(focus)"
		(change)="onFileSelected($event); formatValue($event)" />
	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
