import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { HTMLInputTypeAttribute } from '@layout/interfaces/htmlTypeInput';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';
import { InputType } from '@utils/directives/interfaces/input-type';
import { DecimalPipe } from '@angular/common';

@Component({
	selector: 'seira-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class InputComponent implements OnInit, OnChanges {
	@Input() id?: string;
	@Input() label?: string;
	@Input() required = false;
	@Input() controlName!: string;
	@Input() accept?: string;
	@Input() step?: number;
	@Input() placeholder = '';
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() type: HTMLInputTypeAttribute = 'text';
	@Input() classInput = 'form-control';
	@Input() casasDecimais = 1;
	@Input() mask?: string | null = null;
	@Input() maxlength: string | number | null = null;
	@Input() minlength: string | number | null = null;
	@Input() inputmode?: string;
	@Input() inputType?: InputType;
	@Input() control!: FormControl;
	@Input() focus?: boolean;
	@Output() fileSelected: EventEmitter<File | null> =
		new EventEmitter<File | null>();

	@ViewChild('inputElement') inputElement!: ElementRef;

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		if (!this.control) {
			this.control = this.controlContainer.control?.get(
				this.controlName
			) as FormControl;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['focus']) {
			setTimeout(() => {
				if (this.focus && this.inputElement !== undefined) {
					this.inputElement.nativeElement.focus();
				}
			}, 200);
		}
	}

	formatValue(valueEvent: any): void {
		const value = valueEvent.target.value;

		if (value !== null && this.casasDecimais > 1) {
			if (isNaN(value)) {
				this.control.setValue(
					new DecimalPipe('pt').transform(
						parseFloat(value.toString().replaceAll('.', '').replace(',', '.')),
						`1.${this.casasDecimais}-${this.casasDecimais}`
					)
				);
			} else {
				this.control.setValue(
					new DecimalPipe('pt').transform(
						parseFloat(value),
						`1.${this.casasDecimais}-${this.casasDecimais}`
					)
				);
			}
		} else {
			this.control.setValue(value);
		}
	}

	onFileSelected(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		const file = inputElement.files?.[0] || null;

		// Emitir o arquivo selecionado para o componente pai
		this.fileSelected.emit(file);
	}

	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}

	get seiraError() {
		if (this.control?.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				switch (erros[0]) {
					case 'required':
						if (this.control.touched) return 'Campo obrigatório.';
						break;
					case 'min':
						return (
							'O valor deve ser maior ou igual a ' +
							this.control?.errors[erros[0]]?.min
						);
					case 'max':
						return (
							'O valor deve ser menor ou igual a ' +
							this.control?.errors[erros[0]]?.max
						);
					case 'pattern':
						if (this.control?.errors[erros[0]].actualValue.includes('.')) {
							return 'É aceito apenas números com vírgulas';
						}
						return 'Está fora dos padrões necessários';
					default:
						return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
