import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { enumAsSelectOptions, numberToBrNumber } from '@utils';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import {
	AgrupamentoEspecial,
	AgrupamentoResponse,
	DadosAgrupamento,
	Estacao,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
	PostosRelatorios,
} from '../../../interfaces';
import { RelatoriosService } from '../../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { DateTimeUtils } from '@utils/datetime-util';
import { Select } from '@layout/interfaces/select';
import { corrigeDuplicacaoNome } from '@home/submodulos/dados-meteorologicos/utils';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';

@Component({
	selector: 'seira-tabela-pluviometria-observada',
	templateUrl: './tabela-pluviometria-observada.component.html',
	styleUrls: ['./tabela-pluviometria-observada.component.scss'],
})
export class TabelaPluviometriaObservadaComponent
	implements OnDestroy, InstanciaRelatorio, OnInit
{
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaPluviometriaObservada: any[];
	loading = true;
	periodo = '';
	_destroyed = new Subject();
	agrupamento: Select[] | any = [];
	descricaoRelatorio =
		'Define-se como pluviometria observada a quantidade total de chuva registrada em um local ou região específica, medida ao longo de um período.';

	colunas: ADTColumns[] = [];
	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaPluviometriaObservada', { static: false })
	tabela: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Município', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.dadosTabelaPluviometriaObservada,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	disabledSelectList = false;
	tituloColunaAgrupamento = 'Nome';
	tituloTabela = '';
	municipioPostos: Select<string>[];
	periodoAgrupamento = '';

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.agrupamento = enumAsSelectOptions(AgrupamentoEspecial);
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.setValidators();
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);

		const todosMunicipiosPostos = this.inputs.municipioPostos;
		this.municipioPostos = [
			{ value: '0', name: 'Estado completo' },
			...todosMunicipiosPostos,
		];
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValue(this.municipioPostos[0].value);

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	atualizarTituloColuna(): void {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		this.tituloColunaAgrupamento =
			AgrupamentoEspecial[agrupamento as keyof typeof AgrupamentoEspecial];
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: 'Município/Posto',
					type: 'string',
					className: 'text-left',
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'municipio',
					title: 'Município',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'data',
					title: 'Data',
					className: 'text-center',
					render: (data: string) => {
						const dataCorrigida = data.replace(/\.0/g, '');
						const periodoAnual = dataCorrigida.length === 4;
						const periodoMensal = dataCorrigida.length === 7;
						if (periodoAnual) {
							return dataCorrigida;
						} else if (periodoMensal) {
							return DateTimeUtils.formatarData(dataCorrigida, 'MM/yyyy');
						}
						return DateTimeUtils.formatarData(dataCorrigida, 'dd/MM/yyyy');
					},
				},
				{
					data: 'precipitacaoAcumulada',
					title: 'Pluviometria Observada (mm)',
					className: 'text-center',
				},
			]
		);
	}

	gerarRelatorio(): void {
		if (this.inputs.form.invalid) {
			return;
		}
		this.loading = true;
		this.inputs.setLoading(true);
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;

		this.periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		this.periodo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			this.periodoAgrupamento
		);
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		this.inputs.setLoading(true);
		const posto = this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value;

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			municipio: this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.value,
			posto: posto === 'todos' ? null : posto,
			microrregiao: this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)
				?.value,
			periodoAgrupamento: this.periodoAgrupamento.toUpperCase(),
		};

		this.relatoriosService.buscarRelatorioPorAgrupamento(dados).subscribe({
			next: preciptacoesAgrupadas => {
				this.relatoriosService.verificaExistenciaDados(preciptacoesAgrupadas);
				this.dadosTabelaPluviometriaObservada = this.padronizaResultado(
					preciptacoesAgrupadas,
					agrupamento
				);
				this.atualizarTituloColuna();
				this.atualizarColunasTabela();
				this.tituloTabela = `Pluviometria observada - ${this.periodo}`;
			},
			error: (err: HttpErrorResponse) => {
				this.inputs.setLoading(false);
				this.loading = false;
				this.dadosTabelaPluviometriaObservada = [];
				this.toastr.error(
					'Erro ao buscar dados de Pluviometria observada',
					err.message
				);
			},
			complete: () => {
				this.inputs.setLoading(false);
				this.loading = false;
			},
		});
	}

	padronizaResultado(resultados: AgrupamentoResponse[], agrupamento: string) {
		return resultados.map(resultado => {
			const base = {
				nome:
					agrupamento === 'MUNICIPIO_POSTO'
						? corrigeDuplicacaoNome(resultado.nome)
						: resultado.nome,
				data: resultado.data,
				precipitacaoAcumulada:
					numberToBrNumber(resultado.precipitacaoAcumulada, 1) || '-',
			};

			return agrupamento === 'MUNICIPIO_POSTO'
				? {
						...base,
						...this.obterDetalhesPosto(resultado.dadosPosto),
				  }
				: base;
		});
	}

	private obterDetalhesPosto(dadosPosto: any) {
		return {
			bacia: dadosPosto.bacia || '-',
			altitude: numberToBrNumber(dadosPosto.altitude, 1) || '-',
			latitude: numberToBrNumber(dadosPosto.latitude, 4) || '-',
			longitude: numberToBrNumber(dadosPosto.longitude, 4) || '-',
			mesorregiao: dadosPosto.mesorregiao || '-',
			microrregiao: dadosPosto.microrregiao || '-',
			municipio: dadosPosto.municipio || '-',
			regiao: dadosPosto.regiao || '-',
			subbacia: dadosPosto.subbacia || '-',
		};
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}
}
