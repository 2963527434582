<button
	[id]="idButton"
	[disabled]="disabledButton || loading"
	[routerLink]="link"
	[type]="type"
	[ngClass]="customClass"
	class="btn fw-medium fs-6 px-3 custom-height button-{{ buttonStyle }}"
	(click)="clicked.emit($event)">
	<div *ngIf="!loading; else loadingIcon">
		<ng-content></ng-content>
	</div>
	<ng-template #loadingIcon>
		<i class="spinner-border custom-height"></i>
	</ng-template>
</button>
