import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import * as ExcelTable from 'mr-excel';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { BoletimDiarioService } from '@home/submodulos/dados-meteorologicos/services/boletim-diario.service';
import {
	Boletim,
	DadosTabelaBoletimDiario,
	DashboardItems,
	Posto,
	PrecipitacaoPosto,
	TiposAgrupamentoBoletimDiario,
	RespostaBoletimPeriodo,
} from '@home/submodulos/dados-meteorologicos/interfaces/boletim';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	isNotNuloOuUndefined,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { ToastrService } from 'ngx-toastr';
import { DateTimeUtils } from '@utils/datetime-util';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ValorPorGeocodigo } from '@componentes/mapa-paraiba-svg/mapa.interfaces';
import { EstacoesService } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/services/estacoes.service';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import * as pdfseira from '@utils/pdf-seira';
import { DocumentExporter } from '@utils/document-exporter';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalPdfOptionsComponent } from '../../componentes/modal-pdf-options/modal-pdf-options.component';
import { Table } from 'pdfmake/interfaces';
import { PeriodoBoletim } from '../../enum/PeriodoBoletim';
import { combineLatest } from 'rxjs';
import moment from 'moment';
import { DataModel } from 'mr-excel';
import { Mensagens } from '@core/enums/mensagens';

@Component({
	selector: 'seira-boletim-diario',
	templateUrl: './boletim-diario.component.html',
	styleUrls: ['./boletim-diario.component.scss'],
})
export class BoletimDiarioComponent implements OnInit, OnDestroy {
	formBoletim!: FormGroup;
	chuvaPorMunicipio: ValorPorGeocodigo[] = [];
	loadingBoletim = false;
	boletim?: Boletim;
	top10Estacoes: PrecipitacaoPosto[] = [];
	OpcoesTipoAgrupamento: OptionRadio<TiposAgrupamentoBoletimDiario>[] = [
		{
			label: 'Região Pluviométrica',
			value: TiposAgrupamentoBoletimDiario.REGIAO,
		},
		{ label: 'Mesorregião', value: TiposAgrupamentoBoletimDiario.MESORREGIAO },
	];
	dadosTabela: DadosTabelaBoletimDiario = {
		[TiposAgrupamentoBoletimDiario.REGIAO]: {},
		[TiposAgrupamentoBoletimDiario.MESORREGIAO]: {},
	};
	tabelaFiltrada: PrecipitacaoPosto[] = [];
	getTabelaVazia(mensagem: string): any[][] {
		return [
			[
				{
					text: 'Município',
					fillColor: '#DCDCDC',
					alignment: 'left',
					width: '500px',
				},
				{
					text: 'Tipo de posto',
					fillColor: '#DCDCDC',
					alignment: 'center',
					width: '200px',
				},
				{
					text: 'Total (mm)',
					fillColor: '#DCDCDC',
					alignment: 'center',
					width: '100px',
				},
			],
			[
				{
					text: mensagem,
					colSpan: 3,
					alignment: 'center',
					fillColor: '',
					margin: [0, 8, 0, 4],
				},
				{},
				{},
			],
		];
	}
	opcoesAgrupamentos: {
		[TiposAgrupamentoBoletimDiario.REGIAO]: Array<OptionRadio<string>>;
		[TiposAgrupamentoBoletimDiario.MESORREGIAO]: Array<OptionRadio<string>>;
	} = {
		[TiposAgrupamentoBoletimDiario.REGIAO]: [
			{
				label: 'TODOS',
				value: 'TODOS',
			},
		],
		[TiposAgrupamentoBoletimDiario.MESORREGIAO]: [
			{
				label: 'TODOS',
				value: 'TODOS',
			},
		],
	};

	colunasTabelaBoletim: Array<DataTables.ColumnSettings> = [
		{
			title: 'Município/Posto',
			data: 'detalhesPosto',
			className: 'text-tertiary text-left',
			render: function (data) {
				return data.municipio === data.posto
					? data.municipio
					: data.municipio + '/' + data.posto;
			},
			width: '500px',
		},
		{
			title: 'Tipo de posto',
			data: 'tipoEstacao',
			className: 'text-tertiary text-center',
			render: function (data: keyof typeof TipoEstacao) {
				if (data === 'PLUVIOMETRO_CONVENCIONAL') {
					return 'PCO';
				} else {
					return TipoEstacao[data];
				}
			},
			width: '200px',
		},
		{
			title: 'Precipitação (mm)',
			data: 'precipitacaoAcumulada',
			render: (data: number) => {
				return this.numberToBrNumber(data, 1);
			},
			className: 'text-tertiary text-center',
			width: '100px',
		},
	];
	colunasTabelaTop10Estacoes: Array<DataTables.ColumnSettings> = [
		{
			title: 'Município/Posto',
			data: 'detalhesPosto',
			render: function (data) {
				return data.municipio === data.posto
					? data.municipio
					: data.municipio + '/' + data.posto;
			},
			className: 'text-tertiary text-left',
		},
		{
			title: 'Tipo de posto',
			data: 'tipoEstacao',
			render: function (data: keyof typeof TipoEstacao) {
				if (data === 'PLUVIOMETRO_CONVENCIONAL') {
					return 'PCO';
				} else {
					return TipoEstacao[data];
				}
			},
			className: 'text-tertiary text-center',
		},
		{
			title: 'Precipitação (mm)',
			data: 'precipitacaoAcumulada',
			className: 'text-tertiary text-center',
			render: data => {
				return this.numberToBrNumber(data, 1);
			},
		},
	];
	OpcoesTipoVisualizacao: OptionRadio<'mapa' | 'tabela'>[] = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];

	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que você vai encontrar nesta página:',
				text: 'Informações pluviométricas diárias coletadas através de Pluviômetro tipo <i class="fst-italic fw-medium">Ville de Paris</i> com medição manual (PCO) ou automaticamente (Plataforma de Coleta de Dados - PCD), organizadas por local de observação (posto, município, região, etc.).',
			},
		],
	};

	carregando = true;
	dashboardItems!: DashboardItems;
	data: Date = new Date();
	private readonly opcaoTodosTabela = 'TODOS';
	modalFechadoAposConfirmar = false;

	constructor(
		private formBuilder: FormBuilder,
		private boletimService: BoletimDiarioService,
		private toastr: ToastrService,
		private estacoesService: EstacoesService,
		private modalService: BsModalService
	) {}

	ngOnInit() {
		this.formBoletim = this.formBuilder.group({
			periodo: [null, [Validators.required]],
			tipoVisualizacao: new FormControl('mapa'),
			agrupamentoTabela: new FormControl<TiposAgrupamentoBoletimDiario>(
				TiposAgrupamentoBoletimDiario.REGIAO
			),
			opcoesTabela: new FormControl(this.opcaoTodosTabela),
		});
		this.setObservables();
		this.buscarDadosUltimoBoletim();
		(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

		window.addEventListener('scroll', this.scroll, true);
	}
	setObservables() {
		this.formBoletim.get('agrupamentoTabela')?.valueChanges.subscribe({
			next: (val: TiposAgrupamentoBoletimDiario) => {
				const opcoesTabela = this.formBoletim.get('opcoesTabela');
				opcoesTabela?.setValue(this.opcaoTodosTabela);
				this.tabelaFiltrada = this.dadosTabela[val][opcoesTabela?.value] || [];
			},
		});
		this.formBoletim.get('opcoesTabela')?.valueChanges.subscribe({
			next: (val: string) => {
				const agrupamento = this.formBoletim.get('agrupamentoTabela');
				if (agrupamento && agrupamento.value) {
					this.tabelaFiltrada =
						this.dadosTabela[
							agrupamento.value as TiposAgrupamentoBoletimDiario
						][val] || [];
				}
			},
		});
	}

	scroll() {
		const element = document.getElementById('boletim_diario');
		if (element) {
			const rect = element.getBoundingClientRect();
			if (rect.bottom + (rect.bottom - rect.top - 250) <= window.innerHeight) {
				const popovers = document.getElementsByTagName('popover-container');
				const tooltips = document.getElementsByTagName('bs-tooltip-container');
				for (let index = 0; index < popovers.length; index++) {
					popovers[index].setAttribute('style', 'display:none');
				}
				for (let index = 0; index < tooltips.length; index++) {
					tooltips[index].setAttribute('style', 'display:none');
				}
			}
		}
	}
	ngOnDestroy() {
		window.removeEventListener('scroll', this.scroll, true);
	}

	get agrupamento() {
		return this.formBoletim.get('agrupamentoTabela')
			?.value as TiposAgrupamentoBoletimDiario;
	}

	getFormItemValue(formValue: string) {
		return this.formBoletim.get(formValue)?.value;
	}

	setFiltrosAgrupamento() {
		this.estacoesService.filtros({}).subscribe({
			next: response => {
				response.mesorregioes.forEach(mesorregiao => {
					this.opcoesAgrupamentos[
						TiposAgrupamentoBoletimDiario.MESORREGIAO
					].push({
						label: mesorregiao.nome,
						value: mesorregiao.nome,
					});
				});
				response.regioes.forEach(regiao => {
					this.opcoesAgrupamentos[TiposAgrupamentoBoletimDiario.REGIAO].push({
						label: regiao.nome.toUpperCase(),
						value: regiao.nome,
					});
				});
			},
			error: () => {
				this.toastr.error('Erro ao buscar mesorregiões!');
			},
		});
	}

	updateChuvasPorMunicipio(resp?: Boletim) {
		this.limparTabelas();
		if (resp) {
			resp.chuvasPostosPorMesorregiao.forEach(postoPorMeso => {
				this.dadosTabela[TiposAgrupamentoBoletimDiario.MESORREGIAO][
					postoPorMeso.mesorregiao
				] = postoPorMeso.precipitacaoPostos;

				if (
					this.dadosTabela[TiposAgrupamentoBoletimDiario.MESORREGIAO][
						this.opcaoTodosTabela
					]
				) {
					this.dadosTabela[TiposAgrupamentoBoletimDiario.MESORREGIAO][
						this.opcaoTodosTabela
					].push(...postoPorMeso.precipitacaoPostos);
				} else {
					this.dadosTabela[TiposAgrupamentoBoletimDiario.MESORREGIAO][
						this.opcaoTodosTabela
					] = [...postoPorMeso.precipitacaoPostos];
				}

				postoPorMeso.precipitacaoPostos.forEach(precipitacaoPostos => {
					const campoRegiao = precipitacaoPostos.detalhesPosto.nomeRegiao;
					if (campoRegiao) {
						if (
							this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][
								this.opcaoTodosTabela
							]
						) {
							this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][
								this.opcaoTodosTabela
							].push(precipitacaoPostos);
						} else {
							this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][
								this.opcaoTodosTabela
							] = [precipitacaoPostos];
						}
					}

					if (
						this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][campoRegiao]
					) {
						this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][
							campoRegiao
						].push(precipitacaoPostos);
					} else {
						this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][
							campoRegiao
						] = [precipitacaoPostos];
					}
					const alreadyExists = this.chuvaPorMunicipio.find(
						chuva =>
							chuva.geocodigo ===
							precipitacaoPostos.detalhesPosto.codigoMunicipio
					);
					let estacoesDoMunicipio: Posto[];
					if (alreadyExists?.data) {
						estacoesDoMunicipio = alreadyExists.data;
					} else {
						estacoesDoMunicipio = postoPorMeso.precipitacaoPostos
							.filter(
								el =>
									el.detalhesPosto.codigoMunicipio ==
									precipitacaoPostos.detalhesPosto.codigoMunicipio
							)
							.map(el => el.detalhesPosto);
					}
					if (
						alreadyExists &&
						alreadyExists.valor >= precipitacaoPostos.precipitacaoAcumulada
					) {
						return;
					}

					if (
						alreadyExists &&
						alreadyExists.valor < precipitacaoPostos.precipitacaoAcumulada
					) {
						this.chuvaPorMunicipio.splice(
							this.chuvaPorMunicipio.indexOf(alreadyExists),
							1
						);
					}

					this.chuvaPorMunicipio.push({
						valor: precipitacaoPostos.precipitacaoAcumulada,
						geocodigo: precipitacaoPostos.detalhesPosto.codigoMunicipio,
						data: estacoesDoMunicipio,
					});
				});
			});
			this.updateTabelaFiltrada();
		} else {
			this.tabelaFiltrada = [];
		}
		this.boletim = resp;
		if (this.boletim !== null) {
			setTimeout(() => this.getTop10Estacoes(), 100);
		}
		this.atualizarDashBoard();
	}

	updateTabelaFiltrada() {
		const opcaoTabela = this.formBoletim.get('opcoesTabela');
		if (opcaoTabela?.value) {
			this.tabelaFiltrada =
				this.dadosTabela[this.agrupamento][opcaoTabela.value] || [];
		}
	}

	buscarDadosUltimoBoletim() {
		this.loadingBoletim = true;
		this.setFiltrosAgrupamento();
		this.boletimService.consultarUltimoBoletim().subscribe({
			next: resp => {
				this.updateChuvasPorMunicipio(resp);
				this.loadingBoletim = false;
				this.carregando = false;
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(msg_erro, 'Erro ao buscar ultimo  boletim do dia');
				this.loadingBoletim = false;
			},
		});
	}

	getTop10Estacoes() {
		const mergePrecipitacaoPostos =
			this.boletim!.chuvasPostosPorMesorregiao.reduce(
				(acumulador: any, atualObjetoChuvaPosto: any) => {
					return [...acumulador, ...atualObjetoChuvaPosto.precipitacaoPostos];
				},
				[]
			);
		this.top10Estacoes = mergePrecipitacaoPostos
			.filter(dado => dado.precipitacaoAcumulada > 0)
			.sort((a, b) => b.precipitacaoAcumulada - a.precipitacaoAcumulada)
			.slice(0, 10)
			.map(dado => ({
				...dado,
				precipitacaoAcumulada: dado.precipitacaoAcumulada.toFixed(1),
			}));
	}

	atualizarDashBoard() {
		this.dashboardItems = {
			pcd: [
				{
					label: 'ativas',
					value: this.boletim
						? this.boletim?.estatisticasPostosBoletim?.numeroPCDs || 0
						: '-',
				},
			],
			numeroEstacoes: [
				{
					label: 'ativas',
					value: this.boletim
						? this.boletim?.estatisticasPrecipitacaoBoletim
								?.numeroEstacoesAtivas || 0
						: '-',
				},
				{
					label: 'com chuva',
					value: this.boletim
						? this.boletim?.estatisticasPrecipitacaoBoletim
								?.numeroEstacoesComChuva || 0
						: '-',
				},
				{
					label: 'sem chuva',
					value: this.boletim
						? this.boletim?.estatisticasPrecipitacaoBoletim
								?.numeroEstacoesSemChuva || 0
						: '-',
				},
			],
			pluviometros: [
				{
					label: 'ativas',
					value: this.boletim
						? this.boletim?.estatisticasPostosBoletim
								?.numeroPluviometrosManuais || 0
						: '-',
				},
			],
			botoesDeExportacao: [
				{
					label: '.pdf',
					size: 'big',
					icon: 'ph-file-pdf',
					onClick: () => this.abrirModal(),
				},
				{
					label: '.csv',
					size: 'big',
					icon: 'ph-file-csv',
					onClick: () => this.exportToCSV(),
				},
				{
					label: '.txt',
					size: 'big',
					icon: 'ph-file-text',
					onClick: () => this.exportToTXT(),
				},
				{
					label: '.xls',
					size: 'big',
					icon: 'ph-file-xls',
					onClick: () => this.exportToXLS(),
				},
			] as GroupButton[],
		};
	}

	boletinsPorDataInformada() {
		if (this.formBoletim.invalid) {
			return;
		}
		this.loadingBoletim = true;
		const dataConvertida = this.converterDateInZonedDateTime();
		this.boletimService
			.buscarBoletinsPorDataEspecifica(dataConvertida)
			.subscribe({
				next: resp => {
					if (resp) {
						this.updateChuvasPorMunicipio(resp);
						this.data = new Date(dataConvertida);
						this.loadingBoletim = false;
					} else {
						this.boletim = undefined;
						this.data = new Date(dataConvertida);
						this.limparTabelas();
						this.atualizarDashBoard();
						this.loadingBoletim = false;
					}
				},
				error: err => {
					const msg_erro = obter_erro_request(err);
					this.toastr.error(
						msg_erro,
						'Erro ao buscar boletins de acordo com a datainformada'
					);
					this.loadingBoletim = false;
				},
			});

		this.formBoletim.get('periodo')?.setValue(null);
	}

	limparTabelas() {
		this.top10Estacoes = [];
		for (const keys in this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO]) {
			this.dadosTabela[TiposAgrupamentoBoletimDiario.REGIAO][keys] = [];
		}
		for (const keys in this.dadosTabela[
			TiposAgrupamentoBoletimDiario.MESORREGIAO
		]) {
			this.dadosTabela[TiposAgrupamentoBoletimDiario.MESORREGIAO][keys] = [];
		}
		this.tabelaFiltrada = [];
		this.chuvaPorMunicipio = [];
	}

	converterDateInZonedDateTime() {
		const dataSelecionada = this.formBoletim.get('periodo')?.value;
		return DateTimeUtils.formatarData(
			dataSelecionada,
			"yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX"
		);
	}

	getMapaElemento() {
		const svgContainer = document.getElementById('composicao-imagem');
		const svgContent = svgContainer?.querySelector('svg')!;
		return svgContent.outerHTML;
	}

	getDataFormatada(data: Date): string {
		return moment(data).format('DD/MM/YYYY');
	}

	async exportToPDF(
		dataInicio: Date | null,
		ano: Date | null,
		limiteInferiorMes: number | null,
		limiteInferiorAno: number | null,
		periodo: PeriodoBoletim | null,
		tipoPosto: TipoEstacao | null
	) {
		let dataFim = null;
		if (dataInicio) {
			const dataFormatada = new Date(dataInicio);
			dataFim = new Date(
				dataFormatada.getFullYear(),
				dataFormatada.getMonth() + 1,
				0
			);
		}

		const svg = this.getMapaElemento();

		const dataFormatada = this.getDataFormatada(this.data);

		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push([
			{
				text: `Boletim Pluviométrico Diário - ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				columns: [
					{
						text: `Estações ativas: ${
							this.boletim?.estatisticasPrecipitacaoBoletim
								.numeroEstacoesAtivas || 0
						} `,
						margin: [0, 0, 0, 8],
						fontSize: 10,
					},
					{
						text: `Estações com chuva: ${
							this.boletim?.estatisticasPrecipitacaoBoletim
								.numeroEstacoesComChuva || 0
						}`,
						margin: [0, 0, 0, 8],
						fontSize: 10,
					},
					{
						text: `Estações sem chuva: ${
							this.boletim?.estatisticasPrecipitacaoBoletim
								.numeroEstacoesSemChuva || 0
						}`,

						fontSize: 10,
						margin: [0, 0, 0, 8],
					},
				],
			},
			{
				columns: [
					{
						text: `PCDs ativas: ${
							this.boletim?.estatisticasPostosBoletim.numeroPCDs || 0
						}`,
						fontSize: 10,
						margin: [0, 0, 0, 8],
					},
					{
						text: `PCOs ativos: ${
							this.boletim?.estatisticasPostosBoletim
								.numeroPluviometrosManuais || 0
						}`,
						fontSize: 10,
						margin: [0, 0, 0, 8],
					},
					{
						text: `${this.acharMunicipiosPostosMaioresPrecipitacoes()}`,
						fontSize: 10,
						margin: [0, 0, 0, 8],
					},
				],
			},
			{
				text: `Chuva acumulada (mm) - ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 10, 0, 0],
				fontSize: 9,
			},
			{
				svg: svg,
				width: 500,
				height: 300,
				alignment: 'center',
			},
		]);

		let dadosTabela =
			this.dadosTabela.agrupadoPorMesorregiao[this.opcaoTodosTabela];

		if (tipoPosto) {
			const tipoAtualPosto =
				tipoPosto == 'PCO' ? 'PLUVIOMETRO_CONVENCIONAL' : tipoPosto;
			dadosTabela = dadosTabela?.filter(d => d.tipoEstacao == tipoAtualPosto);
		}

		const tableData = this.objetoVazio(dadosTabela)
			? false
			: this.getDadosTabelaParaExportacao(dadosTabela, true);

		documentDefinition.content.push(
			{
				text: 'PCD: Plataforma de Coleta de Dados; PCO: Pluviômetro Convencional.',
				fontSize: 9,
				margin: [30, 0, 70, 10],
			},
			{
				table: {
					alignment: 'center',
					layout: 'fullWidth',
					fontSize: 12,
					widths: [257, 75, 97],
					headerRows: 1,
					body: tableData
						? tableData
						: this.getTabelaVazia(
								'Nenhum dado encontrado no boletim gerado para os filtros aplicados.'
						  ),
				},
				margin: [30, 0],
			}
		);

		if (periodo == PeriodoBoletim.MENSAL && dataInicio && dataFim) {
			this.boletimService
				.listarBoletinsAgrupadoPorPeriodo(dataInicio, dataFim, periodo)
				.subscribe({
					next: dados => {
						documentDefinition.content.push(
							...this.gerarTabelaPorPeriodo(
								dados,
								limiteInferiorMes,
								periodo,
								dataInicio,
								ano,
								tipoPosto
							)
						);
						const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
						this.loadingBoletim = false;
						pdfDocGenerator.getBlob((blob: Blob) => {
							const objectURL = URL.createObjectURL(blob);
							window.open(objectURL);
							this.closeModal();
						});
					},
					error: error => {
						console.error('Erro ao obter dados do histórico:', error);
					},
				});
		} else if (periodo == PeriodoBoletim.ANUAL && ano) {
			this.boletimService
				.listarBoletinsAgrupadoPorPeriodo(null, ano, periodo)
				.subscribe({
					next: dados => {
						documentDefinition.content.push(
							...this.gerarTabelaPorPeriodo(
								dados,
								limiteInferiorAno,
								periodo,
								dataInicio,
								ano,
								tipoPosto
							)
						);
						const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
						this.loadingBoletim = false;
						pdfDocGenerator.getBlob((blob: Blob) => {
							const objectURL = URL.createObjectURL(blob);
							window.open(objectURL);
							this.closeModal();
						});
					},
					error: error => {
						console.error('Erro ao obter dados do histórico:', error);
					},
				});
		} else if (
			periodo == PeriodoBoletim.AMBOS &&
			dataInicio &&
			dataFim &&
			ano
		) {
			combineLatest([
				this.boletimService.listarBoletinsAgrupadoPorPeriodo(
					dataInicio,
					dataFim,
					PeriodoBoletim.MENSAL
				),
				this.boletimService.listarBoletinsAgrupadoPorPeriodo(
					null,
					ano,
					PeriodoBoletim.ANUAL
				),
			]).subscribe({
				next: ([dados1, dados2]) => {
					documentDefinition.content.push(
						...this.gerarTabelaPorPeriodo(
							dados1,
							limiteInferiorMes,
							PeriodoBoletim.MENSAL,
							dataInicio,
							ano,
							tipoPosto
						)
					);
					documentDefinition.content.push(
						...this.gerarTabelaPorPeriodo(
							dados2,
							limiteInferiorAno,
							PeriodoBoletim.ANUAL,
							dataInicio,
							ano,
							tipoPosto
						)
					);
					const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
					this.loadingBoletim = false;
					pdfDocGenerator.getBlob((blob: Blob) => {
						const objectURL = URL.createObjectURL(blob);
						window.open(objectURL);
						this.closeModal();
					});
				},
				error: error => {
					console.error('Erro ao obter dados do histórico:', error);
				},
			});
		} else {
			const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
			this.loadingBoletim = false;
			pdfDocGenerator.getBlob((blob: Blob) => {
				const objectURL = URL.createObjectURL(blob);
				window.open(objectURL);
				this.closeModal();
			});
		}
	}

	async exportToXLS() {
		const dadosTabela =
			this.dadosTabela.agrupadoPorMesorregiao[this.opcaoTodosTabela];
		const nomeArquivo = `Boletim-pluviométrico-${this.getDataFormatada(
			this.data
		)}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'Municipio_Posto',
				text: 'Município/Posto',
				size: 48,
			},
			{ label: 'Tipo_de_posto', text: 'Tipo de posto', size: 24 },
			{ label: 'Precipitacao', text: 'Precipitação (mm)', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: dadosTabela.map(
						({
							detalhesPosto: { municipio, posto },
							tipoEstacao,
							precipitacaoAcumulada,
						}) => {
							return {
								Municipio_Posto:
									municipio == posto ? municipio : `${municipio}/${posto}`,
								Tipo_de_posto:
									tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
										? 'PCO'
										: TipoEstacao[tipoEstacao],
								Precipitacao: Number(precipitacaoAcumulada.toFixed(1)),
							};
						}
					),
					columns: [
						{ key: 'Municipio_Posto' },
						{
							key: 'Tipo_de_posto',
							style: {
								alignment: { horizontal: 'center' },
							},
						},
						{
							key: 'Precipitacao',
							style: { numFmt: '0.0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo} \n PCD: Plataforma de Coleta de Dados; PCO: Pluviômetro Convencional.`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	closeModal() {
		this.modalService.hide('modal-pdf-options');
	}

	objetoVazio(obj: object): boolean {
		if (obj) {
			return Object?.keys(obj).length === 0;
		}
		return true;
	}

	filtrarPorPreferenciaTipoPosto(
		dados: Record<string, Record<string, number>>,
		tipoPosto: string | null,
		limiteInferior: number | null
	): Record<string, Record<string, number>> {
		return Object.fromEntries(
			Object.entries(dados)
				.map(([municipio, postos]) => {
					const preferencial = tipoPosto
						? tipoPosto === 'PCO'
							? 'PLUVIOMETRO_CONVENCIONAL'
							: tipoPosto
						: 'PLUVIOMETRO_CONVENCIONAL';

					const valorPreferencial = postos[preferencial];
					const valorSecundario = tipoPosto ? null : postos['PCD'];

					if (valorPreferencial && valorPreferencial >= (limiteInferior || 0)) {
						const tipoEscolhido = valorPreferencial ? preferencial : 'PCD';
						return [
							municipio,
							{ [tipoEscolhido]: valorPreferencial || valorSecundario },
						];
					}
					return null;
				})
				.filter(
					(valor): valor is [string, Record<string, number>] => valor !== null
				)
		);
	}

	gerarTabelaPorPeriodo(
		dados: RespostaBoletimPeriodo,
		limiteInferior: number | null,
		periodo: PeriodoBoletim | null,
		dataInicio: Date | null,
		ano: Date | null,
		tipoPosto: TipoEstacao | null
	) {
		let dadosTabela: any = dados;

		dadosTabela = this.filtrarPorPreferenciaTipoPosto(
			dadosTabela,
			tipoPosto,
			limiteInferior
		);

		const historicoTableData =
			!this.objetoVazio(dadosTabela) &&
			this.getDadosTabelaMensalAnual(dadosTabela);

		let periodoText =
			periodo == PeriodoBoletim.MENSAL
				? 'Chuvas acumuladas no mês (mm)'
				: 'Chuvas acumuladas no ano (mm)';

		if (periodo == PeriodoBoletim.MENSAL && dataInicio) {
			const dataIn = DateTimeUtils.formatarData(
				new Date(dataInicio).toDateString(),
				'dd/MM'
			);
			const dataFim = DateTimeUtils.formatarData(
				new Date().toDateString(),
				'dd/MM'
			);
			periodoText += ` - ${dataIn} - ${dataFim}`;
		} else if (periodo == PeriodoBoletim.ANUAL && ano) {
			const dataInicio = DateTimeUtils.formatarData(
				new Date(ano).toDateString(),
				'dd/MM/yyyy'
			);
			const dataFim = DateTimeUtils.formatarData(
				new Date().toDateString(),
				'dd/MM/yyyy'
			);

			periodoText += ` - ${dataInicio} - ${dataFim}`;
		}

		return [
			{
				text: periodoText,
				alignment: 'center',
				fontSize: 12,
				margin: [0, 10, 60, 0],
			},
			{
				text: limiteInferior
					? `(Valores acumulados acima de ${Number(limiteInferior!)
							.toFixed(1)
							.replace('.', ',')}mm)`
					: '',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 0, 60, 5],
			},
			{
				table: {
					alignment: 'center',
					layout: 'fullWidth',
					fontSize: 12,
					widths: [257, 75, 97],
					headerRows: 1,
					body: !historicoTableData
						? this.getTabelaVazia(
								`Nenhuma chuva acumulada no ${
									periodo == PeriodoBoletim.ANUAL ? 'ano' : 'mês'
								} encontrada para os filtros aplicados.`
						  )
						: historicoTableData,
				},
				margin: [30, 0],
			},
		];
	}

	exportToCSV() {
		let csvData = `Boletim pluviométrico - ${this.getDataFormatada(
			this.data
		)} \nPCD: Plataforma de Coleta de Dados - PCO: Pluviômetro Convencional \n\n Município/Posto; Tipo de posto; Precipitação (mm).\n`;

		const dadosTabela =
			this.dadosTabela.agrupadoPorMesorregiao[this.opcaoTodosTabela];

		dadosTabela.forEach(
			({
				detalhesPosto: { municipio, posto },
				tipoEstacao,
				precipitacaoAcumulada,
			}) => {
				csvData +=
					municipio == posto
						? `${posto}; ${
								tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
									? 'PCO'
									: TipoEstacao[tipoEstacao]
						  }; ${precipitacaoAcumulada.toFixed(1).replace('.', ',')}\n`
						: `${municipio}/${posto}; ${
								tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
									? 'PCO'
									: TipoEstacao[tipoEstacao]
						  }; ${precipitacaoAcumulada.toFixed(1).replace('.', ',')}\n`;
			}
		);

		DocumentExporter.gerarCSV(
			csvData,
			`relatorio-boletim-diario-${this.getDataFormatada(this.data)}`
		);
	}

	getDadosTabelaMensalAnual(
		dados: RespostaBoletimPeriodo
	): Table['body'] | false {
		if (!dados || Object.keys(dados).length === 0) {
			return false;
		}

		const body = Object.keys(dados)
			.map(municipio => {
				const posto: any = Object.keys(dados[municipio])[0];
				const value = dados[municipio][posto];
				const fillColor = '';
				const formattedValue = numberToBrNumber(value, 1);

				return [
					{ text: municipio, fillColor, alignment: '' },
					{
						text: posto === 'PLUVIOMETRO_CONVENCIONAL' ? 'PCO' : posto,
						fillColor,
						alignment: 'center',
					},
					{ text: formattedValue, alignment: 'right' },
				];
			})
			.sort((a, b) => {
				// Ordenar os municípios em ordem alfabética
				const municipioA = (a[0] as { text: string }).text;
				const municipioB = (b[0] as { text: string }).text;
				return municipioA.localeCompare(municipioB);
			});

		const cabecalho = [
			{ text: 'Município', fillColor: '#DCDCDC', alignment: '' },
			{ text: 'Tipo de posto', fillColor: '#DCDCDC', alignment: 'center' },
			{ text: 'Total (mm)', fillColor: '#DCDCDC', alignment: 'center' },
		];

		return [cabecalho, ...body];
	}

	getDadosTabelaParaExportacao(dados: PrecipitacaoPosto[], isPdf = false) {
		const dadosOrdenados = dados.sort((a, b) => {
			const aKey = a.detalhesPosto.municipio + '/' + a.detalhesPosto.posto;
			const bKey = b.detalhesPosto.municipio + '/' + b.detalhesPosto.posto;
			return aKey.localeCompare(bKey);
		});

		return [
			isPdf
				? this.colunasTabelaBoletim.map(({ title }) => ({
						text: title,
						fillColor: '#DCDCDC',
						alignment:
							title === 'Tipo de posto' || title === 'Precipitação (mm)'
								? 'center'
								: 'left',
				  }))
				: this.colunasTabelaBoletim.map(({ title }) => title),
			...dadosOrdenados.map(
				({
					detalhesPosto: { municipio, posto },
					tipoEstacao,
					precipitacaoAcumulada,
				}) => [
					municipio === posto ? municipio : municipio + '/' + posto,
					{
						text:
							tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
								? 'PCO'
								: TipoEstacao[tipoEstacao],
						alignment: 'center',
					},
					{
						text: precipitacaoAcumulada.toFixed(1).replace('.', ','),
						alignment: 'right',
					},
				]
			),
		] as any[][];
	}

	formatarPrecipitacaoAcumulada = (valor: number): string => {
		if (valor % 1 === 0) {
			return `${valor},0`;
		} else {
			return valor.toString();
		}
	};

	exportToTXT() {
		let txtData = `Boletim pluviométrico - ${this.getDataFormatada(
			this.data
		)} \nPCD: Plataforma de Coleta de Dados; PCO: Pluviômetro Convencional. \n\n`;
		const dadosTabela =
			this.dadosTabela.agrupadoPorMesorregiao[this.opcaoTodosTabela];
		dadosTabela.forEach(
			({
				detalhesPosto: { municipio, posto },
				tipoEstacao,

				precipitacaoAcumulada,
			}) => {
				txtData +=
					`Município/Posto: ${municipio}/${posto}\n` +
					`Tipo de posto: ${
						tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
							? 'PCO'
							: TipoEstacao[tipoEstacao]
					}\n` +
					`Precipitação (mm): ${numberToBrNumber(
						precipitacaoAcumulada,
						1
					)}\n\n`;

				return txtData;
			}
		);

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-boletim-diario-${this.getDataFormatada(this.data)}`
		);
	}

	acharMunicipiosPostosMaioresPrecipitacoes(): string {
		const dadosTabela =
			this.dadosTabela.agrupadoPorMesorregiao[this.opcaoTodosTabela];

		if (!dadosTabela || dadosTabela.length === 0) {
			return 'Maiores chuvas: Nenhum dado disponível';
		}

		const dadosValidos = dadosTabela.filter(
			(dado: any) =>
				typeof dado.precipitacaoAcumulada === 'number' &&
				dado.precipitacaoAcumulada > 0
		);

		if (dadosValidos.length === 0) {
			return 'Maiores chuvas: Nenhum dado de precipitação válido';
		}

		const dadosOrdenados = dadosValidos.sort((a: any, b: any) => {
			if (b.precipitacaoAcumulada === a.precipitacaoAcumulada) {
				return a.detalhesPosto.posto.localeCompare(b.detalhesPosto.posto);
			}
			return b.precipitacaoAcumulada - a.precipitacaoAcumulada;
		});

		const top3 = dadosOrdenados.slice(0, 3);

		const resultado = top3
			.map(
				(dado: any) =>
					`${dado.detalhesPosto.posto} - ${dado.precipitacaoAcumulada
						.toFixed(1)
						.replace('.', ',')} mm`
			)
			.join('; \n');

		return `Maiores chuvas: \n ${resultado}`;
	}

	abrirModal() {
		const closeModal = (modal = false) => {
			this.modalFechadoAposConfirmar = modal;
			this.modalService.hide('modal-pdf-options');
		};

		const afterSubmitFunc = (
			dataInicio: Date | null,
			ano: Date | null,
			limiteInferiorMes: number | null,
			limiteInferiorAno: number | null,
			periodo: PeriodoBoletim | null,
			tipoPosto: TipoEstacao | null
		) => {
			this.exportToPDF(
				dataInicio,
				ano,
				limiteInferiorMes,
				limiteInferiorAno,
				periodo,
				tipoPosto
			);
		};

		this.modalService.show(ModalPdfOptionsComponent, {
			id: 'modal-pdf-options',
			backdrop: 'static',
			class: 'modal-dialog-centered',
			initialState: {
				isModal: true,
				afterSubmitOnModalFunc: afterSubmitFunc,
				close: (modal: boolean) => closeModal(modal),
				loadingSubmit: this.loadingBoletim,
				dataBoletim: this.data,
			},
		});
	}

	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
}
