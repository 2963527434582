import { Component, inject, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	DadoEstatisticaMensal,
	DadoPrecipitacaoMensal,
} from '@home/submodulos/dados-meteorologicos/componentes/tabela-relatorio-precipitacao-mensal/tabela-relatorio-precipitacao-mensal.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	DadoPrecipitacao,
	EstatisticasPrecipitacao,
	PostosRelatorios,
	RelatorioAnual,
	Relatorios,
	TipoEstatistica,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import {
	isNotNuloOuUndefined,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { corrigeDuplicacaoNome } from '../../../utils';
import * as ExcelTable from 'mr-excel';
import { isNotNullOrUndefined } from '@angular-eslint/utils/dist/utils';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-mensal',
	templateUrl: './pluviometro-tabela-precipitacao-mensal.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-mensal.component.scss'],
})
export class PluviometroTabelaPrecipitacaoMensalComponent
	implements InstanciaRelatorio, OnDestroy
{
	descricaoRelatorio =
		'Define-se como pluviometria observada mensal, a chuva acumulada em cada mês no período de um ou mais anos.';

	postos!: PostosRelatorios[];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];

	precipitacaoMensal: Relatorios<DadoPrecipitacaoMensal>[] = [];
	estatisticas: Relatorios<DadoEstatisticaMensal>[] = [];

	inputs = inject(INPUTS_RELATORIOS);
	carregando = true;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	periodoTitulo = '';
	tituloRelatorio = '';

	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {
		this.postos = this.inputs.postos;
		this.setValidators();
	}

	ngOnDestroy(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	private getPrecipitacaoAcumulada(
		dadoPrecipitacao: DadoPrecipitacao,
		index: number
	) {
		return dadoPrecipitacao.precipitacoesMensais?.[index]
			?.precipitacaoAcumulada;
	}

	private processarDadoEstatistica(
		estatisticas: EstatisticasPrecipitacao,
		tipoEstatistica: TipoEstatistica
	) {
		const labelEstatisticaMap: Record<string, string> = {
			anosContabilizados: 'Quantidade de anos',
			mediasMensais: 'Média (mm)',
			maximaMensal: 'Máxima (mm)',
			minimaMensal: 'Mínima (mm)',
		};

		return {
			estatistica: labelEstatisticaMap[tipoEstatistica],
			jan: estatisticas[tipoEstatistica][0],
			fev: estatisticas[tipoEstatistica][1],
			mar: estatisticas[tipoEstatistica][2],
			abr: estatisticas[tipoEstatistica][3],
			mai: estatisticas[tipoEstatistica][4],
			jun: estatisticas[tipoEstatistica][5],
			jul: estatisticas[tipoEstatistica][6],
			ago: estatisticas[tipoEstatistica][7],
			set: estatisticas[tipoEstatistica][8],
			out: estatisticas[tipoEstatistica][9],
			nov: estatisticas[tipoEstatistica][10],
			dez: estatisticas[tipoEstatistica][11],
		};
	}

	private processarDadoPrecipitacao(dadoPrecipitacao: DadoPrecipitacao) {
		return {
			ano: new Date(dadoPrecipitacao.ano).getFullYear(),
			jan: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 0),
			fev: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 1),
			mar: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 2),
			abr: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 3),
			mai: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 4),
			jun: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 5),
			jul: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 6),
			ago: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 7),
			set: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 8),
			out: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 9),
			nov: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 10),
			dez: this.getPrecipitacaoAcumulada(dadoPrecipitacao, 11),
			total: dadoPrecipitacao.precipitacaoAcumuladaAno,
		};
	}

	private processarDadosEstatistica(
		estatisticasPrecipitacao: EstatisticasPrecipitacao
	) {
		return Object.keys(estatisticasPrecipitacao).map(tipoEstatistica =>
			this.processarDadoEstatistica(
				estatisticasPrecipitacao,
				tipoEstatistica as TipoEstatistica
			)
		);
	}

	private popularDadosTabela(relatorio: RelatorioAnual) {
		const municipio = relatorio.detalhesPosto.municipio;
		const nomePosto = relatorio.detalhesPosto.posto;
		const labelMunicipio = corrigeDuplicacaoNome(`${municipio}/${nomePosto}`);

		relatorio.dadosPrecipitacao.forEach(dadoPrecipitacao => {
			const dadoPrecipitacaoMensal = this.precipitacaoMensal.find(
				dado => dado.municipio === labelMunicipio
			);
			const dadoEstatistica = this.estatisticas.find(
				dado => dado.municipio === labelMunicipio
			);
			const dadoPrecipitacaoMensalProcessado =
				this.processarDadoPrecipitacao(dadoPrecipitacao);
			const dadoEstatisticasProcessado = this.processarDadosEstatistica(
				relatorio.estatisticasPrecipitacao
			);

			if (dadoPrecipitacaoMensal && dadoEstatistica) {
				dadoPrecipitacaoMensal.data.push(dadoPrecipitacaoMensalProcessado);
				dadoEstatistica.data = dadoEstatisticasProcessado;
			} else {
				this.precipitacaoMensal.push({
					municipio: labelMunicipio,
					data: [dadoPrecipitacaoMensalProcessado],
				});
				this.estatisticas.push({
					municipio: labelMunicipio,
					data: [...dadoEstatisticasProcessado],
				});
			}
		});
	}

	gerarRelatorio() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			this.inputs.form.get('dataInicio')?.value,
			this.inputs.form.get('dataFim')?.value
		);
		this.carregando = true;
		this.inputs.setLoading(true);

		const postosIds = this.postos.map(posto => posto.id);

		this.tituloRelatorio = `Pluviometria observada mensal (mm) - ${DateTimeUtils.formatarDataPeriodo(
			this.getFormItemValue('dataInicio'),
			this.getFormItemValue('dataFim')
		)}`;

		this.relatoriosService
			.buscarRelatorioPrecipitacaoAnual(postosIds, dataInicio, dataFim)
			.subscribe({
				next: response => {
					this.precipitacaoMensal = [];
					this.estatisticas = [];
					response.relatoriosAnuais.forEach(relatorio =>
						this.popularDadosTabela(relatorio)
					);
					this.relatoriosService.verificaExistenciaDados(
						this.precipitacaoMensal
					);
				},
				error: error => {
					this.carregando = false;
					const mensagem = obter_erro_request(error);
					this.toastr.error(mensagem, 'Ocorreu um erro ao gerar o relatório');
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	retornarPrecipitacaoMensalParaXLSX() {
		const tableData: any[] = [];

		this.precipitacaoMensal.forEach(dadoRelatorio => {
			const [municipio, posto] = dadoRelatorio.municipio.split('/');

			dadoRelatorio.data.forEach(dado => {
				tableData.push({
					municipio: municipio,
					posto: posto || municipio,
					ano: dado.ano,
					jan: isNotNullOrUndefined(dado.jan)
						? numberToBrNumber(dado.jan, 1)
						: '-',
					fev: isNotNullOrUndefined(dado.fev)
						? numberToBrNumber(dado.fev, 1)
						: '-',
					mar: isNotNullOrUndefined(dado.mar)
						? numberToBrNumber(dado.mar, 1)
						: '-',
					abr: isNotNullOrUndefined(dado.abr)
						? numberToBrNumber(dado.abr, 1)
						: '-',
					mai: isNotNullOrUndefined(dado.mai)
						? numberToBrNumber(dado.mai, 1)
						: '-',
					jun: isNotNullOrUndefined(dado.jun)
						? numberToBrNumber(dado.jun, 1)
						: '-',
					jul: isNotNullOrUndefined(dado.jul)
						? numberToBrNumber(dado.jul, 1)
						: '-',
					ago: isNotNullOrUndefined(dado.ago)
						? numberToBrNumber(dado.ago, 1)
						: '-',
					set: isNotNullOrUndefined(dado.set)
						? numberToBrNumber(dado.set, 1)
						: '-',
					out: isNotNullOrUndefined(dado.out)
						? numberToBrNumber(dado.out, 1)
						: '-',
					nov: isNotNullOrUndefined(dado.nov)
						? numberToBrNumber(dado.nov, 1)
						: '-',
					dez: isNotNullOrUndefined(dado.dez)
						? numberToBrNumber(dado.dez, 1)
						: '-',
					total: isNotNullOrUndefined(dado.total)
						? numberToBrNumber(dado.total, 1)
						: '-',
				});
			});
		});

		return tableData;
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: this.tituloRelatorio,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.precipitacaoMensal.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		const colunasMeses = [
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		];

		this.precipitacaoMensal.forEach((dadoRelatorio, index) => {
			const precipitacoesAnuais = dadoRelatorio.data;
			const estatisticas = this.estatisticas[index].data;

			const dadosTabelaPrecipitacaoMensal: (string | number)[][] = [];

			dadosTabelaPrecipitacaoMensal.push(['Ano', ...colunasMeses, 'Total']);

			precipitacoesAnuais.forEach(dado => {
				const { ano, ...rest } = dado;
				dadosTabelaPrecipitacaoMensal.push([
					ano,
					...Object.values(rest).map(d =>
						isNotNuloOuUndefined(d) ? String(numberToBrNumber(d, 1)) : '-'
					),
				]);
			});

			const dadosTabelaEstatistica: (string | number)[][] = [];

			dadosTabelaEstatistica.push(['Estatísticas', ...colunasMeses]);

			estatisticas.forEach(dado => {
				const { estatistica, ...rest } = dado;
				dadosTabelaEstatistica.push([
					estatistica,
					...Object.values(rest).map(d => {
						if (estatistica === 'Quantidade de anos') {
							return d || 0;
						}
						return isNotNuloOuUndefined(d)
							? String(numberToBrNumber(d, 1))
							: '-';
					}),
				]);
			});

			documentDefinition.content.push(
				{
					text: dadoRelatorio.municipio,
					fontSize: 12,
					margin: [0, 10],
				},
				{
					style: 'table',
					table: {
						headerRows: 1,
						widths: [75, ...Array(13).fill(24)],
						body: dadosTabelaPrecipitacaoMensal,
					},
					layout: {
						fillColor: (rowIndex: number, node: any, columnIndex: number) => {
							return rowIndex === 0 ? '#DCDCDC' : null; // Cor de fundo para o cabeçalho
						},
					},
				},
				{
					style: 'table',
					table: {
						headerRows: 1,
						widths: [75, ...Array(12).fill(24)],
						body: dadosTabelaEstatistica,
					},
					layout: {
						fillColor: (rowIndex: number, node: any, columnIndex: number) => {
							return rowIndex === 0 ? '#DCDCDC' : null; // Cor de fundo para o cabeçalho
						},
					},
				}
			);
		});

		documentDefinition.styles = {
			table: {
				fontSize: 8,
				margin: [0, 0, 0, 10],
			},
		};

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	async exportarCSV() {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município/Posto',
			'Ano',
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
			'Total',
		]);

		this.precipitacaoMensal.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio,
					...Object.entries(dado).map(([chave, valor]) => {
						if (chave === 'ano') {
							return valor;
						}
						return isNotNuloOuUndefined(valor)
							? numberToBrNumber(valor, 1)
							: '-';
					}),
				]);
			});
		});

		DocumentExporter.gerarCSV(tableData, this.tituloRelatorio);
	}

	async exportarTXT() {
		let txtData = '';

		this.precipitacaoMensal.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData += `Município/Posto: ${dadoRelatorio.municipio}\n`;

				Object.entries(dado).forEach(([chave, valor]) => {
					// Capitaliza a primeira letra da chave
					const chaveFormatada = chave.charAt(0).toUpperCase() + chave.slice(1);

					if (chave === 'ano') {
						txtData += `${chaveFormatada}: ${valor ? valor : '-'}\n`;
					} else {
						txtData += `${chaveFormatada}: ${
							isNotNullOrUndefined(valor) ? numberToBrNumber(valor, 1) : '-'
						}\n`;
					}
				});

				txtData += '\n\n';
			});
		});

		DocumentExporter.gerarTXT(txtData, this.tituloRelatorio);
	}

	exportarXLSX() {
		const nomeArquivo = this.tituloRelatorio;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const headers = [
			{ label: 'municipio', text: 'Município', size: 24 },
			{ label: 'posto', text: 'Posto', size: 24 },
			{ label: 'ano', text: 'Ano', size: 24 },
			{ label: 'jan', text: 'Jan', size: 24 },
			{ label: 'fev', text: 'Fev', size: 24 },
			{ label: 'mar', text: 'Mar', size: 24 },
			{ label: 'abr', text: 'Abr', size: 24 },
			{ label: 'mai', text: 'Mai', size: 24 },
			{ label: 'jun', text: 'Jun', size: 24 },
			{ label: 'jul', text: 'Jul', size: 24 },
			{ label: 'ago', text: 'Ago', size: 24 },
			{ label: 'set', text: 'Set', size: 24 },
			{ label: 'out', text: 'Out', size: 24 },
			{ label: 'nov', text: 'Nov', size: 24 },
			{ label: 'dez', text: 'Dez', size: 24 },
			{ label: 'total', text: 'Total', size: 24 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPrecipitacaoMensalParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	getFormItemValue(controlName: string): any {
		return this.inputs.form.get(controlName)!.value;
	}
}
