import { TabMenuItem } from '@shared/interfaces/menu';

export const menuItens: TabMenuItem[] = [
	{
		path: 'dados-meteorologicos',
		label: 'Dados meteorológicos',
		submenu: [
			{
				path: 'dados-meteorologicos/boletim-diario',
				label: 'Boletim diário',
			},
			{
				path: 'dados-meteorologicos/monitoramento',
				label: 'Monitoramento',
				submenu: [
					{
						label: 'Rede de monitoramento',
						path: 'dados-meteorologicos/monitoramento/rede-monitoramento',
					},
					{
						label: 'Pluviometria',
						path: 'dados-meteorologicos/monitoramento/pluviometria',
					},

					{
						path: 'dados-meteorologicos/monitoramento/quantis',
						label: 'Quantis',
					},
					{
						label: 'Estações automáticas (PCDs)',
						path: 'dados-meteorologicos/monitoramento/estacoes-automaticas',
					},
				],
			},
		],
	},
	{
		path: 'dados-hidrologicos',
		label: 'Dados hidrológicos',
		submenu: [
			{
				path: 'dados-hidrologicos/reservatorio',
				label: 'Volume dos reservatórios',
			},
		],
	},
	{
		path: 'previsao-numerica-tempo',
		label: 'Previsão numérica do tempo',
		submenu: [
			{
				path: 'previsao-numerica-tempo/previsao-para-o-estado',
				label: 'Previsão para o estado',
			},
			{
				path: 'previsao-numerica-tempo/mapas-de-previsao',
				label: 'Mapas de previsão',
			},
			{
				path: 'previsao-numerica-tempo/meteograma',
				label: 'Meteograma',
			},
			{
				path: 'previsao-numerica-tempo/avisos-de-valores-extremos',
				label: 'Avisos de valores extremos',
			},
			{
				path: 'previsao-numerica-tempo/previsao-veranicos',
				label: 'Previsão de veranicos',
			},
			{
				path: 'previsao-numerica-tempo/selos-previsao',
				label: 'Selos de previsão',
			},
		],
	},
	{
		path: 'previsao-climatica',
		label: 'Previsão climática',
		submenu: [
			{
				path: 'previsao-climatica/modelo-previsao-climatica',
				label: 'Modelo de previsão climática',
			},
		],
	},
	{
		path: 'zoneamentos',
		label: 'Zoneamentos',
		submenu: [
			{
				path: 'zoneamentos/zoneamento-edafoclimatico',
				label: 'Edafoclimático',
			},
			{
				path: 'zoneamentos/risco-climatico',
				label: 'Risco climático',
			},
		],
	},
	// { path: 'imagens-satelite', label: 'Imagens de satélite' },
];
