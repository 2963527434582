<div>
	<label class="text-center text-tertiary fw-semibold w-100">
		Pluviometria observada (mm) - {{ periodoTitulo }}
	</label>

	<seira-card-info
		aditionalClasses="my-3"
		[texto]="descricaoRelatorio"></seira-card-info>

	<div id="mapa-legenda">
		<div class="d-flex">
			<div class="col-12 vh-100 pe-lg-0 pe-1" style="max-height: 500px">
				<seira-mapa-paraiba-leaflet
					(mapaEmitter)="setupMap($event)"
					[loading]="carregando"
					[monitoramentoMapa]="map" />
			</div>
		</div>
		<seira-legenda-mapa
			[legendaInfo]="legenda"
			titulo="Pluviometria observada (mm)">
		</seira-legenda-mapa>
	</div>
</div>
