<form [formGroup]="formBoletim" class="form-group" id="form">
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Boletim diário
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informacoesGerais"></seira-public-page-about>
				</div>
			</div>
		</seira-page-title>
	</div>

	<div class="row" id="main-row">
		<div
			class="col-lg-4 col-xl-4 d-flex flex-column justify-content-start title">
			<div class="align-items-center d-flex">
				<seira-title class="custom-title" style="font-size: 1.8rem !important"
					>Boletim de {{ this.data | date: 'dd/MM/yyyy' }}</seira-title
				>
			</div>

			<div class="d-flex align-items-center gap-2">
				<seira-public-date-picker
					controlName="periodo"
					placeholder="Selecionar data..."></seira-public-date-picker>
				<seira-public-button
					buttonStyle="fill"
					[disabledButton]="formBoletim.invalid"
					(clicked)="boletinsPorDataInformada()"
					>Buscar</seira-public-button
				>
			</div>
		</div>

		<div
			class="col-lg-8 col-xl-8 d-flex flex-column flex-md-row align-items-md-end mt-sm-2 mt-2"
			id="dashboard">
			<seira-dashboard-values
				*ngIf="!carregando"
				[items]="dashboardItems.numeroEstacoes">
				<div label>NÚMERO DE ESTAÇÕES</div>
			</seira-dashboard-values>
			<div class="d-flex">
				<seira-dashboard-values
					class="ms-md-2 mt-md-0 mt-2"
					*ngIf="!carregando"
					[items]="dashboardItems.pcd">
					<div label>
						PCDs
						<i
							class="ph ph-question-fill text-primary px-2"
							[popover]="infomacoesModoDeVisualizacaoMapa"
							triggers="mouseenter:mouseleave"
							placement="right"
							containerClass="custom-popover"
							container="body"></i>
					</div>
				</seira-dashboard-values>
				<seira-dashboard-values
					class="ms-2 mt-md-0 mt-2"
					*ngIf="!carregando"
					[items]="dashboardItems.pluviometros">
					<div label>
						PCOs
						<i
							class="ph ph-question-fill text-primary px-2"
							[popover]="infomacoesConvencionais"
							triggers="mouseenter:mouseleave"
							placement="right"
							containerClass="custom-popover"
							container="body"></i>
					</div>
				</seira-dashboard-values>
				<ng-container *seiraIfViewportSize="'mobile'">
					<ng-container *ngTemplateOutlet="botaoDeExportacao"></ng-container>
				</ng-container>

				<ng-container *seiraIfViewportSize="'lg'">
					<ng-container>
						<ng-container *ngTemplateOutlet="botaoDeExportacao"></ng-container>
					</ng-container>
				</ng-container>

				<ng-container *seiraIfViewportSize="'xl'">
					<ng-container *ngTemplateOutlet="botoesDeExportacao"></ng-container>
				</ng-container>

				<ng-template #botaoDeExportacao>
					<seira-export-button-boletim
						class="botao ms-2 mt-md-0 mt-2 align-self-end"
						*ngIf="!carregando"
						[disabledButton]="!boletim"
						[buttons]="
							dashboardItems.botoesDeExportacao
						"></seira-export-button-boletim>
				</ng-template>

				<ng-template #botoesDeExportacao>
					<seira-public-button-group
						class="ms-md-2 mt-md-0 mt-2 align-self-end"
						id="buttons"
						[disabledButtons]="!boletim"
						*ngIf="!carregando"
						[buttons]="
							dashboardItems.botoesDeExportacao
						"></seira-public-button-group>
				</ng-template>
			</div>
		</div>
	</div>
	<div class="row mt-3">
		<div class="col-lg-2 col-md-3">
			<seira-public-radio-group
				idRadio="modoVisualizacaoBoletim"
				[options]="OpcoesTipoVisualizacao"
				label="Modo de visualização"
				controlName="tipoVisualizacao"></seira-public-radio-group>
		</div>
		<div
			class="col-lg-4 col-md-3"
			*ngIf="getFormItemValue('tipoVisualizacao') !== 'mapa'">
			<seira-public-radio-group
				idRadio="agrupamentoTabela"
				[options]="OpcoesTipoAgrupamento"
				label="Tabela agrupada por"
				controlName="agrupamentoTabela"></seira-public-radio-group>
		</div>
		<div
			class="d-flex flex-column h-25 mx-auto"
			style="width: 95%; padding-right: 0; padding-left: 0">
			<ng-container *seiraIfViewportSize="'mobile'">
				<seira-custom-radio-group
					*ngIf="getFormItemValue('tipoVisualizacao') === 'tabela'"
					[options]="opcoesAgrupamentos[agrupamento]"
					controlName="opcoesTabela"></seira-custom-radio-group>
			</ng-container>
		</div>
	</div>
	<div>
		<div [class.hidden]="getFormItemValue('tipoVisualizacao') !== 'mapa'">
			<div id="composicao-imagem" class="mt-4 d-flex">
				<div class="row">
					<div class="col-1"></div>
				</div>
				<div id="imagem" class="d-flex col-12 justify-content-center">
					<div id="map-container" style="position: relative; width: 70%">
						<ng-container>
							<label class="text-center text-tertiary fw-semibold w-100">
								Chuva acumulada (mm) - {{ this.data | date: 'dd/MM/yyyy' }}
							</label>
							<seira-mapa-paraiba-svg
								tipoMapa="precipitacao"
								id="boletim_diario"
								[popoverTemplate]="popupPrecipitacao"
								[atributosMunicipio]="chuvaPorMunicipio">
								<seira-loading
									[show]="loadingBoletim"
									customStyle="
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								"></seira-loading>
							</seira-mapa-paraiba-svg>
							<ng-container *ngTemplateOutlet="nenhumBoletim"> </ng-container>
						</ng-container>
					</div>
				</div>
				<ng-template
					#popupPrecipitacao
					let-color="color"
					let-nome="nome"
					let-valor="valor"
					let-data="data">
					<seira-popup-boletim-diario-mapa
						[postos]="data"
						[precipitacaoAcumulada]="valor | formatDecimal: 1"
						[headerColor]="color"
						[nome]="nome">
					</seira-popup-boletim-diario-mapa>
				</ng-template>
			</div>
			<div class="pb-3">
				<div class="d-flex align-items-center flex-column" *ngIf="!carregando">
					<h6 class="text-tertiary fw-normal">
						Top 10 | Precipitação dos Municípios/Postos no dia
						{{ this.data | date: 'dd/MM/yyyy' }}
					</h6>
				</div>
				<seira-public-table
					id="tabelaTop10Estacoes"
					[searching]="false"
					[info]="false"
					[paging]="false"
					[hasFooter]="false"
					[hasHeader]="false"
					[data]="top10Estacoes"
					[order]="[[2, 'desc']]"
					[columns]="colunasTabelaTop10Estacoes"></seira-public-table>
			</div>
		</div>
		<div
			*ngIf="getFormItemValue('tipoVisualizacao') === 'tabela'"
			[style.position]="'relative'">
			<div
				class="d-flex justify-content-center"
				*seiraIfViewportSize="'desktop'">
				<seira-custom-radio-group
					[options]="opcoesAgrupamentos[agrupamento]"
					controlName="opcoesTabela"></seira-custom-radio-group>
			</div>
			<div class="row mt-4 mb-3" id="tabelaPostos" style="position: relative">
				<seira-public-table
					[searching]="false"
					[hasHeader]="false"
					[info]="true"
					[data]="tabelaFiltrada"
					[order]="[[2, 'desc']]"
					[columns]="colunasTabelaBoletim"></seira-public-table>
				<seira-loading
					[show]="loadingBoletim"
					customStyle="
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					"></seira-loading>
			</div>
		</div>
	</div>
	<div class="row" *seiraIfViewportSize="['xl', 'lg', 'md']">
		<div class="col-lg-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informacoesGerais"></seira-info-gerais>
		</div>

		<div
			class="col-lg-6 d-flex flex-column mt-4 font-size-16 text-tertiary"
			*ngIf="boletim">
			<div class="align-items-top fw-semibold mb-2">
				Todos os dados de boletim são acumulados a cada 24h e coletados às
				07h00.
			</div>
			<div class="align-items-top fw-semibold me-1">Período dos dados:</div>
			<div>
				{{ boletim.inicioColeta | date: 'HH' }}h de
				{{ boletim.inicioColeta | date: 'dd/MM/yyyy' }} às
				{{ boletim.fimColeta | date: 'HH' }}h de
				{{ boletim.fimColeta | date: 'dd/MM/yyyy' }}
			</div>
		</div>
	</div>
</form>
<ng-template #infomacoesConvencionais>
	<div class="row p-2">
		<span class="col fw-semibold"
			>PCO consiste em Pluviômetro tipo
			<i class="fst-italic">Ville de Paris</i> com medição manual.</span
		>
	</div>
</ng-template>
<ng-template #infomacoesModoDeVisualizacaoMapa>
	<div class="row p-2">
		<span class="col fw-semibold">
			PCD é um equipamento composto por sensores que medem automaticamente as
			variáveis meteorológicas.
		</span>
	</div>
</ng-template>
<ng-template #nenhumBoletim>
	<div
		class="p-3 my-2 bg-white flex-row align-items-center rounded shadow-lg card text-tertiary"
		*ngIf="!boletim"
		style="
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translate(-50%, -50%);
		">
		<div class="flex-row d-flex justify-content-start notfound">
			Nenhum boletim encontrado
		</div>
	</div>
</ng-template>
