import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import * as Leaflet from 'leaflet';
import L, { latLng, latLngBounds, LayerGroup, MapOptions } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';

import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import {
	MAPAS_ESTACOES_AUTOMATICAS,
	mapEstacoeslayers,
} from '@modulos/home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/componentes/mapa-interpolacao/utils';

@Component({
	selector: 'seira-mapa-paraiba-leaflet',
	templateUrl: './mapa-paraiba-leaflet.component.html',
	styleUrls: ['./mapa-paraiba-leaflet.component.scss'],
})
export class MapaParaibaLeafletComponent implements OnDestroy {
	@Input() monitoramentoMapa!: Leaflet.Map;
	@Input() markersLayer?: LayerGroup;
	@Input() loading = false;
	@Input() custonsLayers = false;
	@Input() titulo = false;
	@Output() mapaEmitter = new EventEmitter<Leaflet.Map>();
	@ViewChild('mapaParaiba') mapaParaiba: ElementRef;
	ctrlAtivo = false;
	mapaId = `mapa-${uuidv4()}`;
	mapa!: Leaflet.Map;
	subscriptions = new Subscription();
	coordenadas: [number, number] = [-7.2605416, -36.7290255];
	options: MapOptions = {
		zoom: 8,
		preferCanvas: true,
		minZoom: 6,
		maxZoom: 15,
		center: latLng(-7.2605416, -36.7290255),
		layers: [mapEstacoeslayers[MAPAS_ESTACOES_AUTOMATICAS.INTERPOLACAO]],
		maxBounds: latLngBounds(latLng(-4.5, -39.2), latLng(-9.5, -34.2)),
	};
	constructor(private httpClient: HttpClient) {
		this.markersLayer = Leaflet.layerGroup();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	loadMap(map: Leaflet.Map) {
		this.mapa = map;
		this.mapa.createPane('labels');
		this.loadMunicipiosMap(map);
		this.loadMarkers(map);
		this.addResetViewControl(map);
		this.mapaEmitter.emit(map);
		this.addMunicipiosLabelsInMap();
	}

	addResetViewControl(map: Leaflet.Map) {
		const resetViewControl = L.Control.extend({
			options: {
				position: 'topleft',
				title: 'Reset view',
			},
			onAdd: (map: Leaflet.Map) => {
				const container = L.DomUtil.create('div', 'leaflet-control-reset');
				container.style.backgroundColor = '#0D6A92';
				container.style.width = '30px';
				container.style.height = '30px';
				container.style.display = 'flex';
				container.style.alignItems = 'center';
				container.style.justifyContent = 'center';
				container.style.cursor = 'pointer';
				container.style.borderBottom = '2px solid transparent';

				this.loadSvg('assets/images/reset_icon.svg').then(svgContent => {
					const svgContainer = L.DomUtil.create('div', '');
					svgContainer.innerHTML = svgContent;
					svgContainer.style.display = 'flex';
					svgContainer.style.alignItems = 'center';
					svgContainer.style.justifyContent = 'center';
					container.innerHTML = '';
					container.appendChild(svgContainer);
				});

				container.onclick = () => {
					this.resetMapView();
				};

				container.onmouseover = () => {
					container.style.backgroundColor = '#3e9536';
				};

				container.onmouseout = () => {
					container.style.backgroundColor = '#0D6A92';
				};

				const existingZoomControls = document.querySelectorAll(
					'.leaflet-control-container .leaflet-top .leaflet-control-zoom a'
				);
				if (existingZoomControls.length === 2) {
					existingZoomControls[1].parentNode!.insertBefore(
						container,
						existingZoomControls[1].nextSibling
					);
				} else {
					const fallbackContainer = L.DomUtil.create(
						'div',
						'leaflet-bar leaflet-control leaflet-control-custom'
					);
					fallbackContainer.appendChild(container);
					return fallbackContainer;
				}

				return L.DomUtil.create('div');
			},
		});

		map.addControl(new resetViewControl());
	}

	loadSvg(url: string): Promise<string> {
		// @ts-ignore
		return this.httpClient.get(url, { responseType: 'text' }).toPromise();
	}

	resetMapView() {
		const defaultZoom = 8;
		this.mapa.setView(this.coordenadas, defaultZoom);
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		if (!this.custonsLayers) {
			this.httpClient
				.get<GeoJSON.GeoJsonObject>('assets/geoJson/pb-municipios-geo.json')
				.subscribe({
					next: value => {
						Leaflet.geoJSON(value, {
							style(_) {
								return {
									color: '#607d8b',
									opacity: 0.5,
									weight: 2,
									fillOpacity: 0.05,
								};
							},
						}).addTo(map);
					},
				});
		}
	}

	addMunicipiosLabelsInMap() {
		const pane = L.tileLayer(
			'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
			{
				maxZoom: 19,
				pane: 'labels',
			}
		);
		this.mapa.on('zoom', () => {
			if (this.mapa.getZoom() > 8 && !pane.getContainer()) {
				pane.addTo(this.mapa);
			} else {
				pane.remove();
			}
		});
	}

	loadMarkers(map: Leaflet.Map) {
		if (this.markersLayer) {
			this.markersLayer.clearLayers();
			this.markersLayer.addTo(map);
		}
	}

	async getMapImage() {
		const elementoHtml = this.mapa.getContainer();
		const canva = await html2canvas(elementoHtml, {
			useCORS: true,
			allowTaint: true,
			logging: false,
			scale: 2,
		});

		return canva.toDataURL('image/png', 1);
	}
}
