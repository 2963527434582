import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	inject,
	Injector,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { Select } from '@layout/interfaces/select';
import {
	Agrupamento,
	Formato,
	Meses,
	TipoGraficoPCD,
	TipoMapaPCD,
	TipoTabelaPCD,
} from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import {
	compararStrings,
	enumAsSelectOptions,
	obter_erro_request,
	removerAcentos,
} from '@utils';
import {
	ComponenteRelatorio,
	ConjuntoLabelsDinamicasPeriodo,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosInterface,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import moment, { Moment } from 'moment/moment';
import { ComponentesRelatoriosService } from '@home/submodulos/dados-meteorologicos/services/componentes-relatorios.service';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { isAfter, subDays } from 'date-fns';
import { DateTimeUtils } from '@utils/datetime-util';
import { merge } from 'rxjs';
import { Mensagens } from '@core/enums/mensagens';
import { corrigeDuplicacaoNome } from '@modulos/home/submodulos/dados-meteorologicos/utils';
import { JanelaTempo } from '../variaveis/interfaces/variaveis';
import { Variavel } from '@modulos/home/submodulos/dados-meteorologicos/interfaces';
import { LabelsHistoricoVariaveisMeteorologicas } from '../../../../interfaces/variavel-meteorologica-mapa';

@Component({
	selector: 'seira-estacoes-automaticas',
	templateUrl: './estacoes-automaticas.component.html',
	styleUrls: ['./estacoes-automaticas.component.scss'],
})
export class EstacoesAutomaticasComponent implements OnInit, AfterViewInit {
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	private listaRelatorios = inject(
		ComponentesRelatoriosService
	).getRelatorios();
	relatorioAtual?: ComponenteRelatorio;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;
	inputsRelatorioInjector!: Injector;
	form!: FormGroup;
	categorias: Select[] = [];
	formatos: Select[] = [];
	variaveisGrafico: Select[] = [];
	municipios: Municipio[] = [];
	tipos: Select[] = [];
	regioes: Select[] = [];
	microrregioes: Select[] = [];
	microrregioesHelpers: Select[] = [];
	mesorregioes: Select[] = [];
	bacias: Select[] = [];
	estacoes: Estacao[] = [];
	postos: PostosRelatorios[] = [];
	postosHelpers: PostosRelatorios[] = [];
	municipioPostos: Select[] = [];
	municipioPostosHelpers: Select[] = [];
	meses: Select[] = [];
	janelasTempo: Select[] = [];
	optionsAgrupamento: Select[] = [];
	agrupamentoHelpers: Select[] = [];
	variaveisMosaico: Select[] = [];
	mensagemDataInvalida = '';
	botoesDeExportacao: GroupButton[] = [];
	labelAdptativaPeriodo?: ConjuntoLabelsDinamicasPeriodo;
	exibirRangePicker = false;
	dezAnos = 315622799999;

	informacoesGerais: TextoInformativo = {
		label: Mensagens.INFORMACOES_GERAIS,
		texts: [
			{
				title: 'O que são variáveis meteorológicas?',
				text: 'São elementos da atmosfera passíveis de serem mensurados com equipamentos específicos. A medição sistemática (ao longo do tempo), das variáveis meteorológicas como temperatura, vento, chuva, pressão, radiação solar, umidade, etc. definem o comportamento do clima de uma determinada região.',
			},
			{
				title: 'O que você vai encontrar nesta página?',
				text: 'Aqui você pode acompanhar, a partir de dados coletados automaticamente, através de PCD, informações meteorológicas registradas em uma janela de tempo no mínimo horária, nas diversas estações do estado da Paraíba. As informações estão em forma de mapas, usando algoritmos de interpolação de dados, gráficos e tabelas.',
			},
		],
	};
	opcoesTipoVisualizacao: OptionRadio<'MAPA' | 'GRAFICO' | 'TABELA'>[] = [
		{ label: 'Mapa', value: 'MAPA' },
		{ label: 'Gráfico', value: 'GRAFICO' },
		{ label: 'Tabela', value: 'TABELA' },
	];
	opcoesPeriodo: OptionRadio<PeriodosLabel>[] = [
		{ label: 'Diário', value: 'diario' },
		{ label: 'Mensal', value: 'mensal' },
		{ label: 'Anual', value: 'anual' },
	];

	constructor(
		private cdr: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		readonly relatoriosService: RelatoriosService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		this.optionsAgrupamento = enumAsSelectOptions(Agrupamento);
		this.agrupamentoHelpers = enumAsSelectOptions(Agrupamento);
		this.tipos = enumAsSelectOptions(TipoTabelaPCD);
		this.meses = enumAsSelectOptions(Meses);
		this.variaveisGrafico = enumAsSelectOptions(Variavel);
		this.variaveisMosaico = enumAsSelectOptions(
			LabelsHistoricoVariaveisMeteorologicas
		);

		this.janelasTempo = enumAsSelectOptions(JanelaTempo);
		this.setFormControls();
		this.setInjector();
		this.buscarFiltrosEstacoes();
		this.observarCategorias();
		this.observarFormulario();
		this.observarFormatos();
		this.observarMudancaTipo();
		this.observarJanelaTempo();
	}

	ngAfterViewInit(): void {
		this.relatorioAtual =
			this.listaRelatorios.PCD.MAPA.VARIAVEL_METEOROLOGICA_INTERPOLADA;
		this.gerarRelatorio();
		this.loadDynamicComponent(this.relatorioAtual.componente);
		this.setLabelAdptativaPeriodo();
		this.cdr.detectChanges();
	}

	get dataPeriodo() {
		const data = this.getFormItemValue(FormularioRelatorio.PERIODO);

		if (data && this.periodoInfo) {
			const dataObj = moment(data);

			if (this.periodoInfo.unit === 'day') {
				return dataObj.startOf('day').toDate();
			} else if (this.periodoInfo.unit === 'month') {
				return dataObj.startOf('month').toDate();
			} else if (this.periodoInfo.unit === 'year') {
				return dataObj.startOf('year').toDate();
			}
		}
		return null;
	}

	get dataInicio() {
		const data = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		if ((data && this.periodoInfo) || (data && this.exibirRangePicker)) {
			const dataObj = moment(data);
			if (this.periodoInfo?.unit === 'day' || this.exibirRangePicker) {
				return dataObj.startOf('day').toDate();
			} else if (this.periodoInfo?.unit === 'month') {
				if (periodoBusca !== 'mensal') {
					return dataObj.startOf('day').toDate();
				}
				return dataObj.startOf('month').toDate();
			} else if (this.periodoInfo?.unit === 'year') {
				if (periodoBusca !== 'anual') {
					return dataObj.startOf('day').toDate();
				}
				return dataObj.startOf('year').toDate();
			}
		}

		return null;
	}

	get dataFim() {
		const data = this.getFormItemValue(FormularioRelatorio.DATA_FIM);

		if ((data && this.periodoInfo) || (data && this.exibirRangePicker)) {
			const dataObj = moment(data);
			const hoje = moment();
			if (this.periodoInfo?.unit === 'day' || this.exibirRangePicker) {
				if (
					dataObj.date() !== hoje.date() ||
					dataObj.month() !== hoje.month() ||
					dataObj.year() !== hoje.year()
				) {
					return dataObj.endOf('day').toDate();
				}
				return dataObj.toDate();
			} else if (this.periodoInfo?.unit === 'month') {
				if (
					dataObj.month() !== hoje.month() ||
					dataObj.year() !== hoje.year()
				) {
					return dataObj.endOf('month').toDate();
				}
				return dataObj.toDate();
			} else if (this.periodoInfo?.unit === 'year') {
				if (dataObj.year() !== hoje.year()) {
					return dataObj.endOf('year').toDate();
				}
				return dataObj.toDate();
			}
		}
		return null;
	}

	get limiteDataInicioInferior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_INICIO
		);
		const periodoInfo = this.periodoInfo;
		if (!periodoInfo || !dataInicioFromForm) return null;
		return moment(new Date(dataInicioFromForm))
			.subtract(periodoInfo?.amount, periodoInfo?.unit)
			.toDate();
	}

	get limiteDataFimSuperior() {
		const dataInicioFromForm = this.getFormItemValue(
			FormularioRelatorio.DATA_INICIO
		);
		if (!this.periodoInfo || !dataInicioFromForm) return null;
		const amount = Number(this.periodoInfo?.amount);
		const limiteSuperior = moment(new Date(dataInicioFromForm))
			.add(amount, this.periodoInfo?.unit)
			.toDate();
		const hoje = new Date();
		if (limiteSuperior > hoje) return hoje;
		return limiteSuperior;
	}

	get periodoInfo() {
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		) as PeriodosLabel;
		let tipoPeriodoAtual: PeriodosLabel = 'diario';

		if (
			this.relatorioAtual?.periodos?.anual &&
			(periodoBusca === 'anual' ||
				(!this.relatorioAtual?.periodos?.mensal &&
					!this.relatorioAtual?.periodos?.diario))
		) {
			tipoPeriodoAtual = 'anual';
		} else if (
			this.relatorioAtual?.periodos?.mensal &&
			(periodoBusca === 'mensal' ||
				(!this.relatorioAtual?.periodos?.anual &&
					!this.relatorioAtual?.periodos?.diario))
		) {
			tipoPeriodoAtual = 'mensal';
		} else if (this.relatorioAtual?.periodos?.mensal_unico) {
			tipoPeriodoAtual = 'mensal_unico';
		} else if (this.relatorioAtual?.periodos?.anual_unico) {
			tipoPeriodoAtual = 'anual_unico';
		} else if (
			this.relatorioAtual?.periodos?.mensal_parcial &&
			periodoBusca === 'mensal_parcial'
		) {
			tipoPeriodoAtual = 'mensal_parcial';
		} else if (
			this.relatorioAtual?.periodos?.mensal_completo &&
			periodoBusca === 'mensal_completo'
		) {
			tipoPeriodoAtual = 'mensal_completo';
		} else if (
			this.relatorioAtual?.periodos?.anual_parcial &&
			periodoBusca === 'anual_parcial'
		) {
			tipoPeriodoAtual = 'anual_parcial';
		} else if (
			this.relatorioAtual?.periodos?.anual_completo &&
			periodoBusca === 'anual_completo'
		) {
			tipoPeriodoAtual = 'anual_completo';
		} else {
			tipoPeriodoAtual = 'diario';
		}

		const periodoInfo = this.relatorioAtual?.periodos?.[tipoPeriodoAtual];
		return periodoInfo;
	}

	get mostrarPeriodoBusca() {
		return this.getMostrarEntidade(FormularioRelatorio.PERIODO_BUSCA);
	}

	get mostrarSelectAgrupamento() {
		return this.getMostrarEntidade(FormularioRelatorio.AGRUPAMENTO);
	}

	get mostrarSelectPostos() {
		return this.getMostrarEntidade(FormularioRelatorio.ESTACAO);
	}

	get mostrarSelectMunicipios() {
		return this.getMostrarEntidade(FormularioRelatorio.MUNICIPIO);
	}

	get mostrarSelectMicrorregioes() {
		return this.getMostrarEntidade(FormularioRelatorio.MICRORREGIAO);
	}

	get mostrarSelectMesorregioes() {
		return this.getMostrarEntidade(FormularioRelatorio.MESORREGIAO);
	}

	get mostrarSelectRegioes() {
		return this.getMostrarEntidade(FormularioRelatorio.REGIAO_PLUVIOMETRICA);
	}

	get mostrarSelectBacias() {
		return this.getMostrarEntidade(FormularioRelatorio.BACIA);
	}

	get mostrarSelectVariaveisMapa() {
		return this.getMostrarEntidade(FormularioRelatorio.VARIAVEL_MAPA);
	}
	get mostrarSelectVariaveisMosaico() {
		return this.getMostrarEntidade(FormularioRelatorio.VARIAVEIS_MOSAICO);
	}
	get mostrarSelectVariaveisGrafico() {
		return this.getMostrarEntidade(FormularioRelatorio.VARIAVEL_GRAFICO);
	}

	get mostrarSelectJanelaTempo(): boolean {
		return this.getMostrarEntidade(FormularioRelatorio.JANELA_TEMPO);
	}

	get isMesesValidos(): boolean {
		const mesInicio = this.form.get(FormularioRelatorio.MES_INICIO)?.value;
		const mesFim = this.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return false;
		}

		const mesesEnum = Object.values(Meses);
		const indexMesInicio = mesesEnum.findIndex(mes =>
			compararStrings(mes, mesInicio)
		);
		const indexMesFim = mesesEnum.findIndex(mes =>
			compararStrings(mes, mesFim)
		);

		if (indexMesInicio > indexMesFim) {
			return false;
		}

		return true;
	}

	get isIntervalosDatasValido(): boolean {
		const mapeamento: Record<string, string> = {
			day: 'dias',
			month: 'meses',
			year: 'anos',
		};

		const hoje = new Date();

		if (
			(!this.dataInicio || !this.dataFim) &&
			(this.relatorioAtual?.periodos?.diario ||
				this.relatorioAtual?.periodos?.mensal ||
				this.relatorioAtual?.periodos?.anual)
		) {
			this.mensagemDataInvalida = `Selecione uma data de início e uma data de fim`;
			return false;
		}

		if (
			!this.dataPeriodo &&
			(this.relatorioAtual?.periodos?.mensal_unico ||
				this.relatorioAtual?.periodos?.anual_unico)
		) {
			this.mensagemDataInvalida = `Selecione uma data`;
			return false;
		}

		if (this.dataPeriodo && isAfter(this.dataPeriodo, hoje)) {
			this.mensagemDataInvalida = `Não é permitido selecionar datas futuras`;
			return false;
		}

		if (this.dataInicio && this.dataFim) {
			if (isAfter(this.dataInicio, hoje) || isAfter(this.dataFim, hoje)) {
				this.mensagemDataInvalida = `Não é permitido selecionar datas futuras`;
				return false;
			}

			if (isAfter(this.dataInicio, this.dataFim)) {
				this.mensagemDataInvalida = `A data de início deve ser anterior à data de fim`;
				return false;
			}

			const diferencaInicioFim = DateTimeUtils.subtrairEmMilisegundos(
				this.dataFim,
				this.dataInicio
			);

			if (this.exibirRangePicker) {
				if (diferencaInicioFim > this.dezAnos) {
					this.mensagemDataInvalida = `A diferença entre as datas deve ser de no máximo 10 anos`;
					return false;
				}
				return true;
			}

			const limite = this.limiteDataInicioInferior;

			if (!limite) {
				return true;
			}

			const dataInicio = moment(this.dataInicio);
			const dataFimMinEsperada = dataInicio
				.clone()
				.add(this.periodoInfo?.minAmount, this.periodoInfo?.unit);

			const dataFimMaxEsperada = dataInicio
				.clone()
				.add(this.periodoInfo?.amount, this.periodoInfo?.unit);

			const diferencaMinInicioFim = this.periodoInfo?.minAmount
				? dataFimMinEsperada.diff(dataInicio)
				: 0;
			const diferencaMaxInicioFim = this.periodoInfo?.amount
				? dataFimMaxEsperada.diff(dataInicio)
				: Infinity;

			const toleranciaMilissegundo = 1;
			if (diferencaInicioFim < diferencaMinInicioFim - toleranciaMilissegundo) {
				this.mensagemDataInvalida = `A diferença entre as datas deve ser de no mínimo ${this
					.periodoInfo?.minAmount} ${
					mapeamento[this.periodoInfo?.unit as string]
				}`;
				return false;
			}

			if (diferencaInicioFim > diferencaMaxInicioFim + toleranciaMilissegundo) {
				this.mensagemDataInvalida = `A diferença entre as datas deve ser de no máximo ${this
					.periodoInfo?.amount} ${
					mapeamento[this.periodoInfo?.unit as string]
				}`;
				return false;
			}
		}

		return true;
	}

	observarCategorias() {
		this.formatos = enumAsSelectOptions(Formato);

		if (this.formatos.length == 1) {
			this.form.patchValue(
				{ [FormularioRelatorio.TIPO_VISUALIZACAO]: this.formatos[0].value },
				{ emitEvent: false }
			);
		}
		const formato = this.getFormItemValue(
			FormularioRelatorio.TIPO_VISUALIZACAO
		);
		this.analiseFormato(formato);
	}

	observarFormatos() {
		this.form
			.get(FormularioRelatorio.TIPO_VISUALIZACAO)
			?.valueChanges.subscribe(formato => {
				this.analiseFormato(formato);
			});
	}

	analiseFormato(formato: string) {
		switch (formato) {
			case 'GRAFICO':
				this.tipos = enumAsSelectOptions(TipoGraficoPCD);
				break;
			case 'MAPA':
				this.tipos = enumAsSelectOptions(TipoMapaPCD);
				break;
			default:
				this.tipos = enumAsSelectOptions(TipoTabelaPCD);
		}
		this.form.patchValue({ tipo: this.tipos[0].value }, { emitEvent: false });
	}

	observarFormulario() {
		this.form.valueChanges.subscribe({
			next: ({
				tipoVisualizacao,
				tipo,
				periodoBusca,
			}: {
				tipoVisualizacao?: keyof typeof Formato;
				tipo?:
					| keyof typeof TipoTabelaPCD
					| keyof typeof TipoGraficoPCD
					| keyof typeof TipoMapaPCD;
				periodoBusca: PeriodosLabel;
			}) => {
				if ('PCD' in this.listaRelatorios) {
					const categorias = this.listaRelatorios['PCD'] as any;
					if (tipoVisualizacao && tipoVisualizacao in categorias) {
						const formatos = categorias[tipoVisualizacao];
						if (tipo && tipo in formatos) {
							this.relatorioAtual = formatos[tipo];
						} else {
							this.relatorioAtual = formatos;
						}
					} else {
						this.relatorioAtual = categorias;
					}
				}

				this.loadDynamicComponent(this.relatorioAtual?.componente);
				if (this.mostrarPeriodoBusca) {
					this.setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca);
				} else {
					this.setLabelAdptativaPeriodoSemPeriodoBusca();
				}
				this.cdr.detectChanges();
			},
		});
	}

	observarMudancaTipo() {
		merge(
			this.form.get('tipo')!.valueChanges,
			this.form.get('tipoVisualizacao')!.valueChanges,
			this.form.get('periodoBusca')!.valueChanges
		).subscribe(() => {
			this.resetDatas();
			setTimeout(() => {
				if (!this.form.get(FormularioRelatorio.AGRUPAMENTO)?.value) {
					this.form
						.get(FormularioRelatorio.AGRUPAMENTO)
						?.setValue(this.optionsAgrupamento[0].value);
				}
				this.preencherValores();
				this.gerarRelatorio();
			}, 100);
		});
	}

	observarJanelaTempo() {
		this.form.get('janelaTempo')?.valueChanges.subscribe(valor => {
			this.exibirRangePicker = valor === 'Personalizada';

			if (this.exibirRangePicker) {
				const hoje = moment().format('YYYY-MM-DD');

				this.form.patchValue({
					dataInicio: hoje,
					dataFim: hoje,
				});
			}
		});
	}

	gerarRelatorio() {
		if (
			!this.isMesesValidos &&
			this.relatorioAtual?.periodos?.mensal_por_nome
		) {
			this.toastr.info(
				'O mês de início deve ser anterior ao mês de fim.',
				'Não foi possível gerar resultados'
			);
			return;
		}

		if (!this.isIntervalosDatasValido) {
			this.periodoInfo?.amount;
			if (!this.periodoInfo) {
				this.toastr.info(
					'O intervalo de datas é inválido',
					'Não foi possível gerar resultados'
				);
			}

			this.toastr.info(
				this.mensagemDataInvalida,
				'Não foi possível gerar resultados'
			);
			return;
		}

		this.form.patchValue({
			dataInicio: this.dataInicio,
			dataFim: this.dataFim,
			periodo: this.dataPeriodo,
		});

		if (this.form.invalid) {
			return;
		}

		this.instanceRelatorio?.gerarRelatorio();
	}

	getFormItemValue(field: string) {
		return this.form.get(field)?.value;
	}

	get loadingForm() {
		return !!this.form.get(FormularioRelatorio.LOADING_GERAR)?.value;
	}

	setLoading(state: boolean): void {
		this.form.get(FormularioRelatorio.LOADING_GERAR)?.setValue(state);
	}

	private setFormControls(): void {
		const hoje = new Date();
		const mesesArray = Object.values(Meses);
		const mesAtual = removerAcentos(mesesArray[hoje.getMonth()]);

		this.form = this.formBuilder.group({
			[FormularioRelatorio.TIPO]: new FormControl<
				| keyof typeof TipoTabelaPCD
				| keyof typeof TipoGraficoPCD
				| keyof typeof TipoMapaPCD
				| null
			>('VARIAVEL_METEOROLOGICA_INTERPOLADA'),
			[FormularioRelatorio.TIPO_VISUALIZACAO]: new FormControl('MAPA'),
			[FormularioRelatorio.AGRUPAMENTO]: new FormControl('MUNICIPIO_POSTO'),
			[FormularioRelatorio.ESTACAO]: new FormControl(null),
			[FormularioRelatorio.MUNICIPIO]: new FormControl(null),
			[FormularioRelatorio.MICRORREGIAO]: new FormControl(null),
			[FormularioRelatorio.MESORREGIAO]: new FormControl(null),
			[FormularioRelatorio.BACIA]: new FormControl(null),
			[FormularioRelatorio.REGIAO_PLUVIOMETRICA]: new FormControl(null),
			[FormularioRelatorio.VARIAVEL_GRAFICO]: new FormControl<null | string>(
				ValuesVariaveis.PRECIPITACAO.toUpperCase()
			),
			[FormularioRelatorio.VARIAVEL_MAPA]: new FormControl<null | string>(
				ValuesVariaveis.PRECIPITACAO
			),
			[FormularioRelatorio.JANELA_TEMPO]: new FormControl<null | string>(
				JanelaTempo['Últimas 6 horas']
			),
			[FormularioRelatorio.PERIODO_BUSCA]: new FormControl<PeriodosLabel>(
				'diario'
			),
			[FormularioRelatorio.VARIAVEIS_MOSAICO]: new FormControl<string>(
				'PRECIPITACAO'
			),
			[FormularioRelatorio.DATA_INICIO]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.DATA_FIM]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.MES_INICIO]: new FormControl<string>(
				Meses.JANEIRO.toLocaleUpperCase()
			),
			[FormularioRelatorio.MES_FIM]: new FormControl<string>(
				mesAtual.toLocaleUpperCase()
			),
			[FormularioRelatorio.PERIODO]: new FormControl<moment.Moment | null>(
				moment(hoje)
			),
			[FormularioRelatorio.LOADING_GERAR]: new FormControl<boolean>(false),
		});
	}

	private setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS,
					useValue: {
						agrupamento: this.optionsAgrupamento,
						form: this.form,
						postos: this.postos,
						municipioPostos: this.municipioPostos,
						estacoes: this.estacoes,
						regioes: this.regioes,
						bacias: this.bacias,
						microrregioes: this.microrregioes,
						mesorregioes: this.mesorregioes,
						municipios: this.municipios,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}

	private buscarFiltrosEstacoes() {
		this.relatoriosService.consultarOpcoesFiltros().subscribe({
			next: resp => {
				this.municipios = resp.municipios;
				this.estacoes = resp.estacoes;
				this.microrregioes = resp.microrregioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.microrregioesHelpers = resp.microrregioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.mesorregioes = resp.mesorregioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.regioes = resp.regioes.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.bacias = resp.bacias.map(val => ({
					name: val.nome,
					value: val.id.toString(),
				}));
				this.postos = resp.estacoes.map(estacao => ({
					id: estacao.id,
					municipio: estacao.nomeMunicipio,
					nomePosto: estacao.nomePosto,
					tipo: estacao.tipoEstacao,
					responsavel: estacao.responsavel ?? '-',
				}));
				this.postosHelpers = this.postos;
				if (this.estacoes) {
					const estacoesFiltradas = this.estacoes.filter(
						e => e.tipoEstacao === 'PCD' && e.statusEstacao === 'ATIVA'
					);

					const estacoesFormatadas = estacoesFiltradas.map(e => {
						return {
							name: corrigeDuplicacaoNome(`${e.nomeMunicipio}/${e.nomePosto}`),
							value: e.id.toString(),
						};
					});

					this.municipioPostos = estacoesFormatadas;
					this.municipioPostosHelpers = estacoesFormatadas;

					this.form.patchValue({
						estacao: this.municipioPostos[0].value,
					});
				}
				this.setInjector();
				this.preencherValores();
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das estações'
				);
			},
		});
	}

	private preencherValores() {
		if (!this.form.get(FormularioRelatorio.MUNICIPIO)?.value) {
			this.form
				.get(FormularioRelatorio.MUNICIPIO)
				?.setValue(this.municipios[0].id);
		}

		if (!this.form.get(FormularioRelatorio.ESTACAO)?.value) {
			this.form
				.get(FormularioRelatorio.ESTACAO)
				?.setValue(this.municipioPostos[0].value);
		}

		if (!this.form.get(FormularioRelatorio.MESORREGIAO)?.value) {
			this.form
				.get(FormularioRelatorio.MESORREGIAO)
				?.setValue(this.mesorregioes[0].value);
		}

		if (!this.form.get(FormularioRelatorio.MICRORREGIAO)?.value) {
			this.form
				.get(FormularioRelatorio.MICRORREGIAO)
				?.setValue(this.microrregioes[0].value);
		}

		if (!this.form.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)?.value) {
			this.form
				.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
				?.setValue(this.regioes[0].value);
		}

		if (!this.form.get(FormularioRelatorio.BACIA)?.value) {
			this.form.get(FormularioRelatorio.BACIA)?.setValue(this.bacias[0].value);
		}

		if (!this.form.get(FormularioRelatorio.JANELA_TEMPO)?.value) {
			this.form
				.get(FormularioRelatorio.JANELA_TEMPO)
				?.setValue(this.janelasTempo[0].value);
		}

		if (!this.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.value) {
			this.form
				.get(FormularioRelatorio.VARIAVEL_MAPA)
				?.setValue(ValuesVariaveis.PRECIPITACAO);
		}
		if (!this.form.get(FormularioRelatorio.VARIAVEIS_MOSAICO)?.value) {
			this.form
				.get(FormularioRelatorio.VARIAVEIS_MOSAICO)
				?.setValue([LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO]);
		}

		if (!this.form.get(FormularioRelatorio.VARIAVEL_GRAFICO)?.value) {
			this.form
				.get(FormularioRelatorio.VARIAVEL_GRAFICO)
				?.setValue(this.variaveisGrafico[0].value);
		}
	}

	private loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];

				if (this.relatorioAtual?.periodos) {
					this.setOpcoesPeriodoByRelatorioInstance(
						this.relatorioAtual.periodos
					);
				}
				if (
					!this.instanceRelatorio.postos?.length ||
					!this.instanceRelatorio.estacoes?.length ||
					!this.instanceRelatorio.regioes?.length ||
					!this.instanceRelatorio.mesorregioes?.length ||
					!this.instanceRelatorio.municipios?.length
				) {
					this.instanceRelatorio.postos = this.postos;
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.regioes = this.regioes;
					this.instanceRelatorio.mesorregioes = this.mesorregioes;
					this.instanceRelatorio.municipios = this.municipios;

					if (
						this.instanceRelatorio.agrupamento?.length === 0 ||
						this.instanceRelatorio.agrupamento === undefined
					) {
						this.optionsAgrupamento = this.agrupamentoHelpers;
					} else {
						this.optionsAgrupamento = this.instanceRelatorio.agrupamento;
					}
					this.instanceRelatorio.agrupamento = this.optionsAgrupamento;

					if (
						this.instanceRelatorio.microrregioes?.length === 0 ||
						this.instanceRelatorio.microrregioes === undefined
					) {
						this.microrregioes = this.microrregioesHelpers;

						if (
							this.form.get(FormularioRelatorio.MICRORREGIAO)?.value === '0'
						) {
							this.form
								.get(FormularioRelatorio.MICRORREGIAO)
								?.setValue(this.microrregioes[0].value);
						}
					} else {
						this.microrregioes = this.instanceRelatorio.microrregioes;
					}
					this.instanceRelatorio.microrregioes = this.microrregioes;

					if (
						this.instanceRelatorio.municipioPostos?.length === 0 ||
						this.instanceRelatorio.municipioPostos === undefined
					) {
						this.municipioPostos = this.municipioPostosHelpers;

						if (this.form.get(FormularioRelatorio.ESTACAO)?.value === '0') {
							this.form
								.get(FormularioRelatorio.ESTACAO)
								?.setValue(this.municipioPostos[0].value);
						}
					} else {
						this.municipioPostos = this.instanceRelatorio.municipioPostos;
					}
					this.instanceRelatorio.municipioPostos = this.municipioPostos;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}

	private get periodoBusca(): string {
		return this.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	private resetDatas() {
		const hoje = new Date();
		const umMesAtras = subDays(hoje, 30);

		const mesesArray = Object.values(Meses);
		const mesAtual = removerAcentos(mesesArray[hoje.getMonth()]);

		this.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValue(Meses.JANEIRO.toLocaleUpperCase());
		this.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValue(mesAtual.toLocaleUpperCase());

		if (this.periodoBusca.toLocaleLowerCase() === 'mensal') {
			const inicioAnoAtual = new Date(hoje.getFullYear(), 0, 1);
			this.form
				.get(FormularioRelatorio.DATA_INICIO)
				?.setValue(moment(inicioAnoAtual));
			this.form.get(FormularioRelatorio.DATA_FIM)?.setValue(moment(hoje));
		} else if (this.periodoBusca.toLocaleLowerCase() === 'anual') {
			const dezAnosAtras = new Date(hoje);
			dezAnosAtras.setFullYear(hoje.getFullYear() - 9);
			this.form
				.get(FormularioRelatorio.DATA_INICIO)
				?.setValue(moment(dezAnosAtras));
			this.form.get(FormularioRelatorio.DATA_FIM)?.setValue(moment(hoje));
		} else {
			this.form
				.get(FormularioRelatorio.DATA_INICIO)
				?.setValue(moment(umMesAtras));
			this.form.get(FormularioRelatorio.DATA_FIM)?.setValue(moment(hoje));
		}

		this.form.get(FormularioRelatorio.PERIODO)?.setValue(moment(hoje));
	}

	private setOpcoesPeriodoByRelatorioInstance(
		periodos: Partial<{
			[K in PeriodosLabel]: PeriodosInterface;
		}>
	) {
		this.opcoesPeriodo = [];

		if (this.mostrarPeriodoBusca) {
			if (periodos.diario) {
				this.opcoesPeriodo.push({ label: 'Diário', value: 'diario' });
			}
			if (periodos.mensal) {
				this.opcoesPeriodo.push({ label: 'Mensal', value: 'mensal' });
			}
			if (periodos.anual) {
				this.opcoesPeriodo.push({ label: 'Anual', value: 'anual' });
			}
			if (periodos.mensal_parcial) {
				this.opcoesPeriodo.push({
					label: 'Mensal parcial',
					value: 'mensal_parcial',
				});
			}
			if (periodos.anual_parcial) {
				this.opcoesPeriodo.push({
					label: 'Anual parcial',
					value: 'anual_parcial',
				});
			}
			if (periodos.mensal_completo) {
				this.opcoesPeriodo.push({
					label: 'Mensal completo',
					value: 'mensal_completo',
				});
			}
			if (periodos.anual_completo) {
				this.opcoesPeriodo.push({
					label: 'Anual completo',
					value: 'anual_completo',
				});
			}
		}
	}

	private setLabelAdptativaPeriodo() {
		const periodoBusca = this.form.get('periodoBusca')!.value;

		if (this.mostrarPeriodoBusca) {
			this.setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca);
		} else {
			this.setLabelAdptativaPeriodoSemPeriodoBusca();
		}
	}

	private setLabelAdptativaPeriodoByPeriodoBusca(periodoBusca: PeriodosLabel) {
		this.labelAdptativaPeriodo = {};

		switch (periodoBusca) {
			case 'diario':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Dia inicial',
						placeholder: 'Selecione o dia inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Dia final',
						placeholder: 'Selecione o dia final',
						dataType: 'day',
					},
				};
				break;
			case 'mensal':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Mês inicial',
						placeholder: 'Selecione o mês inicial',
						dataType: 'month',
					},
					fim: {
						label: 'Mês final',
						placeholder: 'Selecione o mÊs final',
						dataType: 'month',
					},
				};
				break;
			case 'anual':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Ano inicial',
						placeholder: 'Selecione o ano inicial',
						dataType: 'year',
					},
					fim: {
						label: 'Ano final',
						placeholder: 'Selecione o ano final',
						dataType: 'year',
					},
				};
				break;
			case 'mensal_completo':
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Mês',
						placeholder: 'Selecione o mês',
						dataType: 'month',
					},
				};
				break;
			case 'mensal_parcial':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Dia inicial',
						placeholder: 'Selecione o dia inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Dia final',
						placeholder: 'Selecione o dia final',
						dataType: 'day',
					},
				};
				break;

			case 'anual_completo':
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Ano',
						placeholder: 'Selecione o ano',
						dataType: 'year',
					},
				};
				break;
			case 'anual_parcial':
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Dia inicial',
						placeholder: 'Selecione o dia inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Dia final',
						placeholder: 'Selecione o dia final',
						dataType: 'day',
					},
				};
				break;
		}
	}

	private setLabelAdptativaPeriodoSemPeriodoBusca() {
		this.labelAdptativaPeriodo = {};
		const periodosRelatorio = this.relatorioAtual?.periodos;
		if (periodosRelatorio) {
			if (periodosRelatorio.mensal_unico) {
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Mês',
						placeholder: 'Selecione um mês',
						dataType: 'month',
					},
				};
			}

			if (periodosRelatorio.anual_unico) {
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Ano',
						placeholder: 'Selecione um ano',
						dataType: 'year',
					},
				};
			}

			if (periodosRelatorio.mensal_por_nome) {
				this.labelAdptativaPeriodo = {
					mensal_por_nome: true,
				};
			}

			if (periodosRelatorio.diario) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Período inicial',
						placeholder: 'Selecione o período inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Período final',
						placeholder: 'Selecione o período final',
						dataType: 'day',
					},
				};
			}

			if (periodosRelatorio.mensal) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Mês inicial',
						placeholder: 'Selecione o mês inicial',
						dataType: 'month',
					},
					fim: {
						label: 'Mês final',
						placeholder: 'Selecione o mês final',
						dataType: 'month',
					},
				};
			}

			if (periodosRelatorio.anual) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Ano inicial',
						placeholder: 'Selecione o ano inicial',
						dataType: 'year',
					},
					fim: {
						label: 'Ano final',
						placeholder: 'Selecione o ano final',
						dataType: 'year',
					},
				};
			}
		} else {
			if (this.exibirRangePicker) {
				this.labelAdptativaPeriodo = {
					inicio: {
						label: 'Período inicial',
						placeholder: 'Selecione o período inicial',
						dataType: 'day',
					},
					fim: {
						label: 'Período final',
						placeholder: 'Selecione o período final',
						dataType: 'day',
					},
				};
			} else {
				this.labelAdptativaPeriodo = {
					unico: {
						label: 'Dia',
						placeholder: 'Selecione uma data',
						dataType: 'day',
					},
				};
			}
		}
	}

	private getMostrarEntidade(tipoFormulario: FormularioRelatorio) {
		const condicoesMostrarItens =
			this.relatorioAtual?.condicoesMostrarItensForm?.[tipoFormulario];

		if (condicoesMostrarItens) {
			const values = this.form.getRawValue();
			return condicoesMostrarItens(values);
		}
		return false;
	}

	protected readonly FormularioRelatorio = FormularioRelatorio;
}
