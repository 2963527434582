export function corrigeDuplicacaoNome(nome: string) {
	if (!nome) return '-';
	const nomeFormatado = nome.replaceAll(/\s+\/\s+/g, '/');
	const [nomeMun, nomeEstacao] = nomeFormatado.split('/');
	if (nomeMun === nomeEstacao) return nomeMun;
	const nomeParenteses = nome.match(/^(.+?)\s+\((.+?)\)$/);
	if (nomeParenteses && nomeParenteses[1] === nomeParenteses[2]) {
		return nomeParenteses[1];
	}
	return nome;
}
