import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { DadosRelatorioVariaveis } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format, formatISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import {
	exportarPDF,
	exportarCSV,
	exportarTXT,
	exportarXLSX,
} from '../../../utils/exportacao-tabelas';
import { Historico } from '../../../interfaces';

@Component({
	selector: 'seira-variaveis-meteorologicas-tabela',
	templateUrl: './variaveis-meteorologicas-tabela.component.html',
	styleUrls: ['./variaveis-meteorologicas-tabela.component.scss'],
})
export class VariaveisMeteorologicasTabelaComponent
	implements OnDestroy, InstanciaRelatorio
{
	carregando = true;
	tituloTabela = '';
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaVariaveis: DadosRelatorioVariaveis[] = [];

	descricaoRelatorio =
		'Define-se como variáveis meteorológicas os parâmetros atmosféricos, como temperatura, umidade, e velocidade do vento, que influenciam as condições climáticas de um local ou região.';

	@ViewChild('tabelaVariaveisMeteorologicas', { static: false })
	tabela?: PublicTableComponent;

	colunas: ADTColumns[] = [
		{
			data: 'dataColeta',
			className: 'text-start',
			title: 'Data',
		},
		{
			data: 'precipitacao',
			className: 'text-start',
			title: 'Precipitação',
		},
		{
			data: 'temperatura',
			className: 'text-start',
			title: 'Temperatura',
		},
		{
			data: 'umidade',
			className: 'text-start',
			title: 'Umidade',
		},
		{
			data: 'pressao',
			className: 'text-start',
			title: 'Pressão',
		},
		{
			data: 'ventoDirecao',
			className: 'text-start',
			title: 'Direção do vento 1m',
		},
		{
			data: 'ventoVelocidade',
			className: 'text-start',
			title: 'Velocidade do vento 1m',
		},
		{
			data: 'radiacao',
			className: 'text-start',
			title: 'Radiação',
		},
		{
			data: 'vento2m',
			className: 'text-start',
			title: 'Velocidade do vento 2m',
		},
		{
			data: 'ventoDirecao2',
			className: 'text-start',
			title: 'Direção do vento 2m',
		},
		{
			data: 'temperaturaDoSolo',
			className: 'text-start',
			title: 'Temperatura do solo',
		},
		{
			data: 'umidadeSolo',
			className: 'text-start',
			title: 'Umidade do solo',
		},
		{
			data: 'evapotranspiracaoPotencial',
			className: 'text-start',
			title: 'Evapotranspiração',
		},
		{
			data: 'correnteRecarga',
			className: 'text-start',
			title: 'Corrente recarga',
		},
		{
			data: 'sensorPortaAberta',
			className: 'text-start',
			title: 'Sensor porta aberta',
		},
		{
			data: 'umidadeInterna',
			className: 'text-start',
			title: 'Umidade interna',
		},
		{
			data: 'temperaturaInterna',
			className: 'text-start',
			title: 'Temperatura interna',
		},
		{
			data: 'tensaoBateria',
			className: 'text-end',
			title: 'Tensão da bateria',
		},
		{
			data: 'conteudoAguaSolo60cm',
			className: 'text-end',
			title: 'Conteúdo água solo 60 cm',
		},
		{
			data: 'conteudoAguaSolo50cm',
			className: 'text-end',
			title: 'Conteúdo água solo 50 cm',
		},
		{
			data: 'conteudoAguaSolo40cm',
			className: 'text-end',
			title: 'Conteúdo água solo 40 cm',
		},
		{
			data: 'conteudoAguaSolo30cm',
			className: 'text-end',
			title: 'Conteúdo água solo 30 cm',
		},
		{
			data: 'conteudoAguaSolo20cm',
			className: 'text-end',
			title: 'Conteúdo água solo 20 cm',
		},
		{
			data: 'conteudoAguaSolo10cm',
			className: 'text-end',
			title: 'Conteúdo água solo 10 cm',
		},
		{
			data: 'temperaturaDoSolo10cm',
			className: 'text-end',
			title: 'Temperatura solo 10 cm',
		},
		{
			data: 'temperaturaDoSolo20cm',
			className: 'text-end',
			title: 'Temperatura solo 20 cm',
		},
		{
			data: 'temperaturaDoSolo30cm',
			className: 'text-end',
			title: 'Temperatura solo 30 cm',
		},
		{
			data: 'temperaturaDoSolo40cm',
			className: 'text-end',
			title: 'Temperatura solo 40 cm',
		},
		{
			data: 'temperaturaDoSolo50cm',
			className: 'text-end',
			title: 'Temperatura solo 50 cm',
		},
		{
			data: 'temperaturaDoSolo60cm',
			className: 'text-end',
			title: 'Temperatura solo 60 cm',
		},
		{
			data: 'ventoDirecao1DesvioPadrao',
			className: 'text-end',
			title: 'Vento direção 1 desvio Padrão ',
		},
		{
			data: 'ventoDirecao2DesvioPadrao',
			className: 'text-end',
			title: 'Vento direção 2 desvio Padrão',
		},
		{
			data: 'ventoVelocidade1Maxima',
			className: 'text-end',
			title: 'Vento velocidade 1 máxima',
		},
		{
			data: 'ventoVelocidade2Maxima',
			className: 'text-end',
			title: 'Vento velocidade 2 máxima',
		},
	];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(this.colunas, this.tabela, '', this.tituloTabela),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => exportarCSV(this.tabela, '', this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => exportarTXT(this.tabela, '', this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => exportarXLSX(this.tabela, '', this.tituloTabela),
		},
	];

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}

	gerarRelatorio(): void {
		const idPosto = this.getFormItemValue(FormularioRelatorio.ESTACAO);
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.tituloTabela = `Variáveis meteorológicas - ${DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		)}`;

		this.carregando = true;
		this.inputs.setLoading(true);

		if (this.getFormItemValue(FormularioRelatorio.ESTACAO) === 0) {
			this.relatoriosService
				.buscarRelatorioVariaveisTodosMunicipios({
					tipo: TipoEstacao.PCD,
					diaInicio: formatISO(new Date(dataInicio)),
					diaFim: formatISO(new Date(dataFim.setHours(dataFim.getHours() - 3))),
				})
				.subscribe({
					next: resp => {
						this.dadosTabelaVariaveis = this.padronizarResultado(resp);
						this.relatoriosService.verificaExistenciaDados(resp);
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.carregando = false;
						this.inputs.setLoading(false);
					},
					complete: () => {
						this.carregando = false;
						this.inputs.setLoading(false);
					},
				});
		} else {
			this.relatoriosService
				.buscarRelatorioVariaveis(
					idPosto,
					formatISO(new Date(dataInicio)),
					formatISO(new Date(dataFim.setHours(dataFim.getHours() - 3)))
				)
				.subscribe({
					next: variaveis => {
						this.dadosTabelaVariaveis = this.padronizarResultado([variaveis]);
						this.relatoriosService.verificaExistenciaDados(variaveis.historico);
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.carregando = false;
						this.inputs.setLoading(false);
					},
					complete: () => {
						this.carregando = false;
						this.inputs.setLoading(false);
					},
				});
		}
	}

	padronizarResultado(
		relatorios: DadosRelatorioVariaveis[]
	): DadosRelatorioVariaveis[] {
		return relatorios.map(relatorio => ({
			...relatorio,
			historico: relatorio.historico.map(dado => ({
				...dado,
				dataColeta: dado.dataColeta
					? format(new Date(dado.dataColeta.toString()), 'dd/MM/yyyy HH:mm')
					: '-',
				precipitacao: isNotNuloOuUndefined(dado.precipitacao)
					? numberToBrNumber(dado.precipitacao, 1)
					: '-',
				...this.formatarCampos(dado, [
					'temperatura',
					'umidade',
					'pressao',
					'ventoDirecao',
					'ventoVelocidade',
					'radiacao',
					'vento2m',
					'ventoDirecao2',
					'temperaturaDoSolo',
					'umidadeSolo',
					'evapotranspiracaoPotencial',
					'correnteRecarga',
					'sensorPortaAberta',
					'umidadeInterna',
					'temperaturaInterna',
					'tensaoBateria',
					'conteudoAguaSolo60cm',
					'conteudoAguaSolo50cm',
					'conteudoAguaSolo40cm',
					'conteudoAguaSolo30cm',
					'conteudoAguaSolo20cm',
					'conteudoAguaSolo10cm',
					'temperaturaDoSolo10cm',
					'temperaturaDoSolo20cm',
					'temperaturaDoSolo30cm',
					'temperaturaDoSolo40cm',
					'temperaturaDoSolo50cm',
					'temperaturaDoSolo60cm',
					'ventoDirecao1DesvioPadrao',
					'ventoDirecao2DesvioPadrao',
					'ventoVelocidade1Maxima',
					'ventoVelocidade2Maxima',
					'indice',
					'atributo',
					'nome',
					'unidadeDeMedida',
				]),
			})) as Historico[],
		}));
	}

	private formatarCampos(dado: any, campos: string[]): Partial<Historico> {
		return campos.reduce((acc, campo) => {
			acc[campo as keyof Historico] = isNotNuloOuUndefined(dado[campo])
				? typeof dado[campo] === 'number'
					? numberToBrNumber(dado[campo], 2)
					: dado[campo]
				: '-';
			return acc;
		}, {} as Partial<Historico>);
	}
}
