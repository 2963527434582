import { formatISO } from 'date-fns';
import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import {
	DadosEvapotranspiracao,
	DadosMediaHistorica,
	DadosMicrorregiao,
	DadosRelatorioPluviometricoDiario,
	DadosRelatorioEstatisticaMensal,
	DadosRelatorioPrecipitacaoAnual,
	RelatorioPrecipitacaoAnual,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Moment } from 'moment';
import { FiltrosOpcoes } from '../interfaces/filtros-opcoes';
import { Observable } from 'rxjs';
import { RotasRelatorios } from '@home/submodulos/dados-meteorologicos/enum/RotasRelatorios';

import {
	DadosConsultaTodosOsSensores,
	DadosRelatorioVariaveis,
	PostoSudene,
	RelatorioDesvio,
} from '@home/submodulos/dados-meteorologicos/interfaces/response';
import {
	TiposPeriodosVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodo,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { DesvioDTO } from '../interfaces/desvioDTO';
import { RelatorioNDCResponse } from '../interfaces/relatorio-ndc';
import { RelatorioVECResponse } from '../interfaces';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import {
	DadosValoresExtremos,
	DadosValoresExtremosResponse,
} from '../interfaces/valores-extremos';
import { DadosNdc } from '../interfaces/variavel-meteorologica-chuva';

export interface DadosChuva {
	data: string;
	volume: number;
}

type DTOBuscaDesvio = {
	agrupamento: string;
	idEntidade?: number;
	dataInicio: Date;
	dataFim: Date;
	periodo: string;
	incluirDetalhes: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class RelatoriosService {
	private exibirBotoesExportacao: boolean;

	constructor(private http: HttpClientProtectedService) {}

	public setExibirBotoesExportacao(exibirBotoesExportacao: boolean) {
		this.exibirBotoesExportacao = exibirBotoesExportacao;
	}

	public getExibirBotoesExportacao() {
		return this.exibirBotoesExportacao;
	}

	listarVariaveisMeteorologicas(
		tipoPeriodo: TiposPeriodosVariaveisMeteorologicas,
		dataInicio?: string,
		dataFim?: string,
		tipoEstacao?: string
	) {
		return this.http.get<VariaveisMeteorologicasPorPeriodo[]>(
			Rotas.RELATORIO_VARIAVEIS_METEOROLOGICAS,
			{ tipoPeriodo, dataInicio, dataFim, tipoEstacao }
		);
	}

	buscarRelatorioDesvio({
		agrupamento,
		idEntidade,
		dataFim,
		dataInicio,
		periodo,
		incluirDetalhes,
	}: DTOBuscaDesvio): Observable<RelatorioDesvio[]> {
		return this.http.get('desvio', {
			agrupamento,
			id: idEntidade,
			dataInicio: formatISO(new Date(dataInicio)),
			dataFim: formatISO(new Date(dataFim)),
			periodo,
			incluirDetalhes,
		});
	}

	buscarPostosSudene(): Observable<PostoSudene[]> {
		return this.http.get<PostoSudene[]>(Rotas.POSTOS_SUDENE);
	}

	buscarHistoricoChuvas(
		tipoPeriodo: string,
		dataInicio?: string | null,
		dataFim?: string | null
	) {
		return this.http.get<DadosNdc[]>(
			Rotas.RELATORIOS + '/precipitacao/mapa-ndc',
			{
				tipoPeriodo,
				dataInicio,
				dataFim,
			}
		);
	}

	buscarRelatorioEvapotranspiracao(
		postoIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<any, Relatorios<DadosEvapotranspiracao>[]>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.EVAPOTRANSPIRACAO,
			{
				postoIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPluviometricos(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<any, Relatorios<DadosRelatorioPluviometricoDiario>[]>(
			Rotas.RELATORIOS + `/precipitacao/diaria`,
			{
				postosIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPluviometricosPorPosto(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	) {
		return this.http.post<any, Relatorios<DadosRelatorioEstatisticaMensal>[]>(
			Rotas.RELATORIOS + `/precipitacao/resumo-pluviometrico`,
			{
				postosIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPluviometricosDoEstado(
		diaInicio: string,
		diaFim: string
	): Observable<DadosMicrorregiao> {
		return this.http.post<any, any>(
			Rotas.RELATORIOS + `/precipitacao/resumo-pluviometrico-estado`,
			{
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioSudene(
		idPostoSudene: number,
		dataInicio: string,
		dataFim: string
	) {
		return this.http.get(Rotas.PLUVIOMETRIA, {
			idPostoSudene,
			dataInicio,
			dataFim,
		});
	}

	buscarGraficoSPI(
		diaInicio: Moment,
		diaFim: Moment,
		idRegiaoPluviometrica?: number
	): Observable<Blob> {
		return this.http.getBlob(Rotas.GRAFICOS + '/precipitacao/spi', {
			diaInicio: diaInicio.toISOString(),
			diaFim: diaFim.toISOString(),
			idRegiaoPluviometrica,
		});
	}

	consultarOpcoesFiltros(): Observable<FiltrosOpcoes> {
		return this.http.get<FiltrosOpcoes>(
			Rotas.RELATORIOS + '/precipitacao/filtro-estacoes'
		);
	}

	buscarRelatorioMediaHistorica(postosIds: number[], diaFim: Moment) {
		return this.http.post<any, Relatorios<DadosMediaHistorica>[]>(
			Rotas.RELATORIOS + `/precipitacao/media-historica`,
			{
				postosIds,
				diaFim,
			}
		);
	}

	relatorioPrecipitacaoAnual(
		postosIds: number[],
		diaInicio: string | null,
		diaFim: string
	): Observable<RelatorioPrecipitacaoAnual> {
		return this.http.post<any, RelatorioPrecipitacaoAnual>(
			Rotas.RELATORIOS + `/precipitacao/acumulada`,
			{
				postosIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioPrecipitacaoAnual(
		postosIds: number[],
		diaInicio: string,
		diaFim: string
	): Observable<DadosRelatorioPrecipitacaoAnual> {
		return this.http.post<any, any>(Rotas.RELATORIOS + '/precipitacao/anual', {
			postosIds,
			diaInicio,
			diaFim,
		});
	}

	buscarRelatorioVariaveis(postoId: number, diaInicio: string, diaFim: string) {
		return this.http.post<any, DadosRelatorioVariaveis>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.VARIAVEIS,
			{
				postoId,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioVariaveisTodosMunicipios(dados: DadosConsultaTodosOsSensores) {
		return this.http.post<DadosConsultaTodosOsSensores, any>(
			Rotas.ACOMPANHAMENTO_COLETA + RotasRelatorios.VARIAVEISTODOSPOSTOS,
			dados
		);
	}

	buscarRelatorioPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, AgrupamentoResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-precipitacao',
			dados
		);
	}

	buscarRelatorioNDCPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, RelatorioNDCResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-ndc',
			dados
		);
	}

	buscarRelatorioVECPorAgrupamento(dados: DadosAgrupamento) {
		return this.http.post<DadosAgrupamento, RelatorioVECResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/agrupamento-valores-extremos-chuva',
			dados
		);
	}

	relatorioMapaInterpolacaoDesvio(
		diaInicio: string,
		diaFim: string
	): Observable<DesvioDTO[]> {
		return this.http.post<any, any>(
			Rotas.CLIMATOLOGIA + '/desvio-por-municipio',
			{
				diaInicio,
				diaFim,
			}
		);
	}
	relatorioMapaInterpolacaoDesvioPorPosto(
		diaInicio: string,
		diaFim: string
	): Observable<DesvioDTO[]> {
		return this.http.post<any, any>(
			Rotas.CLIMATOLOGIA + '/desvio-por-municipio-posto',
			{
				diaInicio,
				diaFim,
			}
		);
	}

	buscarClimatologia(mesInicio: any, mesFim: any): Observable<any> {
		return this.http.post<any, any[]>(`climatologia`, {
			mesInicio,
			mesFim,
		});
	}

	buscarClimatologiaPorMunicipio(
		mesInicio: string,
		mesFim: string,
		agrupamento: string,
		idMunicipio?: number,
		idEstacao?: number,
		idMicrorregiao?: number
	): Observable<ClimatologiaResponseDTO[]> {
		return this.http.post<any, ClimatologiaResponseDTO[]>(
			`climatologia-por-agrupamento`,
			{
				mesInicio,
				mesFim,
				agrupamento,
				climatologiaBuscaId: { idMunicipio, idEstacao, idMicrorregiao },
			}
		);
	}
	buscarRelatorioSPI(
		diaInicio: string,
		diaFim: string,
		id: number,
		agrupamento: string
	): Observable<any[]> {
		return this.http.get<any>(Rotas.RELATORIOS + '/precipitacao/spi', {
			diaInicio,
			diaFim,
			id,
			agrupamento,
		});
	}

	buscarDadosTabelaPluviometriaObservada(diaInicio: string, diaFim: string) {
		return this.http.get<any[]>(
			Rotas.PLUVIOMETRIA + '/tabela-pluviometria-observada',
			{
				diaInicio,
				diaFim,
			}
		);
	}

	buscarRelatorioValoresExtremos({
		diaInicio,
		diaFim,
		agrupamento,
		tipoPeriodoValoresExtremos,
		posto,
	}: DadosValoresExtremos): Observable<DadosValoresExtremosResponse[]> {
		return this.http.get<DadosValoresExtremosResponse[]>(
			Rotas.RELATORIOS + '/precipitacao/valores-extremos',
			{
				diaInicio,
				diaFim,
				agrupamento,
				tipoPeriodoValoresExtremos,
				posto,
			}
		);
	}

	verificaExistenciaDados(response: any[] | unknown[] | any | unknown) {
		if (
			response.length === 0 ||
			response == null ||
			response == ({} as any) ||
			response == ({} as unknown)
		) {
			this.setExibirBotoesExportacao(false);
		} else {
			this.setExibirBotoesExportacao(true);
		}
	}
}
