import { StatusEstacao } from '../enums/status-estacao';
import { TipoEstacao, TipoPCD } from '../enums/tipo-estacao';
import { DriverDecodificacao } from '../enums/driver-decodificado';
import { Fonte } from './fonte';
import { Entidade } from '@layout/interfaces/entidade';
import { Municipio } from './municipio';
import { Regional } from '@modulos/meteorologia/submodulos/estacao/interfaces/regional';
import ZonedDateTime from '@utils/date/zoned-datetime';
import { Bacia } from '@modulos/recursos-hidricos/submodulos/bacia/interface/bacia';
import { Usuario } from '@administracao/submodulos/usuario/interfaces/usuario';

export interface EstacaoFiltrada {
	id: number;
	microrregiaoCodigo: string;
	nomeMunicipio: string;
	statusEstacao: string;
	telefone: null | number | string;
	responsavel: null | string;
	tipoEstacao: string;
	microrregiao: number;
	longitude: number;
	latitude: number;
	mesorregiao: number;
	nomePosto: string;
	municipio: number;
	bacia: number;
	codigoInterno: string;
	regional: number;
}

export interface TipoMedicao {
	id: number;
	dominio: string;
	label: string;
	field: string;
	und_medida: string;
	data_cadastro: Date;
}

export interface ListagemEstacao {
	id: number;
	nomePosto: string;
	nomeMunicipio: string;
	tipoEstacao: string;
	statusEstacao: string;
}

export class Estacao extends Entidade {
	codigoSudene = '';
	codigoNacional = '';
	codigoInterno = '';
	latitude: number | null = null;
	longitude: number | null = null;
	altitude: number | null = null;
	anoInstalacao: number | null = null;
	nomePosto = '';
	prioridade = 0;
	tipoEstacao: keyof typeof TipoEstacao | null = null;
	leituraDiaria = false;
	statusEstacao: StatusEstacao | null = null;
	driverDecodificacao: DriverDecodificacao | null = null;
	informacoesComplementares = '';
	municipio: Municipio | null = null;
	bacia: Bacia | null = null;
	responsavel: Usuario | null = null;
	fonte: Fonte | null = null;
	descricaoStatus = '';
	regional: Regional | null = null;
	tiposMedicao: TipoMedicao[] = [];
	tipoPCD: TipoPCD | null = null;
	regionalNome: string | null = null;
	constructor(data: Estacao) {
		super();
		this.id = data.id;
		this.tipoEstacao = data.tipoEstacao || null;
		this.nomePosto = data.nomePosto || '';
		this.statusEstacao = data.statusEstacao || null;
		this.codigoNacional = data.codigoNacional || '';
		this.codigoSudene = data.codigoSudene || '';
		this.codigoInterno = data.codigoInterno || '';
		this.latitude = data.latitude;
		this.longitude = data.longitude;
		this.altitude = data.altitude;
		this.anoInstalacao = data.anoInstalacao ? +data.anoInstalacao : null;
		this.driverDecodificacao = data.driverDecodificacao || null;
		this.leituraDiaria = data.leituraDiaria || false;
		this.informacoesComplementares = data.informacoesComplementares || '';
		this.prioridade = data.prioridade || 0;
		this.municipio = data.municipio || null;
		this.bacia = data.bacia || null;
		this.fonte = data.fonte || null;
		this.responsavel = data.responsavel || null;
		this.descricaoStatus = data.descricaoStatus || '';
		this.regional = data.regional || null;
		this.tiposMedicao = data.tiposMedicao || [];
		this.tipoPCD = data.tipoPCD || null;
		this.regionalNome = data.regionalNome || null;
	}

	static empty() {
		const emptyEstacaoData = {
			tipoEstacao: null,
			nomePosto: '',
			statusEstacao: null,
			codigoNacional: '',
			codigoSudene: '',
			codigoInterno: '',
			latitude: null,
			longitude: null,
			altitude: null,
			anoInstalacao: null,
			driverDecodificacao: null,
			leituraDiaria: false,
			informacoesComplementares: '',
			prioridade: 0,
			municipio: null,
			bacia: null,
			responsavel: null,
			fonte: null,
			descricaoStatus: '',
			regional: null,
			tiposMedicao: [],
			tipoPCD: null,
			regionalNome: null,
		};
		return new Estacao(emptyEstacaoData);
	}
}
export interface PCDsSincronizacao {
	estacoesIds: number[];
	dataBusca: ZonedDateTime;
}
