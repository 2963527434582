import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';
import {
	DadosRelatorioPluviometricoDiario,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { formatISO } from 'date-fns';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { capitalizeFirstLetter, numberToBrNumber } from '@utils';
import { ToastrService } from 'ngx-toastr';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';
import { corrigeDuplicacaoNome } from '../../../utils';
import * as ExcelTable from 'mr-excel';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-diaria',
	templateUrl: './pluviometro-tabela-precipitacao-diaria.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-diaria.component.scss'],
})
export class PluviometroTabelaPrecipitacaoDiariaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como pluviometria diária, a quantidade de chuva acumulada em um determinado local para um período de 24 horas.';

	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = true;
	periodo = '';
	dadosTabelaRelatorio: Relatorios<DadosRelatorioPluviometricoDiario>[] = [];
	tituloRelatorio = '';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	private subscription = new Subscription();
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}
		const dataInicio = this.inputs.form.get('dataInicio')?.value;
		const dataFim = this.inputs.form.get('dataFim')?.value;
		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		this.periodo = this.getPeriodo();

		const postosIds = this.postos.map(posto => posto.id);

		this.tituloRelatorio = `Pluviometria observada diária (mm) - ${this.getPeriodo()}`;
		this.relatoriosService
			.buscarRelatorioPluviometricos(
				postosIds,
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim))
			)
			.subscribe({
				next: pluviometricos => {
					this.relatoriosService.verificaExistenciaDados(pluviometricos);
					this.dadosTabelaRelatorio =
						this.converteFormatoRelatorioPluviometrico(pluviometricos);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}

	converteFormatoRelatorioPluviometrico(dadosOriginais: any) {
		const dadosTransformados: Relatorios<DadosRelatorioPluviometricoDiario>[] =
			[];

		dadosOriginais?.relatoriosDiarios.forEach((relatorio: any) => {
			const detalhesPosto = relatorio.detalhesPosto;

			for (let i = 0; i < relatorio.dadosMensais.length; i++) {
				const dadosMensais = relatorio.dadosMensais[i];

				if (dadosMensais) {
					const municipio = detalhesPosto.municipio;
					const posto = detalhesPosto.posto;
					const tipo =
						detalhesPosto.tipo === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Pluviômetro convencional'
							: detalhesPosto.tipo;
					const periodo = new Date(dadosMensais.periodo);
					const mesAno = `${capitalizeFirstLetter(
						periodo.toLocaleString('pt-BR', {
							month: 'long',
						})
					)}/${periodo.getFullYear()}`;
					const mesAnoCompacto = this.transformarFormatoMesAno(mesAno);

					const municipioExistente = dadosTransformados.find(
						dados => dados.municipio === municipio
					);

					let data: Relatorios<DadosRelatorioPluviometricoDiario> = {
						municipio: municipio,
						data: [],
					};

					if (municipioExistente) {
						data = municipioExistente;
					}

					const dadosPosto = {
						posto: posto,
						mesAno: mesAnoCompacto,
						tipo: tipo,
						d1: null,
						d2: null,
						d3: null,
						d4: null,
						d5: null,
						d6: null,
						d7: null,
						d8: null,
						d9: null,
						d10: null,
						d11: null,
						d12: null,
						d13: null,
						d14: null,
						d15: null,
						d16: null,
						d17: null,
						d18: null,
						d19: null,
						d20: null,
						d21: null,
						d22: null,
						d23: null,
						d24: null,
						d25: null,
						d26: null,
						d27: null,
						d28: null,
						d29: null,
						d30: null,
						d31: null,
						total: dadosMensais.precipitacaoAcumulada,
					};

					data.data.push(dadosPosto);

					data.data.forEach(() => {
						dadosMensais.medicaoDiaria.forEach((md: any, index: any) => {
							if (md) {
								const seq = 'd' + (index + 1);

								data.data[data.data.length - 1][seq] = md.precipitacao;
							}
						});
					});

					if (!municipioExistente) {
						dadosTransformados.push(data);
					}
				}
			}
		});

		dadosTransformados.sort((a, b) => {
			return a.municipio.localeCompare(b.municipio, 'pt-BR', {
				sensitivity: 'base',
			});
		});

		return dadosTransformados;
	}

	transformarFormatoMesAno(stringOriginal: string) {
		const mesesAbreviados: any = {
			janeiro: 'Jan',
			fevereiro: 'Fev',
			março: 'Mar',
			abril: 'Abr',
			maio: 'Mai',
			junho: 'Jun',
			julho: 'Jul',
			agosto: 'Ago',
			setembro: 'Set',
			outubro: 'Out',
			novembro: 'Nov',
			dezembro: 'Dez',
		};

		const partes = stringOriginal.split('/');

		const mesCompleto = partes[0].toLowerCase();
		const ano = partes[1];

		const mesAbreviado = mesesAbreviados[mesCompleto];

		if (mesAbreviado) {
			return `${mesAbreviado}/${ano}`;
		} else {
			return stringOriginal;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	getPeriodo() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');
		return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim, 'diario');
	}

	retornarPrecipitacaoDiariaParaXLSX() {
		const tableData: any[] = [];

		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push({
					municipio: dadoRelatorio.municipio,
					mesAno: dado.mesAno,
					posto: dado.posto,
					tipo: dado.tipo,
					d1: numberToBrNumber(dado['d1'], 1) || '-',
					d2: numberToBrNumber(dado['d2'], 1) || '-',
					d3: numberToBrNumber(dado['d3'], 1) || '-',
					d4: numberToBrNumber(dado['d4'], 1) || '-',
					d5: numberToBrNumber(dado['d5'], 1) || '-',
					d6: numberToBrNumber(dado['d6'], 1) || '-',
					d7: numberToBrNumber(dado['d7'], 1) || '-',
					d8: numberToBrNumber(dado['d8'], 1) || '-',
					d9: numberToBrNumber(dado['d9'], 1) || '-',
					d10: numberToBrNumber(dado['d10'], 1) || '-',
					d11: numberToBrNumber(dado['d11'], 1) || '-',
					d12: numberToBrNumber(dado['d12'], 1) || '-',
					d13: numberToBrNumber(dado['d13'], 1) || '-',
					d14: numberToBrNumber(dado['d14'], 1) || '-',
					d15: numberToBrNumber(dado['d15'], 1) || '-',
					d16: numberToBrNumber(dado['d16'], 1) || '-',
					d17: numberToBrNumber(dado['d17'], 1) || '-',
					d18: numberToBrNumber(dado['d18'], 1) || '-',
					d19: numberToBrNumber(dado['d19'], 1) || '-',
					d21: numberToBrNumber(dado['d21'], 1) || '-',
					d22: numberToBrNumber(dado['d22'], 1) || '-',
					d23: numberToBrNumber(dado['d23'], 1) || '-',
					d24: numberToBrNumber(dado['d24'], 1) || '-',
					d25: numberToBrNumber(dado['d25'], 1) || '-',
					d26: numberToBrNumber(dado['d26'], 1) || '-',
					d27: numberToBrNumber(dado['d27'], 1) || '-',
					d28: numberToBrNumber(dado['d28'], 1) || '-',
					d29: numberToBrNumber(dado['d29'], 1) || '-',
					d30: numberToBrNumber(dado['d30'], 1) || '-',
					d31: numberToBrNumber(dado['d31'], 1) || '-',
					total: numberToBrNumber(dado.total, 1) || '-',
				});
			});
		});

		return tableData;
	}

	async exportarPDF(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.content.push({
			text: this.tituloRelatorio,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaPluviometricoDiario.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			let i = 0;
			while (i < dadoRelatorio.data.length) {
				const tableData: (string | number)[][] = [];

				tableData.push([
					'Data',
					'01',
					'02',
					'03',
					'04',
					'05',
					'06',
					'07',
					'08',
					'09',
					'10',
					'11',
					'12',
					'13',
					'14',
					'15',
					'16',
					'17',
					'18',
					'19',
					'21',
					'22',
					'23',
					'24',
					'25',
					'26',
					'27',
					'28',
					'29',
					'30',
					'31',
					'Total',
				]);
				let j = i;
				while (dadoRelatorio.data[i]?.posto === dadoRelatorio.data[j]?.posto) {
					tableData.push([
						dadoRelatorio.data[j].mesAno,
						Number(dadoRelatorio.data[j]['d1']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d1'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d2']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d2'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d3']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d3'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d4']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d4'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d5']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d5'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d6']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d6'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d7']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d7'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d8']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d8'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d9']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d9'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d10']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d10'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d11']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d11'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d12']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d12'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d13']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d13'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d14']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d14'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d15']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d15'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d16']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d16'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d17']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d17'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d18']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d18'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d19']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d19'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d21']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d21'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d22']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d22'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d23']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d23'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d24']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d24'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d25']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d25'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d26']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d26'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d27']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d27'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d28']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d28'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d29']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d29'], 1)
							: '-',
						Number(dadoRelatorio.data[j]['d30']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d30'])
							: '-',
						Number(dadoRelatorio.data[j]['d31']) > 0
							? numberToBrNumber(dadoRelatorio.data[j]['d31'], 1)
							: '-',
						Number(dadoRelatorio.data[j].total) > 0
							? numberToBrNumber(dadoRelatorio.data[j].total, 1)
							: '-',
					]);
					j++;
				}

				documentDefinition.content.push(
					{
						text: (dadoRelatorio.municipio = corrigeDuplicacaoNome(
							`${dadoRelatorio.municipio}/${dadoRelatorio.data[i].posto}`
						)),
						fontSize: 10,
						margin: [0, 10],
					},
					{
						table: {
							headerRows: 1,
							widths: Array(tableData[0].length).fill('auto'),
							body: tableData.map(row =>
								row.map(cell => ({ text: cell, fontSize: 10 }))
							),
						},
						layout: {
							fillColor: (rowIndex: number, node: any, columnIndex: number) => {
								return rowIndex === 0 ? '#DCDCDC' : null;
							},
						},
					}
				);

				if (i < j) {
					i = j;
				} else {
					i++;
				}
			}
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Mês/ano',
			'posto',
			'Tipo posto',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'10',
			'11',
			'12',
			'13',
			'14',
			'15',
			'16',
			'17',
			'18',
			'19',
			'21',
			'22',
			'23',
			'24',
			'25',
			'26',
			'27',
			'28',
			'29',
			'30',
			'31',
			'Total',
		]);

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio,
					dado.mesAno,
					dado.posto,
					dado.tipo,
					Number(dado['d1']) > 0
						? Number(dado['d1']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d2']) > 0
						? Number(dado['d2']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d3']) > 0
						? Number(dado['d3']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d4']) > 0
						? Number(dado['d4']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d5']) > 0
						? Number(dado['d5']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d6']) > 0
						? Number(dado['d6']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d7']) > 0
						? Number(dado['d7']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d8']) > 0
						? Number(dado['d8']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d9']) > 0
						? Number(dado['d9']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d10']) > 0
						? Number(dado['d10']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d11']) > 0
						? Number(dado['d11']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d12']) > 0
						? Number(dado['d12']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d13']) > 0
						? Number(dado['d13']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d14']) > 0
						? Number(dado['d14']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d15']) > 0
						? Number(dado['d15']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d16']) > 0
						? Number(dado['d16']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d17']) > 0
						? Number(dado['d17']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d18']) > 0
						? Number(dado['d18']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d19']) > 0
						? Number(dado['d19']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d21']) > 0
						? Number(dado['d21']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d22']) > 0
						? Number(dado['d22']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d23']) > 0
						? Number(dado['d23']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d24']) > 0
						? Number(dado['d24']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d25']) > 0
						? Number(dado['d25']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d26']) > 0
						? Number(dado['d26']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d27']) > 0
						? Number(dado['d27']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d28']) > 0
						? Number(dado['d28']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d29']) > 0
						? Number(dado['d29']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d30']) > 0
						? Number(dado['d30']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado['d31']) > 0
						? Number(dado['d31']).toFixed(1).replace('.', ',')
						: '-',
					Number(dado.total) > 0
						? Number(dado.total).toFixed(1).replace('.', ',')
						: '-',
				]);
			});
		});

		DocumentExporter.gerarCSV(tableData, this.tituloRelatorio);
	}

	exportarTXT(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioPluviometricoDiario>[]
	) {
		let txtData = '';

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			const nicks = dadoRelatorio.municipio;
			dadoRelatorio.data.forEach(dado => {
				const nome = corrigeDuplicacaoNome(`${nicks}/${dado.posto}`);
				txtData +=
					`Município/Posto: ${nome}\n` +
					`Mês/Ano: ${dado.mesAno}\n` +
					`Tipo posto: ${dado.tipo}\n` +
					`1: ${
						Number(dado['d1']) > 0
							? Number(dado['d1']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`2: ${
						Number(dado['d2']) > 0
							? Number(dado['d2']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`3: ${
						Number(dado['d3']) > 0
							? Number(dado['d3']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`4: ${
						Number(dado['d4']) > 0
							? Number(dado['d4']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`5: ${
						Number(dado['d5']) > 0
							? Number(dado['d5']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`6: ${
						Number(dado['d6']) > 0
							? Number(dado['d6']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`7: ${
						Number(dado['d7']) > 0
							? Number(dado['d7']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`8: ${
						Number(dado['d8']) > 0
							? Number(dado['d8']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`9: ${
						Number(dado['d9']) > 0
							? Number(dado['d9']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`10: ${
						Number(dado['d10']) > 0
							? Number(dado['d10']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`11: ${
						Number(dado['d11']) > 0
							? Number(dado['d11']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`12: ${
						Number(dado['d21']) > 0
							? Number(dado['d12']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`13: ${
						Number(dado['d13']) > 0
							? Number(dado['d13']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`14: ${
						Number(dado['d14']) > 0
							? Number(dado['d14']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`15: ${
						Number(dado['d15']) > 0
							? Number(dado['d15']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`16: ${
						Number(dado['d16']) > 0
							? Number(dado['d16']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`17: ${
						Number(dado['d17']) > 0
							? Number(dado['d17']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`18: ${
						Number(dado['d18']) > 0
							? Number(dado['d18']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`19: ${
						Number(dado['d19']) > 0
							? Number(dado['d19']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`20: ${
						Number(dado['d20']) > 0
							? Number(dado['d20']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`21: ${
						Number(dado['d21']) > 0
							? Number(dado['d21']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`22: ${
						Number(dado['d22']) > 0
							? Number(dado['d22']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`23: ${
						Number(dado['d23']) > 0
							? Number(dado['d23']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`24: ${
						Number(dado['d24']) > 0
							? Number(dado['d24']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`25: ${
						Number(dado['d25']) > 0
							? Number(dado['d25']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`26: ${
						Number(dado['d26']) > 0
							? Number(dado['d26']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`27: ${
						Number(dado['d27']) > 0
							? Number(dado['d27']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`28: ${
						Number(dado['d28']) > 0
							? Number(dado['d28']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`29: ${
						Number(dado['d29']) > 0
							? Number(dado['d29']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`30: ${
						Number(dado['d30']) > 0
							? Number(dado['d30']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`31: ${
						Number(dado['d31']) > 0
							? Number(dado['d31']).toFixed(1).replace('.', ',')
							: '-'
					} \n` +
					`Total: ${
						Number(dado.total) > 0
							? Number(dado.total).toFixed(1).replace('.', ',')
							: '-'
					} \n\n`;
			});
		});

		DocumentExporter.gerarTXT(txtData, this.tituloRelatorio);
	}

	exportarXLSX() {
		const nomeArquivo = this.tituloRelatorio;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const headers = [
			{ label: 'municipio', text: 'Município', size: 24 },
			{ label: 'mesAno', text: 'Mês/Ano', size: 24 },
			{ label: 'posto', text: 'Posto', size: 24 },
			{ label: 'tipo', text: 'Tipo do posto', size: 24 },
			{ label: 'd1', text: 'd1', size: 10 },
			{ label: 'd2', text: 'd2', size: 10 },
			{ label: 'd3', text: 'd3', size: 10 },
			{ label: 'd4', text: 'd4', size: 10 },
			{ label: 'd5', text: 'd5', size: 10 },
			{ label: 'd6', text: 'd6', size: 10 },
			{ label: 'd7', text: 'd7', size: 10 },
			{ label: 'd8', text: 'd8', size: 10 },
			{ label: 'd9', text: 'd9', size: 10 },
			{ label: 'd10', text: 'd10', size: 10 },
			{ label: 'd11', text: 'd11', size: 10 },
			{ label: 'd12', text: 'd12', size: 10 },
			{ label: 'd13', text: 'd13', size: 10 },
			{ label: 'd14', text: 'd14', size: 10 },
			{ label: 'd15', text: 'd15', size: 10 },
			{ label: 'd16', text: 'd16', size: 10 },
			{ label: 'd17', text: 'd17', size: 10 },
			{ label: 'd18', text: 'd18', size: 10 },
			{ label: 'd19', text: 'd19', size: 10 },
			{ label: 'd21', text: 'd21', size: 10 },
			{ label: 'd22', text: 'd22', size: 10 },
			{ label: 'd23', text: 'd23', size: 10 },
			{ label: 'd24', text: 'd24', size: 10 },
			{ label: 'd25', text: 'd25', size: 10 },
			{ label: 'd26', text: 'd26', size: 10 },
			{ label: 'd27', text: 'd27', size: 10 },
			{ label: 'd28', text: 'd28', size: 10 },
			{ label: 'd29', text: 'd29', size: 10 },
			{ label: 'd30', text: 'd30', size: 10 },
			{ label: 'd31', text: 'd31', size: 10 },
			{ label: 'total', text: 'Total', size: 10 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPrecipitacaoDiariaParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 5,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();

		this.subscription.unsubscribe();
	}
}
