<div
	class="btn-group bg-transparent align-items-center border mt-2"
	[ngClass]="{ 'mobile-radio-group': isMobile }"
	role="group">
	<ng-container *ngFor="let option of options">
		<label
			class="custom-radio-group-label text-tertiary fw-light btn btn-outline-light border-0 label-input"
			[ngClass]="{ 'fw-bold bg-transparent': control.value === option.value }"
			[class.active]="control.value === option.value"
			[style]="isMobile ? 'font-size: 0.60rem; padding:0.4rem' : ''">
			{{ option.label }}
			<input
				type="radio"
				class="btn-check form-check-input"
				[formControl]="control"
				[id]="option.value"
				[value]="option.value"
				autocomplete="off" />
		</label>
		<div
			*ngIf="options.indexOf(option) !== options.length - 1"
			class="vr my-1"></div>
	</ng-container>
</div>
