<div [ngClass]="[opacity, padding]" class="card-style">
	<div
		class="d-inline-flex w-100"
		[ngClass]="{
			'justify-content-between': !isSmallScreen,
			'justify-content-center flex-wrap gap-3 p-1': isSmallScreen
		}">
		<div>
			<ng-content select="[topContent]"></ng-content>
		</div>
		<div [class]="color + ' h6 m-0'">{{ date | date: 'd' }}</div>
	</div>
	<div class="align-bottom p-0 mt-1">
		<ng-content select="[bottomContent]"></ng-content>
	</div>
	<ng-content select="[footerContent]"></ng-content>
</div>
