import { AfterContentInit, Component, inject, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	Microrregiao,
	PostosRelatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { isNotNuloOuUndefined, numberToBrNumber, verificaSePDF } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subject } from 'rxjs';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';
import moment from 'moment';

@Component({
	selector: 'seira-pluviometro-tabela-desvios-mensais-anuais',
	templateUrl: './pluviometro-tabela-desvios-mensais-anuais.component.html',
	styleUrls: ['./pluviometro-tabela-desvios-mensais-anuais.component.scss'],
})
export class PluviometroTabelaDesviosMensaisAnuaisComponent
	implements OnDestroy, AfterContentInit
{
	inputs = inject(INPUTS_RELATORIOS);
	private _destroyed = new Subject();
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = true;
	dadosTabelaRelatorio: Microrregiao[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	descricaoRelatorio =
		'Define-se como desvios mensais e anuais a quantidade total de precipitação acumulada nas diversas regiões do estado, medida em diferentes períodos para monitoramento e análise climática.';

	periodoTitulo = '';
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {}
	ngAfterContentInit(): void {
		const dataAjustada = moment().subtract(1, 'month').endOf('month').toDate();
		setTimeout(() => {
			this.inputs.form.get(FormularioRelatorio.PERIODO)?.setValue(dataAjustada);
		}, 0);
	}
	gerarRelatorio() {
		const periodo = this.inputs.form.get('periodo')?.value;
		if (this.inputs.form.invalid || !periodo || !this.postos.length) {
			return;
		}
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			periodo,
			'',
			'mensal',
			true
		);

		this.relatoriosService
			.buscarRelatorioPluviometricosDoEstado(
				formatISO(new Date(periodo)),
				formatISO(new Date(periodo))
			)
			.subscribe({
				next: pluviometricos => {
					this.relatoriosService.verificaExistenciaDados(
						pluviometricos.dadosMicrorregiao
					);
					this.dadosTabelaRelatorio = pluviometricos.dadosMicrorregiao;
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
	}

	formatNumber(value: number): string {
		return isNotNuloOuUndefined(value)
			? String(numberToBrNumber(value, 1))
			: '-';
	}

	generateTableData(dadoRelatorio: Microrregiao, isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas: any[] = [];

		colunas.push(
			{ text: 'Município', fillColor: '#DCDCDC' },
			{ text: 'Posto', fillColor: '#DCDCDC' },
			{ text: 'Latitude', fillColor: '#DCDCDC' },
			{ text: 'Longitude', fillColor: '#DCDCDC' },
			{ text: 'Norm. Mensal (mm)', fillColor: '#DCDCDC' },
			{ text: 'Obs. Mensal (mm)', fillColor: '#DCDCDC' },
			{ text: 'Desv. Mensal (mm)', fillColor: '#DCDCDC' },
			{ text: 'Desv. Mensal (%)', fillColor: '#DCDCDC' },
			{ text: 'Norm. Anual (mm)', fillColor: '#DCDCDC' },
			{ text: 'Obs. Anual (mm)', fillColor: '#DCDCDC' },
			{ text: 'Desv. Anual (mm)', fillColor: '#DCDCDC' },
			{ text: 'Desv. Anual (%)', fillColor: '#DCDCDC' }
		);

		verificaSePDF(tableData, colunas, isPdf);

		dadoRelatorio.dadosPorMunicipio.forEach(dado => {
			tableData.push([
				dado.detalhesPosto.municipio,
				dado.detalhesPosto.posto,
				numberToBrNumber(dado.detalhesPosto.latitude, 4),
				numberToBrNumber(dado.detalhesPosto.longitude, 4),
				this.formatNumber(dado.mediaMes),
				this.formatNumber(dado.valorObservadoMes),
				this.formatNumber(dado.desvioMes),
				this.formatNumber(dado.desvioPercentualMes),
				this.formatNumber(dado.mediaAno),
				this.formatNumber(dado.valorObservadoAno),
				this.formatNumber(dado.desvioAno),
				this.formatNumber(dado.desvioPercentualAno),
			]);
		});

		return tableData;
	}

	retornarPluviometriaEstadoParaXLSX(dadoRelatorio: Microrregiao) {
		const tableData: any[] = [];

		dadoRelatorio.dadosPorMunicipio.forEach(dado => {
			tableData.push({
				municipio: dado.detalhesPosto.municipio,
				posto: dado.detalhesPosto.posto,
				latitude: numberToBrNumber(dado.detalhesPosto.latitude, 4),
				longitude: numberToBrNumber(dado.detalhesPosto.longitude, 4),
				mediaMes: this.formatNumber(dado.mediaMes),
				valorObservadoMes: this.formatNumber(dado.valorObservadoMes),
				desvioMes: this.formatNumber(dado.desvioMes),
				desvioPercentualMes: this.formatNumber(dado.desvioPercentualMes),
				mediaAno: this.formatNumber(dado.mediaAno),
				valorObservadoAno: this.formatNumber(dado.valorObservadoAno),
				desvioAno: this.formatNumber(dado.desvioAno),
				desvioPercentualAno: this.formatNumber(dado.desvioPercentualAno),
			});
		});

		return tableData;
	}

	retornarPluviometriaEstadoParaXLSXAux() {
		const tableDataAux: any[] = [];

		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			const table = this.retornarPluviometriaEstadoParaXLSX(dadoRelatorio);
			tableDataAux.push(...table);
		});

		return tableDataAux;
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Desvios mensais e anuais - ${this.periodoTitulo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.dadosTabelaRelatorio.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			const tableData = this.generateTableData(dadoRelatorio, true);
			documentDefinition.content.push(
				{
					text: `Microrregião ${dadoRelatorio.microrregiao}`,
					fontSize: 12,
					margin: [0, 10],
				},
				{
					table: {
						body: tableData,
						layout: {
							noWrap: false,
							fontSize: 5,
						},
					},
				}
			);
		});
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	async exportarCSV() {
		const tableData: any[][] = [];
		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			const table = this.generateTableData(dadoRelatorio, false);
			tableData.push(...table);
		});
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-desvios-mensais-anuais-${this.periodoTitulo}`
		);
	}

	async exportarTXT() {
		let txtData = '';

		this.dadosTabelaRelatorio.forEach(microrregiao => {
			txtData += `Microrregião: ${microrregiao.microrregiao} \n\n`;

			microrregiao.dadosPorMunicipio.forEach(dado => {
				txtData +=
					`Município: ${dado.detalhesPosto.municipio} \n` +
					`Posto: ${dado.detalhesPosto.posto} \n` +
					`Latitude: ${numberToBrNumber(dado.detalhesPosto.latitude, 4)} \n` +
					`Longitude: ${numberToBrNumber(dado.detalhesPosto.longitude, 4)} \n` +
					`Norm. Mensal (mm): ${this.formatNumber(dado.mediaMes)} \n` +
					`Obs. Mensal (mm): ${this.formatNumber(dado.valorObservadoMes)} \n` +
					`Desv. Mensal (mm): ${this.formatNumber(dado.desvioMes)} \n` +
					`Desv. Mensal (%): ${this.formatNumber(
						dado.desvioPercentualMes
					)} \n` +
					`Norm. Anual (mm): ${this.formatNumber(dado.mediaAno)} \n` +
					`Obs. Anual (mm): ${this.formatNumber(dado.valorObservadoAno)} \n` +
					`Desv. Anual (mm): ${this.formatNumber(dado.desvioAno)} \n` +
					`Desv. Anual (%): ${this.formatNumber(
						dado.desvioPercentualAno
					)} \n\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-desvios-mensais-anuais-${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = [
			{
				label: 'municipio',
				text: 'Município',
				size: 24,
			},
			{
				label: 'posto',
				text: 'Posto',
				size: 24,
			},
			{ label: 'latitude', text: 'Latitude', size: 24 },
			{ label: 'longitude', text: 'Longitude', size: 24 },
			{ label: 'mediaMes', text: 'Norm. Mensal (mm)', size: 24 },
			{ label: 'valorObservadoMes', text: 'Obs. Mensal (mm)', size: 24 },
			{ label: 'desvioMes', text: 'Desv. Mensal (mm)', size: 24 },
			{ label: 'desvioPercentualMes', text: 'Desv. Mensal (%)', size: 24 },
			{ label: 'mediaAno', text: 'Norm. Anual (mm)', size: 24 },
			{ label: 'valorObservadoAno', text: 'Obs. Anual (mm)', size: 24 },
			{ label: 'desvioAno', text: 'Desv. Anual (mm)', size: 24 },
			{ label: 'desvioPercentualAno', text: 'Desv. Anual (%)', size: 24 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPluviometriaEstadoParaXLSXAux(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `Desvios mensais e anuais - ${this.periodoTitulo}`,
						styleId: 'title',
					},
				},
			],
			fileName: `relatorio-desvios-mensais-anuais-${this.periodoTitulo}`,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
