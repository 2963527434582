import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PublicSelectEnumComponent } from '@componentes/public-select/public-select-enum.component';
import {
	ModeloVariavel,
	getLabelModeloVariavel,
} from '@modulos/home/submodulos/previsao-numerica-tempo/pages/mapas-de-previsao/enums/modelo-variavel';

@Component({
	selector: 'seira-public-select-variavel',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => PublicSelectVariavelComponent),
		},
	],
})
export class PublicSelectVariavelComponent extends PublicSelectEnumComponent {
	override label = 'Variável';
	override notFoundText = 'Nenhuma variável foi encontrada';
	override placeholder = 'Informe a variável desejada';

	constructor() {
		super(ModeloVariavel, getLabelModeloVariavel);
	}
}
