import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	DadosRelatorioEstatisticaMensal,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { formatISO } from 'date-fns';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';
import * as ExcelTable from 'mr-excel';
import { numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-pluviometro-tabela-estatistica-mensal',
	templateUrl: './pluviometro-tabela-estatistica-mensal.component.html',
	styleUrls: ['./pluviometro-tabela-estatistica-mensal.component.scss'],
})
export class PluviometroTabelaEstatisticaMensalComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como estatística mensal os dados de chuva coletados em uma estação meteorológica específica, utilizados para monitorar o clima em locais pontuais.';
	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = true;
	dadosTabelaRelatorio: Relatorios<DadosRelatorioEstatisticaMensal>[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];
	periodoTitulo = '';

	private subscription = new Subscription();
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	gerarRelatorio() {
		const periodo = this.inputs.form.get('periodo')?.value;
		if (this.inputs.form.invalid || !periodo || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			periodo,
			'',
			'',
			true
		);

		const postosIds = this.postos.map(posto => posto.id);

		this.relatoriosService
			.buscarRelatorioPluviometricosPorPosto(
				postosIds,
				formatISO(new Date(periodo)),
				formatISO(new Date(periodo))
			)
			.subscribe({
				next: pluviometricos => {
					this.relatoriosService.verificaExistenciaDados(pluviometricos);
					this.dadosTabelaRelatorio =
						this.converteFormatoRelatorioPluviometrico(pluviometricos);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
			});
	}

	converteFormatoRelatorioPluviometrico(dadosOriginais: any) {
		const dadosTransformados: Relatorios<DadosRelatorioEstatisticaMensal>[] =
			[];

		dadosOriginais?.resumosPluviometricos.forEach((relatorio: any) => {
			const detalhesPosto = relatorio.detalhesPosto;
			const ano = relatorio.ano;
			const { municipio, posto, tipo } = detalhesPosto;
			const data: Relatorios<DadosRelatorioEstatisticaMensal> = {
				municipio,
				posto,
				tipo: tipo === 'PLUVIOMETRO_CONVENCIONAL' ? 'PCO' : tipo,
				ano,
				data: [],
			};

			for (let i = 0; i < relatorio.resumosMensais.length; i++) {
				const resumosMensais = relatorio.resumosMensais[i];
				if (resumosMensais) {
					const dadosPosto = {
						id: resumosMensais.mes,
						mes: this.transformarFormatoMes(resumosMensais.mes),
						chuvaAcumulada: this.defineCasasDecimais(
							resumosMensais.chuvaAcumulada
						),
						mediaAcumulada: this.defineCasasDecimais(
							resumosMensais.mediaAcumulada
						),
						desvioAcumulado: this.defineCasasDecimais(
							resumosMensais?.desvioAcumulado
						),
						numeroVeranicos: resumosMensais.numeroVeranicos,
						diasConsecultivosSemChuva: resumosMensais.diasConsecultivosSemChuva,
						valorMaiorChuva: this.defineCasasDecimais(
							resumosMensais.valorMaiorChuva
						),
						diaMaiorChuva: this.formataDataISO(resumosMensais.diaMaiorChuva),
						valido: resumosMensais.valido,
						dadosCompletos: resumosMensais.dadosCompletos,
					};

					data.data.push(dadosPosto);
				}
			}

			const dadosTotal = {
				id: 13,
				mes: this.transformarFormatoMes(relatorio.total.mes),
				chuvaAcumulada: this.defineCasasDecimais(
					relatorio.total.chuvaAcumulada
				),
				mediaAcumulada: this.defineCasasDecimais(
					relatorio.total.mediaAcumulada
				),
				desvioAcumulado: this.defineCasasDecimais(
					relatorio.total?.desvioAcumulado
				),
				numeroVeranicos: relatorio.total.numeroVeranicos,
				diasConsecultivosSemChuva: relatorio.total.diasConsecultivosSemChuva,
				valorMaiorChuva: this.defineCasasDecimais(
					relatorio.total.valorMaiorChuva
				),
				diaMaiorChuva: this.formataDataISO(relatorio.total.diaMaiorChuva),
				valido: relatorio.total.valido,
				dadosCompletos: relatorio.total.dadosCompletos,
			};

			data.data.push(dadosTotal);
			dadosTransformados.push(data);
		});

		return dadosTransformados;
	}

	defineCasasDecimais(valor: any) {
		try {
			const resultado = valor?.toFixed(2);
			if (isNaN(resultado)) {
				return null;
			}
			return resultado;
		} catch (e) {
			return null;
		}
	}

	transformarFormatoMes(mes: string) {
		const mesesAbreviados: any = {
			1: 'Jan',
			2: 'Fev',
			3: 'Mar',
			4: 'Abr',
			5: 'Mai',
			6: 'Jun',
			7: 'Jul',
			8: 'Ago',
			9: 'Set',
			10: 'Out',
			11: 'Nov',
			12: 'Dez',
		};

		if (!mes) {
			return 'Total';
		}
		return mesesAbreviados[mes];
	}

	formataDataISO(data: any) {
		if (data) {
			const dataFormatada = moment(data).format('DD/MM/YYYY');
			return dataFormatada;
		}
		return data;
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	retornarPrecipitacaoPorPostoParaXLSX(
		dadosTabelaPluviometricoPorPosto: Relatorios<DadosRelatorioEstatisticaMensal>[]
	) {
		const tableData: any[] = [];

		dadosTabelaPluviometricoPorPosto.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push({
					municipio: dadoRelatorio.municipio,
					posto: dadoRelatorio.posto || '',
					mes: dado.mes,
					chuvaAcumulada: numberToBrNumber(dado.chuvaAcumulada, 1) || '-',
					mediaAcumulada: numberToBrNumber(dado.mediaAcumulada, 1) || '-',
					desvioAcumulado: numberToBrNumber(dado.desvioAcumulado, 1) || '-',
					numeroVeranicos: dado.numeroVeranicos || '-',
					diasConsecultivosSemChuva: dado.diasConsecultivosSemChuva || '-',
					diaMaiorChuva: dado.diaMaiorChuva || '-',
					valorMaiorChuva: numberToBrNumber(dado.valorMaiorChuva, 1) || '-',
				});
			});
		});

		return tableData;
	}

	async exportarPDF(
		dadosTabelaPluviometricoDiario: Relatorios<DadosRelatorioEstatisticaMensal>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Estatística mensal (mm) - ${this.periodoTitulo}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaPluviometricoDiario.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaPluviometricoDiario.forEach(dadoRelatorio => {
			if (dadoRelatorio.data.length) {
				const tableData: (string | number)[][] = [];

				tableData.push([
					'Mês',
					'Chuva acumulada (mm)',
					'Média histórica (mm)',
					'Nº de veranicos',
					'Nº de dias do maior veranico',
					'Dia da maior chuva',
					'Valor da maior chuva (mm)',
				]);

				dadoRelatorio?.data.forEach((item: any) => {
					return tableData.push([
						item.mes,
						numberToBrNumber(item.chuvaAcumulada, 1) || '-',
						numberToBrNumber(item.mediaAcumulada, 1) || '-',
						item.numeroVeranicos || '-',
						item.diasConsecultivosSemChuva || '-',
						item.diaMaiorChuva || '-',
						numberToBrNumber(item.valorMaiorChuva, 1) || '-',
					]);
				});

				documentDefinition.content.push(
					{
						text:
							dadoRelatorio.posto === dadoRelatorio.municipio ||
							dadoRelatorio.posto === ''
								? `${dadoRelatorio.municipio} - ${dadoRelatorio.tipo}`
								: ` ${dadoRelatorio.municipio} - ${dadoRelatorio.posto} - ${dadoRelatorio.tipo}`,
						fontSize: 10,
						margin: [0, 10],
					},
					{
						table: {
							headerRows: 1,
							widths: Array(tableData[0].length).fill('*'),
							body: tableData.map(row =>
								row.map(cell => ({ text: cell, fontSize: 10 }))
							),
						},
						layout: {
							fillColor: (rowIndex: number, node: any, columnIndex: number) => {
								return rowIndex === 0 ? '#DCDCDC' : null; // Cor de fundo para o cabeçalho
							},
						},
					}
				);
			}
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(
		dadosTabelaPluviometricoPorPosto: Relatorios<DadosRelatorioEstatisticaMensal>[]
	) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Mês',
			'Chuva acumulada (mm)',
			'Média histórica (mm)',
			'Nº de veranicos',
			'Nº de dias do maior veranico',
			'Dia da maior chuva',
			'Valor da maior chuva (mm)',
		]);

		dadosTabelaPluviometricoPorPosto.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dadoRelatorio.municipio || '-',
					dadoRelatorio.posto || '-',
					dado.mes,
					numberToBrNumber(dado.chuvaAcumulada, 1) || '-',
					numberToBrNumber(dado.mediaAcumulada, 1) || '-',
					dado.numeroVeranicos || '-',
					dado.diasConsecultivosSemChuva || '-',
					dado.diaMaiorChuva,
					numberToBrNumber(dado.valorMaiorChuva, 1) || '-',
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-estatistica-mensal-${this.periodoTitulo}`
		);
	}

	exportarTXT(
		dadosTabelaPluviometricoPorPosto: Relatorios<DadosRelatorioEstatisticaMensal>[]
	) {
		let txtData = '';

		dadosTabelaPluviometricoPorPosto.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData +=
					`Município: ${dadoRelatorio.municipio} \n` +
					`Posto: ${dadoRelatorio.posto} \n` +
					`Mês: ${dado.mes} \n` +
					`Chuva acumulada (mm): ${
						numberToBrNumber(dado.chuvaAcumulada, 1) || '-'
					} \n` +
					`Média histórica (mm): ${
						numberToBrNumber(dado.mediaAcumulada, 1) || '-'
					} \n` +
					`Nº de veranicos: ${dado.numeroVeranicos || '-'} \n` +
					`Nº de dias do maior veranico: ${
						dado.diasConsecultivosSemChuva || '-'
					} \n` +
					`Dia da maior chuva: ${dado.diaMaiorChuva || '-'} \n` +
					`Valor da maior chuva (mm): ${
						numberToBrNumber(dado.valorMaiorChuva, 1) || '-'
					} \n\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-estatistica-mensal-${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `relatorio-estatistica-mensal-${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{ label: 'municipio', text: 'Município', size: 24 },
			{ label: 'posto', text: 'Posto', size: 24 },
			{ label: 'mes', text: 'Mês', size: 24 },
			{ label: 'chuvaAcumulada', text: 'Chuva acumulada (mm)', size: 24 },
			{ label: 'mediaAcumulada', text: 'Média histórica (mm)', size: 24 },
			{ label: 'numeroVeranicos', text: 'Nº de veranicos', size: 24 },
			{
				label: 'diasConsecultivosSemChuva',
				text: 'Nº de dias do maior veranico',
				size: 24,
			},
			{ label: 'diaMaiorChuva', text: 'Dia da maior chuva', size: 24 },
			{ label: 'valorMaiorChuva', text: 'Valor da maior chuva (mm)', size: 24 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPrecipitacaoPorPostoParaXLSX(
						this.dadosTabelaRelatorio
					),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `Estatística mensal - ${this.periodoTitulo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}
}
