import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

interface ItemLegenda {
	numberCurrentAndNext: number | string;
	color: string;
}
export interface LegendaOptions {
	label: string;
	items: ItemLegenda[];
}

@Component({
	selector: 'seira-legenda-relatorio-historico-variaveis',
	templateUrl: './legenda-relatorio-historico-variaveis.component.html',
	styleUrls: ['./legenda-relatorio-historico-variaveis.component.scss'],
})
export class LegendaRelatorioHistoricoVariaveisComponent
	implements OnInit, AfterViewInit
{
	@Input() options!: LegendaOptions;
	legendas: ItemLegenda[] = [];
	private isTelaPequena = false;
	_destroyed = new Subject();

	constructor(private readonly bp: BreakpointObserver) {}

	ngAfterViewInit(): void {
		this.bp
			.observe(['(max-width: 980px)'])
			.pipe(takeUntil(this._destroyed))
			.subscribe(res => {
				this.isTelaPequena = res.matches;
				this.handleLegendasCasaDecimalOuMenorMaior();
			});
	}

	ngOnInit(): void {
		this.handleLegendasCasaDecimalOuMenorMaior();
	}

	/**
	 * Adiciona no primeiro item da legenda o sinal '≤', no último o '≤', e coloca a casa
	 * decimal em todos os itens
	 */
	handleLegendasCasaDecimalOuMenorMaior(): void {
		const items = this.options.items.map(e => {
			const valor = Number(e.numberCurrentAndNext);
			return {
				...e,
				numberCurrentAndNext: valor.toFixed(
					this.isTelaPequena && Number.isInteger(valor) ? 0 : 1
				),
			};
		});
		items[0].numberCurrentAndNext = `≤ ${
			Number(items[1].numberCurrentAndNext) - 0.1
		}`;
		items[items.length - 1].numberCurrentAndNext = `≥ ${Number(
			items.at(-1)?.numberCurrentAndNext
		).toFixed(1)}`;
		this.legendas = items.map(e => ({
			...e,
			numberCurrentAndNext: e.numberCurrentAndNext.replace('.', ','),
		}));
	}

	percentage(): string {
		const itemCount = this.options.items.length;

		// Convertendo strings para números antes de realizar o cálculo
		const numericItems = this.options.items.map(item => {
			return typeof item.numberCurrentAndNext === 'string'
				? parseFloat(item.numberCurrentAndNext.replace(',', '.'))
				: item.numberCurrentAndNext;
		});

		// Realizando a operação com os valores convertidos
		// Neste exemplo, a conversão é feita mas não utilizada no cálculo da porcentagem
		return 100 / itemCount + '%';
	}
}
