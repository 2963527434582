import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
	selector: 'seira-card-calendario',
	templateUrl: './card-calendario.component.html',
	styleUrls: ['./card-calendario.component.scss'],
})
export class CardCalendarioComponent implements OnInit {
	@Input() date!: Date;
	@Input() isWeekend!: boolean;
	@Input() inMonth!: boolean;
	color!: string;
	opacity!: string;
	isSmallScreen = false;
	resolutionSmall = 730;
	padding!: string;

	ngOnInit() {
		this.checkScreenSize();
		this.color = !this.isWeekend ? 'text-dark' : 'text-danger';
		this.opacity = this.inMonth ? 'opacity-100' : 'opacity-25';
	}

	@HostListener('window:resize')
	onResize(): void {
		this.checkScreenSize();
	}

	private checkScreenSize(): void {
		this.isSmallScreen = window.innerWidth <= this.resolutionSmall;
		this.padding = this.isSmallScreen ? 'p-0' : 'p-2';
	}
}
