import {
	legendaInfoDesvioMilimetro,
	legendaInfoDesvioPorcentagem,
	legendaInfoPluvObservada2MesesAAnual,
	legendaInfoPluvObservadaPeriodo,
} from '@componentes/mapa-paraiba-svg/legenda';
import {
	DesvioCores,
	PluviometriaObservadaCores,
} from '@modulos/home/submodulos/dados-meteorologicos/enum/variaveis-meteorologicas-enum';

import colormap from 'colormap';

const cm: string[] = colormap({
	colormap: 'rainbow',
	nshades: 27,
	format: 'hex',
	alpha: 1,
});

const desvioAcumuladoColormap: string[] = colormap({
	colormap: 'rainbow',
	nshades: 40,
	format: 'hex',
	alpha: 1,
});

const precipitacaoCoresDiasChuva = colormap({
	colormap: [
		{ index: 0, rgb: [255, 255, 255, 1] }, // 9
		{ index: 0.1, rgb: [253, 26, 19, 1] }, // 8
		{ index: 0.2, rgb: [255, 122, 51] }, // 7
		{ index: 0.3, rgb: [255, 218, 51, 1] }, // 6
		{ index: 0.4, rgb: [129, 255, 111, 1] }, // 5
		{ index: 0.5, rgb: [65, 205, 221, 1] }, // 4
		{ index: 0.6, rgb: [51, 173, 255, 1] }, // 3
		{ index: 0.7, rgb: [41, 48, 207, 1] }, // 2
		{ index: 0.8, rgb: [51, 132, 255, 1] }, // 1
	],
	nshades: 9,
	format: 'hex',
	alpha: 0,
});

const precipitacaoColormap = Object.values(PluviometriaObservadaCores);
const desvioMilimetroColormap = Object.values(DesvioCores);
const desvioPorcentagemColormap = Object.values(DesvioCores);

const climatologiaColormap = colormap({
	colormap: [
		{ index: 0, rgb: [249, 53, 55, 1] }, // vermelho
		{ index: 0.1, rgb: [255, 95, 53, 1] }, // laranja
		{ index: 0.2, rgb: [252, 169, 116, 1] }, // salmão
		{ index: 0.3, rgb: [252, 204, 155, 1] }, // laranja pastel
		{ index: 0.4, rgb: [253, 254, 153, 1] }, // amarelo
		{ index: 0.5, rgb: [155, 253, 1, 1] }, // verde 1
		{ index: 0.6, rgb: [115, 217, 115, 1] }, // verde 2
		{ index: 0.7, rgb: [50, 205, 49, 1] }, //verde 3
		{ index: 0.8, rgb: [0, 154, 55, 1] }, // verde 4
		{ index: 0.9, rgb: [50, 93, 92, 1] }, // verde 5
	],

	nshades: 12,
	format: 'hex',
	alpha: 0,
});
const climatologiaMesesColormap = colormap({
	colormap: [
		{ index: 0, rgb: [228, 7, 11, 1] }, // vermelho1
		{ index: 0.1, rgb: [249, 53, 55, 1] }, // vermelho2
		{ index: 0.2, rgb: [255, 95, 53, 1] }, // laranja
		{ index: 0.3, rgb: [252, 169, 116, 1] }, // salmão
		{ index: 0.4, rgb: [252, 204, 155, 1] }, // laranja pastel
		{ index: 0.5, rgb: [253, 254, 153, 1] }, // amarelo
		{ index: 0.6, rgb: [155, 253, 1, 1] }, // verde 1
		{ index: 0.7, rgb: [115, 217, 115, 1] }, // verde 2
		{ index: 0.8, rgb: [50, 205, 49, 1] }, //verde 3
		{ index: 0.9, rgb: [0, 154, 55, 1] }, // verde 4
		{ index: 1.0, rgb: [50, 93, 80, 1] }, // verde 5
		{ index: 1.0, rgb: [50, 93, 92, 1] }, // verde 6
	],

	nshades: 12,
	format: 'hex',
	alpha: 0,
});
const climatologiaAnoColormap = colormap({
	colormap: [
		{ index: 0, rgb: [249, 53, 55, 1] }, // vermelho
		{ index: 0.1, rgb: [255, 95, 53, 1] }, // laranja
		{ index: 0.2, rgb: [252, 169, 116, 1] }, // salmão
		{ index: 0.3, rgb: [252, 204, 155, 1] }, // laranja pastel
		{ index: 0.4, rgb: [253, 254, 153, 1] }, // amarelo
		{ index: 0.5, rgb: [155, 253, 1, 1] }, // verde 1
		{ index: 0.6, rgb: [0, 154, 55, 1] }, // verde 4
		{ index: 0.7, rgb: [50, 93, 92, 1] }, // verde 5
		{ index: 0.8, rgb: [96, 115, 252, 1] }, // Azul
		{ index: 0.9, rgb: [30, 59, 250, 1] }, // Azul
		{ index: 1.0, rgb: [4, 32, 215, 1] }, // 3
		{ index: 1.0, rgb: [3, 23, 150, 1] }, // 2
		{ index: 1.0, rgb: [2, 14, 90, 1] }, // 1
	],

	nshades: 14,
	format: 'hex',
	alpha: 0,
});

const IAPMColormap = colormap({
	colormap: [
		{ index: 0, rgb: [255, 0, 0] }, // vermelho
		{ index: 0.16, rgb: [255, 152, 0] }, // laranja
		{ index: 0.333, rgb: [255, 234, 0] }, // amarelo
		{ index: 0.5, rgb: [153, 255, 0] }, // verde 1
		{ index: 0.666, rgb: [0, 255, 68] }, // verde 2
		{ index: 0.833, rgb: [0, 152, 255] }, //azul
	],

	nshades: 7,
	format: 'hex',
	alpha: 1,
});

const pluviometriaIntervalos1Mes = Object.keys(
	legendaInfoPluvObservadaPeriodo
).map(Number);
pluviometriaIntervalos1Mes.sort((a, b) => a - b);

const pluviometriaIntervalos2MesesAAnual = Object.keys(
	legendaInfoPluvObservada2MesesAAnual
).map(Number);
pluviometriaIntervalos2MesesAAnual.sort((a, b) => a - b);

const desvioMmIntervalos = Object.keys(legendaInfoDesvioMilimetro).map(Number);
desvioMmIntervalos.sort((a, b) => a - b);
const desvioPorcIntervalos = Object.keys(legendaInfoDesvioPorcentagem).map(
	Number
);
desvioPorcIntervalos.sort((a, b) => a - b);

export interface ParametrosVariavel {
	model: 'exponential' | 'gaussian' | 'spherical';
	sigma2: number;
	alpha: number;
	unidade: string;
	colormap: string[];
	valorMin: number;
	valorMax: number;
	intervalos: number[];
	infoAdicional: string[];
}

export type VariavelMeteorologica =
	| 'temperatura'
	| 'umidade'
	| 'precipitacao1Mes'
	| 'precipitacao2MesesAAnual'
	| 'mrai'
	| 'desvioAcumulado'
	| 'desvioMilimetro'
	| 'desvioPorcentagem'
	| 'pressao'
	| 'temperaturaSolo'
	| 'climatologiaMes'
	| 'climatologiaMeses'
	| 'climatologiaAno'
	| 'precipitacao_dias_chuva';

export const variavelParametros = {
	temperatura: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 15,
		valorMax: 38,
		intervalos: [0, 12, 16, 18, 20, 22, 24, 26, 28, 30, 33, 35, 40],
		unidade: '°C',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		}).filter((_e, i) => i !== 1 && i !== 2),
		infoAdicional: [],
	},
	umidade: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 0,
		valorMax: 100,
		intervalos: [10, 20, 30, 40, 50, 60, 70, 80, 90],
		unidade: '%',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		})
			.filter((_e, i) => i > 3 && i !== 7)
			.reverse(),
		infoAdicional: [],
	},
	precipitacao1Mes: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 400,
		valorMin: 9.9,
		intervalos: pluviometriaIntervalos1Mes,
		unidade: 'mm',
		colormap: precipitacaoColormap,
		infoAdicional: [],
	},
	precipitacao: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 400,
		valorMin: 9.9,
		intervalos: pluviometriaIntervalos1Mes,
		unidade: 'mm',
		colormap: precipitacaoColormap,
		infoAdicional: [],
	},
	precipitacao2MesesAAnual: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 1600,
		valorMin: 199,
		intervalos: pluviometriaIntervalos2MesesAAnual,
		unidade: 'mm',
		colormap: precipitacaoColormap,
		infoAdicional: [],
	},
	precipitacao_dias_chuva: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 35,
		valorMin: 0,
		intervalos: [0, 5, 10, 15, 20, 25, 30, 35],
		unidade: 'dias',
		colormap: precipitacaoCoresDiasChuva,
		infoAdicional: [],
	},
	mrai: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 10,
		valorMin: -10,
		intervalos: [-10, -4, -2, 0, 2, 4, 10],
		unidade: '',
		colormap: IAPMColormap,
		infoAdicional: [
			'Extremamente seco',
			'Muito seco',
			'Seco',
			'Úmido',
			'Muito úmido',
			'Extremamente úmido',
		],
	},
	desvioAcumulado: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 100,
		valorMin: -100,
		intervalos: [-100, -50, -20, -10, -5, 20, 30, 50, 100],
		unidade: '%',
		colormap: desvioAcumuladoColormap
			.filter((_e, i) => i >= 8)
			.reverse()
			.filter(
				(_e, i) => ![4, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18].includes(i)
			)
			.slice(4, 13),
		infoAdicional: [],
	},
	desvioMilimetro: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 250,
		valorMin: -150,
		intervalos: desvioMmIntervalos,
		unidade: 'mm',
		colormap: desvioMilimetroColormap,
		infoAdicional: [],
	},
	desvioPorcentagem: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 400,
		valorMin: -100,
		intervalos: desvioPorcIntervalos,
		unidade: '%',
		colormap: desvioPorcentagemColormap,
		infoAdicional: [],
	},
	pressao: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: null,
		valorMax: null,
		intervalos: [900, 912, 924, 936, 948, 961, 973, 985, 997, 1010],
		unidade: 'hPa',
		colormap: cm
			.filter((_e, i) => ![0, 1, 2, 3, 4, 5, 6, 7].includes(i))
			.filter((_e, i) => i % 2 === 0),
		infoAdicional: [],
	},
	temperaturaSolo: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 15,
		valorMax: 38,
		intervalos: [0, 12, 16, 18, 20, 22, 24, 26, 28, 30, 33, 35, 40],
		unidade: '°C',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		}).filter((_e, i) => i !== 1 && i !== 2),
		infoAdicional: [],
	},
	climatologiaMes: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 0,
		valorMax: 350,
		intervalos: [0, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350],
		unidade: 'mm',
		colormap: climatologiaColormap,
		infoAdicional: [],
	},
	climatologiaMeses: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 100,
		valorMax: 1200,
		intervalos: [0, 100, 200, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
		unidade: 'mm',
		colormap: climatologiaMesesColormap,
	},
	climatologiaAno: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 300,
		valorMax: 1900,
		intervalos: [
			300, 400, 500, 600, 700, 800, 900, 1000, 1150, 1300, 1450, 1600, 1750,
			1900,
		],
		unidade: 'mm',
		colormap: climatologiaAnoColormap,
	},
};
