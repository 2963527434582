<div class="d-flex flex-column">
	<label class="text-tertiary text-center fw-semibold mt-3 w-100">
		{{ tituloTabela }}
	</label>

	<div style="max-width: 1300px">
		<div
			class="d-flex my-3 flex-row justify-content-between align-items-end gap-2">
			<seira-card-info [texto]="descricaoRelatorio" />
		</div>

		<div
			*ngIf="carregando; else tabela"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>

		<ng-template #tabela>
			<div>
				<div class="mb-2">
					<seira-public-table
						#tabelaEvapotranspiracao
						id="tabela-evapotranspiracao"
						[hasFooter]="true"
						[hasHeader]="false"
						[info]="true"
						[paging]="true"
						[scrollX]="true"
						[searching]="false"
						[columns]="colunas"
						[data]="dadosTabelaEvapotranspiracao"></seira-public-table>
				</div>
			</div>
		</ng-template>
	</div>
</div>
