import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeloService } from '@home/submodulos/previsao-numerica-tempo/services/selo.service';
import { DadosSelo } from '@home/submodulos/previsao-numerica-tempo/interfaces/selo';
import { timer } from 'rxjs';

interface Params {
	ids?: string;
}

@Component({
	selector: 'seira-selo',
	templateUrl: './selo.component.html',
	styleUrls: ['./selo.component.scss'],
})
export class SeloComponent implements OnInit, OnChanges {
	@Input() ids: number[] = [];
	dadosSelos: DadosSelo[] = [];
	loading = false;
	gerou = false;
	constructor(
		private route: ActivatedRoute,
		private seloService: SeloService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['ids']) {
			this.dadosSelos = [];
			this.updateSelo();
		}
	}

	ngOnInit() {
		this.route.queryParams.subscribe((params: Params) => {
			const ids = params.ids
				? params.ids.split(',').map(el => parseInt(el))
				: [];
			this.ids = ids;
			timer(0, 30000).subscribe({
				next: () => {
					this.updateSelo();
				},
			});
		});
	}

	updateSelo() {
		if (!this.ids.length) {
			return;
		}
		this.loading = true;
		this.seloService.dadosSelo(this.ids).subscribe({
			next: response => {
				if (this.dadosSelos.length === response.length) {
					this.dadosSelos.map((selo, index) => response[index]);
				} else {
					this.dadosSelos = response;
				}
				this.loading = false;
				this.gerou = true;
			},
			error: () => {
				this.loading = false;
			},
		});
	}
}
