import { Component, Input } from '@angular/core';
import {
	DadosRelatorioEstatisticaMensal,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorios-estatistica-mensal',
	templateUrl: './tabela-relatorios-estatistica-mensal.component.html',
	styleUrls: ['./tabela-relatorios-estatistica-mensal.component.scss'],
})
export class TabelaRelatoriosEstatisticaMensalComponent {
	@Input() relatorios: Relatorios<DadosRelatorioEstatisticaMensal>[] = [];

	colunas: ADTColumns[] = [
		{
			data: 'id',
			title: 'Id',
			type: 'number',
			visible: false,
		},
		{
			data: 'mes',
			title: 'Mês',
			type: 'string',
			className: 'text-center',
			orderable: false,
		},
		{
			data: 'chuvaAcumulada',
			title: 'Chuva acumulada (mm)',
			type: 'number',
			className: 'text-center',
			render: precipitacao =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 1)
					: '-',
		},
		{
			data: 'mediaAcumulada',
			title: 'Média histórica (mm)',
			type: 'number',
			className: 'text-center',
			render: precipitacao =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 1)
					: '-',
		},
		{
			data: 'numeroVeranicos',
			title: 'Nº de veranicos',
			type: 'number',
			className: 'text-center',
			render: precipitacao =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 0)
					: '-',
		},
		{
			data: 'diasConsecultivosSemChuva',
			title: 'Nº de dias do maior veranico',
			type: 'number',
			className: 'text-center',
			render: precipitacao =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 0)
					: '-',
		},
		{
			data: 'diaMaiorChuva',
			title: 'Dia da maior chuva',
			type: 'string',
			className: 'text-center',
			orderable: false,
			render: data => data || '-',
		},
		{
			data: 'valorMaiorChuva',
			title: 'Valor da maior chuva (mm)',
			type: 'number',
			className: 'text-center',
			render: precipitacao =>
				isNotNuloOuUndefined(precipitacao)
					? numberToBrNumber(precipitacao, 1)
					: '-',
		},
	];
}
