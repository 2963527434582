<div id="container">
	<svg
		class="circular-progress"
		[attr.width]="radius * 1.1"
		[attr.height]="radius * 1.1"
		[attr.viewBox]="'0 0 ' + radius * 2.2 + ' ' + radius * 2.2">
		<circle class="background" cx="100" cy="100" r="90"></circle>
		<circle
			class="progress"
			cx="100"
			cy="100"
			r="90"
			[style.strokeDasharray]="circumference"
			[style.stroke]="color"
			[style.strokeDashoffset]="offset"></circle>
	</svg>
	<div class="percentage font-size-16 fw-semibold" [style.color]="color">
		{{ percentage | formatDecimal: 2 }}%
	</div>
</div>
