import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { DadosSelo } from '@home/submodulos/previsao-numerica-tempo/interfaces/selo';

@Injectable({
	providedIn: 'root',
})
export class SeloService {
	constructor(private http: HttpClientProtectedService) {}

	dadosSelo(ids: number[]) {
		return this.http.post<number[], DadosSelo[]>(Rotas.SELOS + '/dados', ids);
	}
}
