import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@componentes/page-not-found/page-not-found.component';
import { AuthRequiredGuard } from '@core/guards/auth/auth-required.guard';
import { SeloComponent } from '@home/submodulos/previsao-numerica-tempo/pages/selos-previsao/selo/selo.component';
import { SemPermissaoComponent } from '@modulos/sem-permissao/sem-permissao.component';

const routes: Routes = [
	{
		path: 'home',
		redirectTo: '',
		pathMatch: 'full',
		title: 'SEIRA',
	},
	{
		path: '',
		loadChildren: () =>
			import('./modulos/home/home.module').then(m => m.HomeModule),
	},
	{
		path: 'selo',
		component: SeloComponent,
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./modulos/autenticacao/autenticacao.module').then(
				m => m.AutenticacaoModule
			),
	},
	{
		path: 'administracao',
		loadChildren: () =>
			import('./modulos/administracao/administracao.module').then(
				m => m.AdministracaoModule
			),
		canActivate: [AuthRequiredGuard],
	},
	{
		path: 'meteorologia',
		loadChildren: () =>
			import('./modulos/meteorologia/meteorologia.module').then(
				m => m.MeteorologiaModule
			),
		canActivate: [AuthRequiredGuard],
	},
	{
		path: 'recursos-hidricos',
		loadChildren: () =>
			import('./modulos/recursos-hidricos/recursos-hidricos.module').then(
				m => m.RecursosHidricosModule
			),
		canActivate: [AuthRequiredGuard],
	},
	{
		path: 'sem_permissao',
		pathMatch: 'full',
		component: SemPermissaoComponent,
		title: 'Not found - SEIRA',
	},
	{
		path: '**',
		pathMatch: 'full',
		component: PageNotFoundComponent,
		title: 'Not found - SEIRA',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
