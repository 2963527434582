<div class="row mt-3 mb-5">
	<seira-public-pagination [items]="precipitacaoMensal" [itemsPerPage]="5">
		<ng-template let-item="item" let-index="index">
			<div class="mb-4">
				<label
					for="tabela-precipitacao-mensal"
					class="text-tertiary mb-2 fw-semibold">
					{{ item.municipio }}
				</label>
				<seira-public-table
					id="tabela-precipitacao-mensal"
					[hasFooter]="false"
					[hasHeader]="false"
					[info]="false"
					[paging]="false"
					[scrollX]="true"
					[searching]="false"
					[columns]="colunasPrecipitacaoMensal"
					[data]="item.data" />
				<div class="my-2"></div>
				<seira-public-table
					id="tabela-estatisticas"
					[hasFooter]="false"
					[hasHeader]="false"
					[info]="false"
					[scrollX]="true"
					[paging]="false"
					[searching]="false"
					[columns]="colunasEstatisticas"
					[data]="estatisticas[index].data" />
			</div>
		</ng-template>
	</seira-public-pagination>
</div>
