<div class="row mt-3 mb-5">
	<seira-public-table
		id="tabela-precipitacao-anual"
		[hasFooter]="true"
		[hasHeader]="false"
		[info]="true"
		[paging]="true"
		[searching]="false"
		[columns]="colunas"
		[data]="relatorios?.chuvasAcumuladas || []"></seira-public-table>
</div>
<ng-template let-pk="pk" #templateColunaAcoes>
	<seira-dropdown [options]="generateOptionsDropdown(pk)"></seira-dropdown>
</ng-template>
