<ng-container *seiraIfViewportSize="'desktop'">
	<div class="d-flex align-items-center" style="height: 100%">
		<seira-public-a
			[fragment]="'informacoes-gerais'"
			class="text-primary"
			customClass="fw-light mx-2">
			<i class="ph-question-fill ms-1"></i> Sobre esta página
		</seira-public-a>
	</div>
</ng-container>

<ng-container *seiraIfViewportSize="'mobile'">
	<seira-public-a
		class="text-primary"
		(click)="mostrarInformacoesGerais()"
		customClass="d-flex fw-light mx-2">
		<i class="ph-question-fill ms-1"></i> Sobre esta página
	</seira-public-a>
</ng-container>

<ng-template #modal>
	<div class="modal-body bg-frost text-white p-3">
		<span class="fw-semibold fs-4 text-uppercase">{{
			textosInformativos.label
		}}</span>
		<div
			class="d-flex flex-column mt-2"
			*ngFor="let texto of textosInformativos.texts">
			<span class="fw-semibold mb-1">{{ texto.title }}</span>
			<span style="text-align: justify" [innerHTML]="texto.text"></span>
		</div>
	</div>
</ng-template>
