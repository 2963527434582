import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import {
	DadosEvapotranspiracao,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { format, formatISO } from 'date-fns';
import { Validators } from '@angular/forms';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';

import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';
import { Select } from '@layout/interfaces/select';
import { corrigeDuplicacaoNome } from '@home/submodulos/dados-meteorologicos/utils';

@Component({
	selector: 'seira-evapotranspiracao-tabela',
	templateUrl: './evapotranspiracao-tabela.component.html',
	styleUrls: ['./evapotranspiracao-tabela.component.scss'],
})
export class EvapotranspiracaoTabelaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	carregando = false;
	tituloTabela = '';
	periodoAgrupamento = '';
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaEvapotranspiracao: DadosEvapotranspiracao[] = [];
	municipioPostos: Select<string>[];

	descricaoRelatorio =
		'Define-se como evapotranspiração a quantidade total de água transferida para a atmosfera, resultado da soma da evaporação do solo e da transpiração das plantas de uma determinada área.';

	@ViewChild('tabelaEvapotranspiracao', { static: false })
	tabela?: PublicTableComponent;

	colunas: ADTColumns[] = [
		{
			data: 'nomeEstacao',
			title: 'Município/Posto ',
			type: 'string',
			className: 'text-left',
		},
		{
			data: 'dataColeta',
			title: 'Data',
			type: 'string',
			className: 'text-center',
		},
		{
			data: 'evapotranspiracaoPotencial',
			title: 'Evapotranspiração potencial (mm)',
			type: 'number',
			className: 'text-center',
		},
	];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.colunas,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		const todosMunicipiosPostos = this.inputs.municipioPostos;
		this.municipioPostos = [
			{ value: '0', name: 'Estado completo' },
			...todosMunicipiosPostos,
		];

		this.setValidators();
	}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValue(this.municipioPostos[0].value);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}

	gerarRelatorio() {
		let postoIds = [];
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		this.periodoAgrupamento = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		const estacao = this.getFormItemValue(FormularioRelatorio.ESTACAO);

		if (estacao == 0) {
			postoIds = this.inputs.postos.map(posto => posto.id);
		} else {
			postoIds = [estacao];
		}

		this.tituloTabela = `Evapotranspiração - ${DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			this.periodoAgrupamento
		)}`;

		this.carregando = true;
		this.inputs.setLoading(true);

		this.relatoriosService
			.buscarRelatorioEvapotranspiracao(
				postoIds,
				formatISO(new Date(dataInicio)),
				formatISO(new Date(dataFim.setHours(dataFim.getHours() - 3)))
			)
			.subscribe({
				next: evapotranspiracao => {
					this.dadosTabelaEvapotranspiracao =
						this.padronizarResultado(evapotranspiracao);
					this.relatoriosService.verificaExistenciaDados(evapotranspiracao);
				},
				error: err => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	padronizarResultado(
		relatorios: Relatorios<DadosEvapotranspiracao>[]
	): DadosEvapotranspiracao[] {
		const resultado: DadosEvapotranspiracao[] = [];

		if (this.periodoAgrupamento === 'diario') {
			relatorios.forEach(relatorio => {
				relatorio.data.forEach(dado =>
					resultado.push({
						nomeEstacao: corrigeDuplicacaoNome(
							`${relatorio.municipio}/${dado.nomeEstacao}`
						),
						dataColeta: format(new Date(dado.dataColeta), 'dd/MM/yyyy'),
						evapotranspiracaoPotencial: isNotNuloOuUndefined(
							dado.evapotranspiracaoPotencial
						)
							? numberToBrNumber(dado.evapotranspiracaoPotencial, 3)
							: '-',
					})
				);
			});

			return resultado;
		}

		const agrupado = new Map<
			string,
			{ somaEvapotranspiracao: number; count: number }
		>();

		relatorios.forEach(relatorio => {
			relatorio.data.forEach(dado => {
				const data = new Date(dado.dataColeta);
				let chaveAgrupamento = '';

				if (this.periodoAgrupamento === 'mensal') {
					chaveAgrupamento = format(data, 'MM/yyyy');
				} else if (this.periodoAgrupamento === 'anual') {
					chaveAgrupamento = format(data, 'yyyy');
				}

				const chave = `${relatorio.municipio}/${dado.nomeEstacao}-${chaveAgrupamento}`;

				if (!agrupado.has(chave)) {
					agrupado.set(chave, { somaEvapotranspiracao: 0, count: 0 });
				}

				const valorAtual = agrupado.get(chave)!;
				if (isNotNuloOuUndefined(dado.evapotranspiracaoPotencial)) {
					valorAtual.somaEvapotranspiracao += dado.evapotranspiracaoPotencial;
				}
				valorAtual.count += 1;
			});
		});

		agrupado.forEach((valor, chave) => {
			const [nomeEstacao, dataColeta] = chave.split('-');
			resultado.push({
				nomeEstacao: corrigeDuplicacaoNome(nomeEstacao),
				dataColeta,
				evapotranspiracaoPotencial:
					valor.somaEvapotranspiracao > 0
						? numberToBrNumber(valor.somaEvapotranspiracao, 3)
						: '-',
			});
		});

		return resultado;
	}
}
