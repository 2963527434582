import { AfterContentInit, Component, Input } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BreakpointObserver } from '@angular/cdk/layout';

export interface GroupButton {
	label: string;
	icon: string;
	size: 'big' | 'small' | 'medium';
	onClick: Function;
}

export type GroupButtonMode = 'full' | 'dropdown';

const iphone14ProWidth = '418px';

@Component({
	selector: 'seira-public-button-group',
	templateUrl: './public-button-group.component.html',
	styleUrls: ['./public-button-group.component.scss'],
	providers: [
		{
			provide: BsDropdownConfig,
			useValue: { isAnimated: true, autoClose: true },
		},
	],
})
export class PublicButtonGroupComponent implements AfterContentInit {
	@Input() buttons!: GroupButton[];
	@Input() mode: GroupButtonMode = 'full';
	@Input() disabledButtons = false;
	@Input() dropdownContainer?: string;
	private isTelaPequena = false;

	constructor(private readonly bpObserver: BreakpointObserver) {}

	ngAfterContentInit(): void {
		this.bpObserver
			.observe([`(max-width: ${iphone14ProWidth})`])
			.subscribe(res => {
				this.isTelaPequena = res.matches;
			});
	}

	getIconSize(button: GroupButton): string {
		if (this.isTelaPequena) {
			return '18px';
		}
		if (button.size === 'big') {
			return '38px';
		}
		if (button.size === 'medium') {
			return '30px';
		}
		return '24px';
	}

	getButtonClasses(button: GroupButton): string {
		const classes: string[] = [];
		if (this.isTelaPequena) {
			classes.push('py-2 px-2 font-small');
		} else {
			classes.push('gap-1');
			if (button.size === 'big') {
				classes.push('flex-column vstack py-3 px-4');
			} else if (button.size === 'medium') {
				classes.push('py-1rem px-3');
			} else {
				classes.push('py-2 px-3');
			}
		}
		return classes.join(' ');
	}
}
