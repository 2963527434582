<label class="text-center text-tertiary fw-semibold w-100">
	Desvio por período ({{ tipoDesvio === 'porcentagem' ? '%' : 'mm' }}) -
	{{ periodoTitulo }}
</label>
<seira-card-info aditionalClasses="mt-3 mb-3" [texto]="descricaoRelatorio" />

<div style="text-align: right">
	<span
		triggers="mouseenter:mouseleave"
		style="cursor: pointer"
		[popover]="dica"
		placement="bottom right"
		containerClass="custom-popover"
		container="body"
		class="text-primary"
		customClass="fw-light mx-2">
		<i class="ph-lightbulb-fill ms-1"></i>
		Dica
	</span>
</div>

<div
	*ngIf="loading"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>

<div *ngIf="gerou && !loading">
	<seira-high-stock
		*ngIf="chartOptions"
		(chartInstance)="setChart($event)"
		classChart="highcharts-light h-auto"
		styleChart="width: 100%; height: 550px; display: block;"
		[options]="chartOptions" />
</div>

<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>

<ng-template #dica>
	<div class="row p-2">
		<span class="col fw-semibold texto-dica">
			Clique nas opções da legenda para obter dados mais específicos.
		</span>
	</div>
</ng-template>
