import {
	FormularioRelatorio,
	PeriodosLabel,
} from './../../../interfaces/tipos-relatorios';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { format, formatISO } from 'date-fns';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DadosValoresExtremosResponse } from '../../../interfaces/valores-extremos';
import { DateTimeUtils } from '@utils/datetime-util';
import { numberToBrNumber } from '@utils';
import { Agrupamento } from '../../../interfaces';
import { Select } from '@layout/interfaces/select';
import { Subject, takeUntil } from 'rxjs';
import { corrigeDuplicacaoNome } from '@home/submodulos/dados-meteorologicos/utils';

@Component({
	selector: 'seira-valores-extremos-tabela',
	templateUrl: './valores-extremos-tabela.component.html',
	styleUrls: ['./valores-extremos-tabela.component.scss'],
})
export class ValoresExtremosTabelaComponent
	implements OnDestroy, InstanciaRelatorio, OnInit
{
	carregando = false;
	tituloTabela = '';
	agrupamentoTabela = '';
	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	dadosTabelaValoresExtremos: DadosValoresExtremosResponse[] = [];
	municipioPostos: Select<string>[];

	descricaoRelatorio =
		'Os valores extremos se referem aos valores máximos e mínimos de chuva, temperatura, umidade, pressão, radiação, evapotranspiração, velocidade do vento a 2 metros e velocidade do vento a 10 metros registrados em um determinado período, local ou região.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.dadosTabelaValoresExtremos);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaValoresExtremos);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT(this.dadosTabelaValoresExtremos);
			},
		},
	];

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		const todosMunicipiosPostos = this.inputs.municipioPostos;
		this.municipioPostos = [
			{ value: '0', name: 'Estado completo' },
			...todosMunicipiosPostos,
		];
	}

	ngOnInit() {
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValue(this.municipioPostos[0].value);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal_parcial', { emitEvent: false });

		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setDatasByPeriodoBusca(
			this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValue('mensal', { emitEvent: false });
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setDatasByPeriodoBusca(periodoBuscaValue: PeriodosLabel | null) {
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);

		if (dataInicio && dataFim) {
			switch (periodoBuscaValue) {
				case 'mensal_completo':
					const mesInput = moment(
						this.getFormItemValue(FormularioRelatorio.PERIODO)
					);
					const mesVigente = moment().startOf('month');
					if (mesInput.isSameOrAfter(mesVigente)) {
						const newDataFim = mesVigente.subtract(1, 'month').endOf('month');
						this.inputs.form
							.get(FormularioRelatorio.PERIODO)
							?.setValue(newDataFim);
					}
					break;
				case 'anual_completo':
					const anoInput = moment(
						this.getFormItemValue(FormularioRelatorio.PERIODO)
					);
					const anoVigente = moment().startOf('year');
					if (anoInput.isSameOrAfter(anoVigente)) {
						const newDataFim = anoVigente.subtract(1, 'year').endOf('year');
						this.inputs.form
							.get(FormularioRelatorio.PERIODO)
							?.setValue(newDataFim);
					}
					break;
			}
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: (periodo: PeriodosLabel | null) => {
					this.setDatasByPeriodoBusca(periodo);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)?.value;
	}

	public gerarRelatorio() {
		const posto = this.getFormItemValue(FormularioRelatorio.ESTACAO);
		const dataInicio = this.getFormItemValue(FormularioRelatorio.DATA_INICIO);
		const dataFim = this.getFormItemValue(FormularioRelatorio.DATA_FIM);
		const periodo = this.getFormItemValue(FormularioRelatorio.PERIODO);
		this.agrupamentoTabela = this.getFormItemValue(
			FormularioRelatorio.AGRUPAMENTO
		);
		const periodoBusca = this.getFormItemValue(
			FormularioRelatorio.PERIODO_BUSCA
		);

		this.tituloTabela = `Valores extremos - ${this.getPeriodo()}`;

		this.inputs.setLoading(true);
		this.carregando = true;

		const inicioParaEnviar =
			periodoBusca === 'mensal_completo' || periodoBusca === 'anual_completo'
				? periodo
				: dataInicio;

		const fimParaEnviar =
			periodoBusca === 'mensal_completo' || periodoBusca === 'anual_completo'
				? periodo
				: dataFim;

		this.relatoriosService
			.buscarRelatorioValoresExtremos({
				diaInicio: formatISO(new Date(inicioParaEnviar)),
				diaFim: formatISO(new Date(fimParaEnviar)),
				agrupamento: this.agrupamentoTabela,
				tipoPeriodoValoresExtremos: String(periodoBusca).toUpperCase(),
				posto,
			})
			.subscribe({
				next: valoresExtremos => {
					this.dadosTabelaValoresExtremos = valoresExtremos;
					this.relatoriosService.verificaExistenciaDados(valoresExtremos);
				},
				error: () => {
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	getPeriodo() {
		const tipoPeriodoValoresExtremos = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value;
		let dataInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		let dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO)?.value;

		if (moment.isMoment(dataInicio)) {
			dataInicio = dataInicio.toDate();
		}
		if (moment.isMoment(dataFim)) {
			dataFim = dataFim.toDate();
		}

		switch (tipoPeriodoValoresExtremos.toUpperCase()) {
			case 'MENSAL_COMPLETO':
				return DateTimeUtils.formatarDataPeriodo(periodo, '', 'mensal', true);
			case 'ANUAL_COMPLETO':
				return DateTimeUtils.formatarDataPeriodo(periodo, '', 'anual', true);
			case 'MENSAL_PARCIAL':
				return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim, 'diario');
			case 'ANUAL_PARCIAL':
				return DateTimeUtils.formatarDataPeriodo(dataInicio, dataFim, 'diario');
			default:
				return DateTimeUtils.formatarDataPeriodo(periodo, '', 'anual', true);
		}
	}

	formatarData(data: string) {
		return DateTimeUtils.formatarData(data, 'dd/MM/yyyy') || '-';
	}

	formatarNumero(numero: number) {
		return numberToBrNumber(numero, 1) || '-';
	}

	getAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		return Agrupamento[agrupamento as keyof typeof Agrupamento] ?? '-';
	}

	async exportarPDF(
		dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions();
		documentDefinition.pageOrientation = 'landscape';
		documentDefinition.pageSize = 'C2';
		documentDefinition.pageMargins = [20, 90, 20, 40];

		documentDefinition.content.push({
			text: `Valores Extremos - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaValoresExtremos.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		const tableData = [
			[
				{
					text: this.getAgrupamento(),
					style: 'tableHeader',
					rowSpan: 2,
					alignment: 'center',
				},
				{
					text: 'Precipitação (mm)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Temperatura máxima do ar (oC)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Umidade relativa do ar (%)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Pressão Atmosférica (hPa)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Radiação solar (W/m2)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Evapotranspiração (mm)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Velocidade do vento a 2 metros (m/s)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
				{
					text: 'Velocidade do vento a 10 metros (m/s)',
					style: 'tableHeader',
					colSpan: 4,
					alignment: 'center',
				},
				{},
				{},
				{},
			],
			[
				'',
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Max', style: 'tableHeader', alignment: 'center' },
				{ text: 'Data', style: 'tableHeader', alignment: 'center' },
				{ text: 'Min', style: 'tableHeader', alignment: 'center' },
			],
		];

		dadosTabelaValoresExtremos.forEach(dados => {
			tableData.push([
				this.agrupamentoTabela === 'MUNICIPIO_POSTO'
					? corrigeDuplicacaoNome(dados.nome)
					: dados.nome,
				this.formatarData(dados.dataColetaMaxPrecipitacao),
				this.formatarNumero(dados.maxPrecipitacao),
				this.formatarData(dados.dataColetaMinPrecipitacao),
				this.formatarNumero(dados.minPrecipitacao),
				this.formatarData(dados.dataColetaMaxTemperaturaAr),
				this.formatarNumero(dados.maxTemperaturaAr),
				this.formatarData(dados.dataColetaMinTemperaturaAr),
				this.formatarNumero(dados.minTemperaturaAr),
				this.formatarData(dados.dataColetaMaxUmidadeRelativa),
				this.formatarNumero(dados.maxUmidadeRelativa),
				this.formatarData(dados.dataColetaMinUmidadeRelativa),
				this.formatarNumero(dados.minUmidadeRelativa),
				this.formatarData(dados.dataColetaMaxPressaoAtmosferica),
				this.formatarNumero(dados.maxPressaoAtmosferica),
				this.formatarData(dados.dataColetaMinPressaoAtmosferica),
				this.formatarNumero(dados.minPressaoAtmosferica),
				this.formatarData(dados.dataColetaMaxRadiacao),
				this.formatarNumero(dados.maxRadiacao),
				this.formatarData(dados.dataColetaMinRadiacao),
				this.formatarNumero(dados.minRadiacao),
				this.formatarData(dados.dataColetaMaxEvapotranspiracao),
				this.formatarNumero(dados.maxEvapotranspiracao),
				this.formatarData(dados.dataColetaMinEvapotranspiracao),
				this.formatarNumero(dados.minEvapotranspiracao),
				this.formatarData(dados.dataColetaMaxVelocidadeVento2m),
				this.formatarNumero(dados.maxVelocidadeVento2m),
				this.formatarData(dados.dataColetaMinVelocidadeVento2m),
				this.formatarNumero(dados.minVelocidadeVento2m),
				this.formatarData(dados.dataColetaMaxVelocidadeVento10m),
				this.formatarNumero(dados.maxVelocidadeVento10m),
				this.formatarData(dados.dataColetaMinVelocidadeVento10m),
				this.formatarNumero(dados.minVelocidadeVento10m),
			]);
		});

		documentDefinition.content.push({
			table: {
				headerRows: 2,
				body: tableData,
				layout: 'lightHorizontalLines',
				fontSize: 8,
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]) {
		const tableData = [];

		tableData.push([
			'',
			'Precipitação (mm)',
			'',
			'',
			'',
			'Temperatura máxima do ar (oC)',
			'',
			'',
			'',
			'Umidade relativa do ar (%)',
			'',
			'',
			'',
			'Pressão Atmosférica (hPa)',
			'',
			'',
			'',
			'Radiação solar (W/m2)',
			'',
			'',
			'',
			'Evapotranspiração (mm)',
			'',
			'',
			'',
			'Velocidade do vento a 2 metros (m/s)',
			'',
			'',
			'',
			'Velocidade do vento a 10 metros (m/s)',
			'',
			'',
			'',
		]);

		tableData.push([
			this.getAgrupamento(),
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
			'Data',
			'Max',
			'Data',
			'Min',
		]);

		dadosTabelaValoresExtremos.forEach(dados => {
			tableData.push([
				this.agrupamentoTabela === 'MUNICIPIO_POSTO'
					? corrigeDuplicacaoNome(dados.nome)
					: dados.nome,
				this.formatarData(dados.dataColetaMaxPrecipitacao),
				this.formatarNumero(dados.maxPrecipitacao),
				this.formatarData(dados.dataColetaMinPrecipitacao),
				this.formatarNumero(dados.minPrecipitacao),
				this.formatarData(dados.dataColetaMaxTemperaturaAr),
				this.formatarNumero(dados.maxTemperaturaAr),
				this.formatarData(dados.dataColetaMinTemperaturaAr),
				this.formatarNumero(dados.minTemperaturaAr),
				this.formatarData(dados.dataColetaMaxUmidadeRelativa),
				this.formatarNumero(dados.maxUmidadeRelativa),
				this.formatarData(dados.dataColetaMinUmidadeRelativa),
				this.formatarNumero(dados.minUmidadeRelativa),
				this.formatarData(dados.dataColetaMaxPressaoAtmosferica),
				this.formatarNumero(dados.maxPressaoAtmosferica),
				this.formatarData(dados.dataColetaMinPressaoAtmosferica),
				this.formatarNumero(dados.minPressaoAtmosferica),
				this.formatarData(dados.dataColetaMaxRadiacao),
				this.formatarNumero(dados.maxRadiacao),
				this.formatarData(dados.dataColetaMinRadiacao),
				this.formatarNumero(dados.minRadiacao),
				this.formatarData(dados.dataColetaMaxEvapotranspiracao),
				this.formatarNumero(dados.maxEvapotranspiracao),
				this.formatarData(dados.dataColetaMinEvapotranspiracao),
				this.formatarNumero(dados.minEvapotranspiracao),
				this.formatarData(dados.dataColetaMaxVelocidadeVento2m),
				this.formatarNumero(dados.maxVelocidadeVento2m),
				this.formatarData(dados.dataColetaMinVelocidadeVento2m),
				this.formatarNumero(dados.minVelocidadeVento2m),
				this.formatarData(dados.dataColetaMaxVelocidadeVento10m),
				this.formatarNumero(dados.maxVelocidadeVento10m),
				this.formatarData(dados.dataColetaMinVelocidadeVento10m),
				this.formatarNumero(dados.minVelocidadeVento10m),
			]);
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-valores-extremos-${this.getPeriodo()}`
		);
	}

	exportarTXT(dadosTabelaValoresExtremos: DadosValoresExtremosResponse[]) {
		let txtData = '';

		dadosTabelaValoresExtremos.forEach(dados => {
			txtData +=
				`${this.getAgrupamento()}: ${
					this.agrupamentoTabela === 'MUNICIPIO_POSTO'
						? corrigeDuplicacaoNome(dados.nome)
						: dados.nome
				}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxPrecipitacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima precipitação (mm): ${this.formatarNumero(
					dados.maxPrecipitacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinPrecipitacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				` Mínima precipitação (mm): ${this.formatarNumero(
					dados.minPrecipitacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxTemperaturaAr),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima Temperatura do ar (oC): ${this.formatarNumero(
					dados.maxTemperaturaAr
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinTemperaturaAr),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima Temperatura do ar (oC): ${this.formatarNumero(
					dados.minTemperaturaAr
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxUmidadeRelativa),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima umidade relativa do ar (%): ${this.formatarNumero(
					dados.maxUmidadeRelativa
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinUmidadeRelativa),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima umidade relativa do ar (%): ${this.formatarNumero(
					dados.minUmidadeRelativa
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxPressaoAtmosferica),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima pressão atmosférica (hPa): ${this.formatarNumero(
					dados.maxPressaoAtmosferica
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinPressaoAtmosferica),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima pressão atmosférica (hPa): ${this.formatarNumero(
					dados.minPressaoAtmosferica
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxRadiacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima radiação solar (W/m2): ${this.formatarNumero(
					dados.maxRadiacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinRadiacao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima radiação solar (W/m2): ${this.formatarNumero(
					dados.minRadiacao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxEvapotranspiracao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima evapotranspiração (mm): ${this.formatarNumero(
					dados.maxEvapotranspiracao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinEvapotranspiracao),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima evapotranspiração (mm): ${this.formatarNumero(
					dados.minEvapotranspiracao
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxVelocidadeVento2m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima velocidade do vento a 2 metros (m/s): ${this.formatarNumero(
					dados.maxVelocidadeVento2m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinVelocidadeVento2m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima velocidade do vento a 2 metros (m/s): ${this.formatarNumero(
					dados.minVelocidadeVento2m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMaxVelocidadeVento10m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Máxima velocidade do vento a 10 metros (m/s): ${this.formatarNumero(
					dados.maxVelocidadeVento10m
				)}\n` +
				`Data: ${format(
					new Date(dados.dataColetaMinVelocidadeVento10m),
					'dd/MM/yyyy HH:mm'
				)}\n` +
				`Mínima velocidade do vento a 10 metros (m/s): ${this.formatarNumero(
					dados.minVelocidadeVento10m
				)}\n\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-valores-extremos-${this.getPeriodo()}`
		);
	}
}
