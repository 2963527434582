import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DadosAgrupamento } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import * as Highcharts from 'highcharts/highstock';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { RelatorioNDCResponse } from './../../../interfaces/relatorio-ndc';
import { options } from './chartOptions';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../../utils';
import { DateTimeUtils } from '@utils/datetime-util';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	criarImagemBase64FromChart,
	enumAsSelectOptions,
	numberToBrNumberTables,
	setAgrupamentoEspecial,
} from '@utils';
import { MesesNaoAbreviados } from '../../../enum';
import { Select } from '@layout/interfaces/select';
import format from 'date-fns/format';
import { AgrupamentoEspecial } from '../../../interfaces';
const labelColunaPrecipitacao = 'Precipitação total (mm)';
const labelColunaNDC = 'Número de dias com chuva';
@Component({
	selector: 'seira-grafico-ndc',
	templateUrl: './grafico-ndc.component.html',
	styleUrls: ['./grafico-ndc.component.scss'],
})
export class GraficoNdcComponent implements OnInit, OnDestroy {
	chart!: Highcharts.Chart;
	chartOptions: Highcharts.Options = {
		...options,
		legend: {
			enabled: true,
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			itemStyle: {
				fontWeight: 'normal',
				fontSize: '12px',
			},
		},
	};

	inputs = inject(INPUTS_RELATORIOS);
	_destroyed = new Subject();

	tituloEixoX = '';
	resultados: RelatorioNDCResponse[] = [];
	descricaoRelatorio =
		'O NDC, Número de Dias com Chuva, representa o número de dias em que a pluviometria diária, acumulada em 24 horas, foi igual ou superior a 0,1 mm.';

	periodoTitulo = '';
	loading = true;

	tituloAgrupamento = '';
	agrupamento: Select[] | any = [];

	tipoPDF = false;
	microrregioes: Select<string>[];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	get _agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}

	constructor(
		private relatoriosService: RelatoriosService,
		private toastrService: ToastrService
	) {
		this.agrupamento = enumAsSelectOptions(AgrupamentoEspecial);
		const todasMicrorregioes = this.inputs.microrregioes;
		this.microrregioes = [
			{ value: '0', name: 'Estado completo' },
			...todasMicrorregioes,
		];
	}

	ngOnInit(): void {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValue(this.agrupamento[0].value);
		this.inputs.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValue(this.microrregioes[0].value);
		this.setValidatorsByAgrupamento(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidatorsByAgrupamento(agrupamentoValue: string) {
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.MUNICIPIO);
		this.handleRemoveValidatorsFromFormControl(FormularioRelatorio.ESTACAO);
		this.handleRemoveValidatorsFromFormControl(
			FormularioRelatorio.MICRORREGIAO
		);

		switch (agrupamentoValue) {
			case 'MUNICIPIO_POSTO':
				this.inputs.form
					?.get(FormularioRelatorio.ESTACAO)
					?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				this.inputs.form
					?.get(FormularioRelatorio.MICRORREGIAO)
					?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				this.inputs.form
					?.get(FormularioRelatorio.MUNICIPIO)
					?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: value => {
					this.setValidatorsByAgrupamento(value);
				},
			});

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
		this.atualizarTitulosChart();
		this.configurarChart(this.resultados);
	}

	getDataFromForm(data: FormularioRelatorio) {
		return this.inputs.form.get(data);
	}

	handleRemoveValidatorsFromFormControl(data: FormularioRelatorio) {
		this.inputs.form.get(data)?.clearValidators();
		this.inputs.form.get(data)?.updateValueAndValidity({ emitEvent: false });
	}

	gerarRelatorio() {
		this.limparChart();

		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			diaInicio,
			diaFim,
			periodoAgrupamento
		);

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
			isRetornoAgrupado: true,
		};

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.getDataFromForm(FormularioRelatorio.ESTACAO)?.value;
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = this.getDataFromForm(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				break;
			case 'MUNICIPIO':
				dados.municipio = this.getDataFromForm(FormularioRelatorio.MUNICIPIO)
					?.value;
				break;
		}

		this.relatoriosService.buscarRelatorioNDCPorAgrupamento(dados).subscribe({
			next: ndcAgrupados => {
				this.relatoriosService.verificaExistenciaDados(ndcAgrupados);
				this.resultados = ndcAgrupados;

				this.chartOptions = {
					...this.chartOptions,
					exporting: {
						enabled: true,
						buttons: {
							contextButton: {
								menuItems: [
									'viewFullscreen',
									'separator',
									'downloadPNG',
									'downloadJPEG',
								],
							},
						},
						filename: gerarFilenameGrafico(
							`grafico_numero_dias_com_chuva_${this.inputs.form
								.get(FormularioRelatorio.AGRUPAMENTO)
								?.value.toLocaleLowerCase()}_${periodoAgrupamento.toLocaleLowerCase()}`
						),
					},
				};
			},
			error: () => {
				this.toastrService.error(
					'Erro ao gerar gráfico de NDC, tente novamente'
				);
				this.loading = false;
				this.inputs.setLoading(false);
				this.configurarChart([]);
			},
			complete: () => {
				this.loading = false;
				this.inputs.setLoading(false);
			},
		});
	}

	limparChart() {
		if (this.chart && this.chart.series.length > 0) {
			while (this.chart.series.length > 0) {
				this.chart.series[0].remove(true);
			}
		}
	}

	extrairNomes(preciptacoesAgrupadas: RelatorioNDCResponse[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome).sort())];
	}

	configurarChart(resultados: RelatorioNDCResponse[]) {
		const diaInicio = this.getDataFromForm(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.getDataFromForm(FormularioRelatorio.DATA_FIM)?.value;
		let series = [];
		let categorias: string[] = [];
		const tipoPeriodo = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		categorias = [
			...new Set(
				resultados.map(resultado =>
					tipoPeriodo === 'anual'
						? resultado.ano?.toString() || ''
						: tipoPeriodo === 'mensal'
						? `${String(resultado.mes?.toString().padStart(2, '0'))}/${
								resultado.ano || ''
						  }`
						: `${format(diaInicio, 'dd/MM/yyyy')} a ${format(
								diaFim,
								'dd/MM/yyyy'
						  )}`
				)
			),
		];

		const nomes = this.extrairNomes(resultados);
		series = nomes.map(nome => ({
			type: 'column' as const,
			name: corrigeDuplicacaoNome(nome),
			data: categorias.map(categoria => {
				const ponto = resultados.find(r => {
					const categoriaAtual =
						tipoPeriodo === 'anual'
							? r.ano?.toString() || ''
							: tipoPeriodo === 'mensal'
							? `${String(r.mes?.toString().padStart(2, '0'))}/${r.ano || ''}`
							: `${format(diaInicio, 'dd/MM/yyyy')} a ${format(
									diaFim,
									'dd/MM/yyyy'
							  )}`;

					return r.nome === nome && categoriaAtual === categoria;
				});
				return ponto
					? {
							name: corrigeDuplicacaoNome(ponto.nome),
							y: ponto.diasComChuva,
							precipitacao:
								ponto.somaPrecipitacao?.toFixed(1).replace('.', ',') || '0',
					  }
					: { y: null, precipitacao: '0' };
			}),
		}));

		this.chart?.xAxis[0].setCategories(categorias);

		if (this.chart) {
			if (this._agrupamento == 'REGIAO_PLUVIOMETRICA') {
				this.chart.update({
					colors: [
						'#FFFF00',
						'#00008B',
						'#90EE90',
						'#FFA500',
						'#FF0000',
						'#006400',
						'#5DADE2',
					],
				});
			} else {
				this.chart.update({
					colors: undefined,
				});
			}
		}

		this.chart?.update({
			tooltip: {
				pointFormat:
					'Dias com chuva: <b>{point.y} dias</b><br>' +
					'Precipitação total: <b>{point.precipitacao} mm</b><br>',
			},
		});

		while (this.chart?.series.length) {
			this.chart.series[0].remove(false);
		}

		series.forEach(serie => {
			this.chart?.addSeries(serie as Highcharts.SeriesOptionsType, false);
		});

		this.chart?.redraw();
	}

	atualizarTitulosChart() {
		const agrupamento = this.getDataFromForm(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const tipoPeriodo = this.getDataFromForm(FormularioRelatorio.PERIODO_BUSCA)
			?.value;

		if (tipoPeriodo === 'mensal') {
			this.tituloEixoX = 'Meses';
		} else if (tipoPeriodo === 'anual') {
			this.tituloEixoX = 'Anos';
		} else {
			switch (agrupamento) {
				case 'MUNICIPIO_POSTO':
					this.tituloEixoX = 'Município/Posto';
					break;
				case 'MICRORREGIAO':
					this.tituloEixoX = 'Microrregião';
					break;
				case 'MUNICIPIO':
					this.tituloEixoX = 'Município';
					break;
				case 'MESORREGIAO':
					this.tituloEixoX = 'Mesorregiões';
					break;
				case 'REGIAO_PLUVIOMETRICA':
					this.tituloEixoX = 'Regiões Pluviométricas';
					break;
				case 'BACIA_SUBBACIA':
					this.tituloEixoX = 'Bacias e Sub-bacias';
					break;
			}
		}

		this.chart.xAxis[0].setTitle({ text: this.tituloEixoX });
	}

	getXLSXHeaders() {
		let headers = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		this.tituloAgrupamento = setAgrupamentoEspecial(agrupamento);
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		periodoAgrupamento != 'diario'
			? periodoAgrupamento === 'mensal'
				? (headers = [
						{ label: 'nome', text: this.tituloAgrupamento, size: 24 },
						{ label: 'mes', text: 'Mês', size: 24 },
						{ label: 'diasComChuva', text: 'Dias com chuva', size: 24 },
						{
							label: 'somaPrecipitacao',
							text: 'Soma da precipitação (mm)',
							size: 24,
						},
				  ])
				: (headers = [
						{ label: 'nome', text: this.tituloAgrupamento, size: 24 },
						{ label: 'ano', text: 'Ano', size: 24 },
						{ label: 'diasComChuva', text: 'Dias com chuva', size: 24 },
						{
							label: 'somaPrecipitacao',
							text: 'Soma da precipitação (mm)',
							size: 24,
						},
				  ])
			: (headers = [
					{ label: 'nome', text: this.tituloAgrupamento, size: 24 },
					{ label: 'diasComChuva', text: 'Dias com chuva', size: 24 },
					{
						label: 'somaPrecipitacao',
						text: 'Soma da precipitação',
						size: 24,
					},
			  ]);

		return headers;
	}

	private getTableData() {
		const dadosTabela: Array<any[]> = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		this.tituloAgrupamento = setAgrupamentoEspecial(agrupamento);
		const cabecalho: any[] = [];
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (this.tipoPDF) {
			if (periodoAgrupamento !== 'diario') {
				cabecalho.push(
					{ text: this.tituloAgrupamento, fillColor: '#DCDCDC' },
					{
						text: periodoAgrupamento === 'mensal' ? 'Mês' : 'Ano',
						fillColor: '#DCDCDC',
					},
					{ text: labelColunaNDC, fillColor: '#DCDCDC' },
					{ text: labelColunaPrecipitacao, fillColor: '#DCDCDC' }
				);
			} else {
				cabecalho.push(
					{ text: this.tituloAgrupamento, fillColor: '#DCDCDC' },
					{ text: labelColunaNDC, fillColor: '#DCDCDC' },
					{ text: labelColunaPrecipitacao, fillColor: '#DCDCDC' }
				);
			}
		} else {
			if (periodoAgrupamento !== 'diario') {
				cabecalho.push(
					this.tituloAgrupamento,
					periodoAgrupamento === 'mensal' ? 'Mês' : 'Ano',
					labelColunaNDC,
					labelColunaPrecipitacao
				);
			} else {
				cabecalho.push(
					this.tituloAgrupamento,
					labelColunaNDC,
					labelColunaPrecipitacao
				);
			}
		}

		dadosTabela.push(cabecalho);
		let valores: any[];

		if (periodoAgrupamento === 'diario') {
			valores = this.resultados?.map(value => [
				value.nome && corrigeDuplicacaoNome(value.nome),
				value.diasComChuva,
				numberToBrNumberTables(value.somaPrecipitacao, 1),
			]);
		} else {
			valores = this.resultados?.map(value => [
				value.nome && corrigeDuplicacaoNome(value.nome),
				value.mes ? MesesNaoAbreviados[value.mes] : value.ano,
				value.diasComChuva,
				numberToBrNumberTables(value.somaPrecipitacao, 1),
			]);
		}

		dadosTabela.push(...valores);
		return dadosTabela;
	}

	retornarNdcParaXLSX() {
		const tableData: any[] = [];
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		this.tituloAgrupamento = setAgrupamentoEspecial(agrupamento);
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		periodoAgrupamento != 'diario'
			? periodoAgrupamento === 'mensal'
				? this.resultados.map(ndc => {
						tableData.push({
							nome: ndc.nome && corrigeDuplicacaoNome(ndc.nome),
							mes: MesesNaoAbreviados[Number(ndc.mes)],
							diasComChuva: ndc.diasComChuva,
							somaPrecipitacao: numberToBrNumberTables(ndc.somaPrecipitacao, 1),
						});
				  })
				: this.resultados.map(ndc => {
						tableData.push({
							nome: ndc.nome && corrigeDuplicacaoNome(ndc.nome),
							ano: ndc.ano,
							diasComChuva: ndc.diasComChuva,
							somaPrecipitacao: numberToBrNumberTables(ndc.somaPrecipitacao, 1),
						});
				  })
			: this.resultados.map(ndc => {
					tableData.push({
						nome: ndc.nome && corrigeDuplicacaoNome(ndc.nome),
						diasComChuva: ndc.diasComChuva,
						somaPrecipitacao: numberToBrNumberTables(ndc.somaPrecipitacao, 1),
					});
			  });

		return tableData;
	}

	async exportPdf() {
		const periodoAgrupamento = this.getDataFromForm(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;
		this.tipoPDF = true;
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);
		documentDefinition.content.push([
			{
				text: `Número de dias com chuva (NDC) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: await criarImagemBase64FromChart(this.chart),
				width: 500,
				height: 300,
				margin: [0, 15, 5, 15],
				alignment: 'center',
			},
		]);

		documentDefinition.content.push({
			table: {
				widths:
					periodoAgrupamento != 'diario'
						? ['25%', '25%', '25%', '25%']
						: ['35%', '35%', '30%'],
				body: this.getTableData(),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},

			marginLeft: 20,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		this.tipoPDF = false;
		const tableData = this.getTableData();

		DocumentExporter.gerarCSV(
			tableData,
			`Número de dias com chuva (NDC) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		this.tipoPDF = false;
		const tableData = this.getTableData();
		let txtData = '';

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Número de dias com chuva (NDC) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const agrupamento = this.inputs.form.get('agrupamento')?.value;
		this.tituloAgrupamento = setAgrupamentoEspecial(agrupamento);
		const nomeArquivo = `Número de dias com chuva (NDC) - ${this.periodoTitulo}`;
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const headers = this.getXLSXHeaders();
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarNdcParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: nomeArquivo,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
