import { Component } from '@angular/core';
import { inject, OnDestroy } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { FormGroup, Validators } from '@angular/forms';
import {
	LabelsHistoricoVariaveisMeteorologicas,
	TiposPeriodosVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodo,
	VariaveisMeteorologicasPorPeriodoETipoVariavel,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import {
	getBase64ImageFromURL,
	isNotNuloOuUndefined,
	numberToBrNumber,
	numberToBrNumberTables,
	verificaSePDF,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';
import { DateTimeUtils } from '@utils/datetime-util';
import {
	legendaInfoPluvObservada2MesesAAnual,
	legendaInfoPluvObservadaPeriodo,
	legendaInfoTemperatura,
} from '@componentes/mapa-paraiba-svg/legenda';
import { Select } from '@layout/interfaces/select';
import {
	PluviometriaObservadaCores,
	TemperaturaCores,
} from '@modulos/home/submodulos/dados-meteorologicos/enum/variaveis-meteorologicas-enum';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { bootstrapLarguraSM } from '@utils/constants';
@Component({
	selector: 'seira-mosaico-variavel-meteorologica',
	templateUrl: './mosaico-variavel-meteorologica.component.html',
	styleUrls: ['./mosaico-variavel-meteorologica.component.scss'],
})
export class MosaicoVariavelMeteorologicaComponent
	implements OnDestroy, InstanciaRelatorio
{
	carregando = true;
	resultados: VariaveisMeteorologicasPorPeriodoETipoVariavel[] = [];
	periodoTitulo = '';
	variavelSelcionada = '';
	variaveisMosaico: Select[] = [];
	funcaoCoresMapa?: (valor: number) => string;
	inputs = inject(INPUTS_RELATORIOS);
	labelTipoVariavel!: string;
	temperatura_minima = 15;
	descricaoRelatorio =
		'Define-se como mosaico de variável meteorológica o conjunto de mapas gerados para representar a distribuição espacial da precipitação acumulada e média de temperatura, seja por dia, mês ou ano, com a coloração variando de acordo com o volume de chuva registrado ou a temperatura média em cada área.';

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',

			size: 'small',

			icon: 'ph-file-pdf',

			onClick: () => this.exportPdf(),
		},
		{
			label: '.csv',

			size: 'small',

			icon: 'ph-file-csv',

			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',

			size: 'small',

			icon: 'ph-file-text',

			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',

			size: 'small',

			icon: 'ph-file-xls',

			onClick: () => this.exportarXLSX(),
		},
	];

	variaveisMeteorologicasPorPeriodo: VariaveisMeteorologicasPorPeriodo[] = [];
	legenda: LegendaOptions = {
		label: LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO,
		items: [],
	};
	form!: FormGroup;

	constructor(
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEIS_MOSAICO)
			?.clearValidators();
	}

	gerarRelatorio() {
		this.variavelSelcionada = this.inputs.form.get('variaveisMosaico')?.value;
		const dataInicio: Moment = this.inputs.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Moment = this.inputs.form.get(FormularioRelatorio.DATA_FIM)
			?.value;
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio.toISOString(),
			dataFim.toISOString(),
			periodo.toUpperCase()
		);
		if (dataInicio && dataFim && periodo.toUpperCase() == 'MENSAL') {
			this.inputs.setLoading(true);
			this.carregando = true;
			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio.toISOString(),
					dataFim.toISOString(),
					'PCD'
				)

				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						if (this.labelTipoVariavel !== 'PRECIPITACAO') {
							const resConvencionais = res
								.filter(r =>
									r.historico.some(
										h => h.temperaturaMedia > this.temperatura_minima
									)
								)
								.map(r => ({
									...r,
								}));

							this.variaveisMeteorologicasPorPeriodo = resConvencionais;
							this._resultados = resConvencionais;
						} else {
							const resConvencionais = res.map(r => ({
								...r,
							}));
							this.variaveisMeteorologicasPorPeriodo = resConvencionais;
							this._resultados = resConvencionais;
						}
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},

					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		} else if (periodo.toUpperCase() == 'ANUAL') {
			this.inputs.setLoading(true);
			this.carregando = true;
			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio?.toISOString(),
					dataFim?.toISOString(),
					'PCD'
				)
				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						if (this.labelTipoVariavel !== 'PRECIPITACAO') {
							const resConvencionais = res
								.filter(r =>
									r.historico.some(
										h => h.temperaturaMedia > this.temperatura_minima
									)
								)
								.map(r => ({
									...r,
								}));

							this.variaveisMeteorologicasPorPeriodo = resConvencionais;
							this._resultados = resConvencionais;
						}
						const resConvencionais = res.map(r => ({
							...r,
						}));
						this.variaveisMeteorologicasPorPeriodo = resConvencionais;
						this._resultados = resConvencionais;
					},

					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},

					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		} else if (dataInicio && dataFim && periodo.toUpperCase() == 'DIARIO') {
			this.inputs.setLoading(true);
			this.carregando = true;

			this.relatorioService

				.listarVariaveisMeteorologicas(
					TiposPeriodosVariaveisMeteorologicas.DIARIO,
					dataInicio.toISOString(),
					dataFim.toISOString(),
					'PCD'
				)
				.subscribe({
					next: res => {
						this.relatorioService.verificaExistenciaDados(res);
						if (this.labelTipoVariavel !== 'PRECIPITACAO') {
							const resConvencionais = res
								.filter(r =>
									r.historico.some(
										h => h.temperaturaMedia > this.temperatura_minima
									)
								)
								.map(r => ({
									...r,
								}));

							this.variaveisMeteorologicasPorPeriodo = resConvencionais;
							this._resultados = resConvencionais;
						}
						const resConvencionais = res.map(r => ({
							...r,
						}));

						this.variaveisMeteorologicasPorPeriodo = resConvencionais;
						this._resultados = resConvencionais;
					},

					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
						this.carregando = false;
					},

					complete: () => {
						this.inputs.setLoading(false);
						this.carregando = false;
					},
				});
		}
	}

	set _resultados(value: VariaveisMeteorologicasPorPeriodo[]) {
		this.legenda.label =
			this.variavelSelcionada === 'PRECIPITACAO'
				? LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO
				: LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA;

		this.resultados = value
			.map(el => this.resultadoPorTipoPeriodo(el))
			.filter(el => el.historico.length);
		this.setCoresLegenda();
	}

	getFormItemValue(formValue: string) {
		return this.inputs.form.get(formValue)?.value;
	}

	setCoresLegenda() {
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		this.legenda.items = [];
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');
		const diffMeses = DateTimeUtils.getDiferencaMesesUTC(
			new Date(diaFim),
			new Date(diaInicio)
		);

		if (periodo === 'anual') {
			const valores = Object.keys(
				this.variavelSelcionada === 'PRECIPITACAO'
					? legendaInfoPluvObservada2MesesAAnual
					: legendaInfoTemperatura
			).map(e => Number(e));

			const valoresDiariosStrings = valores.map(valor =>
				new Intl.NumberFormat('pt-BR').format(valor)
			);

			const cores = Object.values(
				this.variavelSelcionada === 'PRECIPITACAO'
					? PluviometriaObservadaCores
					: TemperaturaCores
			);

			this.configurarLegenda(valores, cores, valoresDiariosStrings);
		} else {
			const valoresDiarios = Object.keys(
				this.variavelSelcionada === 'PRECIPITACAO'
					? legendaInfoPluvObservadaPeriodo
					: legendaInfoTemperatura
			).map(e => Number(e));

			const valoresDiariosString = valoresDiarios.map(valor =>
				new Intl.NumberFormat('pt-BR').format(valor)
			);

			const coresDiarias = Object.values(
				this.variavelSelcionada === 'PRECIPITACAO'
					? PluviometriaObservadaCores
					: TemperaturaCores
			);

			this.configurarLegenda(
				valoresDiarios,
				coresDiarias,
				valoresDiariosString
			);
		}

		this.funcaoCoresMapa = (valor: number) => {
			if (valor === undefined || valor === null) {
				return '';
			}
			const valorArrendondado = Math.round(valor * 10) / 10;

			const itensOrdenados = [...this.legenda.items].sort(
				(a, b) =>
					Number(b.numberCurrentAndNext) - Number(a.numberCurrentAndNext)
			);

			for (const item of itensOrdenados) {
				if (valorArrendondado >= Number(item.numberCurrentAndNext)) {
					return item.color;
				}
			}

			return '';
		};
	}

	private configurarLegenda(
		valores: number[],
		cores: string[],
		valoresString: string[]
	): void {
		this.legenda.items = valores.map((valor, index) => ({
			numberCurrentAndNext: valor,
			title: valoresString[index],
			color: cores[index],
		}));
	}

	resultadoPorTipoPeriodo(
		resultado: VariaveisMeteorologicasPorPeriodo
	): VariaveisMeteorologicasPorPeriodoETipoVariavel {
		const periodo: keyof typeof TiposPeriodosVariaveisMeteorologicas =
			this.inputs.form
				.get(FormularioRelatorio.PERIODO_BUSCA)
				?.value.toUpperCase();
		const tipoVariavel: ValuesVariaveis =
			this.variavelSelcionada === 'PRECIPITACAO'
				? ValuesVariaveis.PRECIPITACAO
				: ValuesVariaveis.TEMPERATURA_DO_AR;
		const tipo =
			this.variavelSelcionada === 'PRECIPITACAO'
				? 'precipitacaoAcumulada'
				: 'temperaturaMedia';
		const agrupadoPorGeocodigo: Record<
			number,
			{
				valores: number[];
				nomeEstacao: string;
				tipoEstacao: keyof typeof TipoEstacao;
				nomeMunicipio: string;
			}
		> = {};

		resultado.historico.forEach(el => {
			if (isNotNuloOuUndefined(el[tipo])) {
				if (
					tipo === 'temperaturaMedia' &&
					el.temperaturaMedia > this.temperatura_minima
				) {
					const geocodigo = Number(el.geocodigo);

					if (!agrupadoPorGeocodigo[geocodigo]) {
						agrupadoPorGeocodigo[geocodigo] = {
							valores: [],
							nomeEstacao: el.nomeEstacao,
							tipoEstacao: TipoEstacao.PCD,
							nomeMunicipio: el.nomeMunicipio,
						};
					}

					agrupadoPorGeocodigo[geocodigo].valores.push(el[tipo]);
				} else if (tipo === 'precipitacaoAcumulada') {
					const geocodigo = Number(el.geocodigo);

					if (!agrupadoPorGeocodigo[geocodigo]) {
						agrupadoPorGeocodigo[geocodigo] = {
							valores: [],
							nomeEstacao: el.nomeEstacao,
							tipoEstacao: TipoEstacao.PCD,
							nomeMunicipio: el.nomeMunicipio,
						};
					}

					agrupadoPorGeocodigo[geocodigo].valores.push(el[tipo]);
				}
			}
		});

		const historicoComMedia = Object.keys(agrupadoPorGeocodigo).map(
			geocodigoStr => {
				const geocodigo = Number(geocodigoStr);
				const dados = agrupadoPorGeocodigo[geocodigo];
				const soma = dados.valores.reduce((acc, valor) => acc + valor, 0);
				const media = soma / dados.valores.length;

				return {
					geocodigo,
					periodo,
					valor: media,
					nomeEstacao: dados.nomeEstacao,
					tipoEstacao: dados.tipoEstacao,
					nomeMunicipio: dados.nomeMunicipio,
				};
			}
		);

		return { data: resultado.data, historico: historicoComMedia, tipoVariavel };
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);

		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);

		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEIS_MOSAICO)
			?.setValidators(Validators.required);
	}

	getDadosTabelaParaExportacao(
		dados: VariaveisMeteorologicasPorPeriodo[],
		isPdf: boolean
	) {
		const tableData: any[][] = [];

		const colunas = [
			{ text: 'Município', fillColor: '#DCDCDC' },
			{ text: 'Geocódigo', fillColor: '#DCDCDC' },
			{ text: 'Nome da estação', fillColor: '#DCDCDC' },
			{ text: 'Tipo da estação', fillColor: '#DCDCDC' },
			{
				text:
					this.variavelSelcionada === 'PRECIPITACAO'
						? 'Precipitação acumulada (mm)'
						: 'Temperatura média (°C)',
				fillColor: '#DCDCDC',
			},
			{ text: 'Data', fillColor: '#DCDCDC' },
		];
		verificaSePDF(tableData, colunas, isPdf);
		const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		const formatoData =
			periodo.toUpperCase() === 'ANUAL'
				? 'yyyy'
				: periodo.toUpperCase() === 'MENSAL'
				? 'MM/yyyy'
				: 'dd/MM/yyyy';

		dados.forEach((item: VariaveisMeteorologicasPorPeriodo) => {
			item.historico.forEach(historico => {
				const rowData: any = [
					historico.nomeMunicipio,
					historico.geocodigo,
					historico.nomeEstacao,
					historico.tipoEstacao,
				];

				if (isPdf) {
					if (this.variavelSelcionada === 'PRECIPITACAO') {
						rowData.push({
							text: historico.precipitacaoAcumulada
								? numberToBrNumber(historico.precipitacaoAcumulada, 1)
								: '-',
							alignment: 'center',
						});
					} else {
						rowData.push({
							text:
								historico.temperaturaMedia > this.temperatura_minima
									? numberToBrNumber(historico.temperaturaMedia, 1)
									: '-',
							alignment: 'center',
						});
					}

					rowData.push({
						text: DateTimeUtils.formatarData(item.data.toString(), formatoData),
						alignment: 'center',
					});
				} else {
					if (this.variavelSelcionada === 'PRECIPITACAO') {
						rowData.push(
							numberToBrNumberTables(
								historico.precipitacaoAcumulada as string | number,
								1
							)
						);
					} else {
						rowData.push(
							historico.temperaturaMedia > this.temperatura_minima
								? numberToBrNumber(historico.temperaturaMedia, 1)
								: '-'
						);
					}
					rowData.push(
						DateTimeUtils.formatarData(item.data.toString(), formatoData)
					);
				}

				tableData.push(rowData);
			});
		});

		return tableData;
	}

	async exportPdf() {
		const pdfDefinitions: any = await pdfseira.documentDefinitions();
		const promiseImagens = this.resultados.map(async (historico, index) => {
			const imagem: any = document.getElementById('mapa-' + index + '-imagem');

			if (imagem) {
				const canvas = document.createElement('canvas');
				canvas.width = imagem.width * 1.5;
				canvas.height = imagem.height * 1.4;
				canvas.style.background = 'color: black';
				canvas?.getContext('2d')?.drawImage(imagem, 0, 0);
				const url = canvas.toDataURL('image/png', 1);
				const imageData = await getBase64ImageFromURL(url);
				return { imagem: imageData, historico };
			}

			return null;
		});

		const legendaHtml = document.getElementById('legenda');

		if (legendaHtml) {
			const img = await html2canvas(legendaHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			}).then(canvas => canvas.toDataURL('image/png', 1));

			pdfDefinitions.content.push({
				text: `Mosaico de Variável Metereológica - ${this.periodoTitulo}`,
				alignment: 'center',
			});

			pdfDefinitions.content.push({
				image: img,
				margin: [0, 10, 0, 10],
				alignment: 'center',
				width: window.innerWidth > bootstrapLarguraSM ? 500 : 200,
			});
		}

		Promise.all(promiseImagens).then(imagens => {
			if (imagens.every(imagem => imagem === null)) {
				this.toastr.error(
					'Não é possível gerar um pdf pois nenhum mapa foi gerado.'
				);
				return;
			}

			const columns: any = [[], [], []]; // Dividindo em 3 colunas

			const formatData = (data: Date, periodo: string): string => {
				switch (periodo.toUpperCase()) {
					case 'ANUAL':
						return moment(data).format('yyyy').toString();

					case 'MENSAL':
						return moment(data).format('MM/yyyy').toString();

					case 'DIARIO':
						return moment(data).format('DD/MM/yyyy').toString();

					default:
						return 'MM/yyyy';
				}
			};

			imagens.forEach((resultadoProcessamentoDaImagem, index) => {
				if (resultadoProcessamentoDaImagem) {
					const dataFormatada = formatData(
						resultadoProcessamentoDaImagem.historico.data,
						resultadoProcessamentoDaImagem.historico.historico[0].periodo
					);

					const dateText = {
						text: dataFormatada,
						fontSize: 12,
						alignment: 'center',
						margin: [0, 10],
					};

					const image = {
						image: resultadoProcessamentoDaImagem.imagem,
						alignment: 'center',
						width: 160,
					};

					columns[index % 3].push(image);
					columns[index % 3].push(dateText);
				}

				if ((index < 15 && index + 1 === 15) || (index - 14) % 18 === 0) {
					columns[0].push({ text: '', pageBreak: 'after' });
					columns[1].push({ text: '', pageBreak: 'after' });
					columns[2].push({ text: '', pageBreak: 'after' });
				}
			});

			pdfDefinitions.content.push({
				columns: columns.map((column: any) => ({
					width: '33.3%',
					stack: column,
				})),
			});

			const tableData = this.getDadosTabelaParaExportacao(
				this.variaveisMeteorologicasPorPeriodo,
				true
			);

			pdfDefinitions.content.push(
				{
					text: '',
					alignment: 'center',
					fontSize: 10,
				},

				{
					text: 'PCD: Plataforma Coleta de Dados.',
					fontSize: 9,
					margin: [0, 5, 70, 10],
				},

				{
					table: {
						widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
						body: tableData,
						layout: {
							alignment: 'center',
							noWrap: false,
						},
					},

					fontSize: 9,
					marginLeft: 0,
				}
			);

			pdfseira.generatePdf(pdfDefinitions);
		});
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.variaveisMeteorologicasPorPeriodo,
			false
		);

		DocumentExporter.gerarCSV(
			tableData,
			`Mosaico de Variável Metereológica - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(
			this.variaveisMeteorologicasPorPeriodo,
			false
		);
		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];
			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Mosaico de Variável Metereológica - ${this.periodoTitulo}`
		);
	}

	getMosaicos(): Array<{
		data: string | number | Date;
		geocodigo: number;
		precipitacaoAcumulada?: number | string;
		temperaturaMedia?: number | string;
		nomeEstacao: string;
		nomeMunicipio: string;
	}> {
		const mosaicos: Array<{
			data: string | number | Date;
			geocodigo: number;
			precipitacaoAcumulada?: number | string;
			temperaturaMedia?: number | string;
			nomeEstacao: string;
			nomeMunicipio: string;
			tipoEstacao: string;
		}> = [];

		this.variaveisMeteorologicasPorPeriodo.map(mosaico => {
			const periodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
				?.value;

			const formatoData =
				periodo.toUpperCase() === 'ANUAL'
					? 'yyyy'
					: periodo.toUpperCase() === 'MENSAL'
					? 'MM/yyyy'
					: 'dd/MM/yyyy';

			mosaicos.push(
				...mosaico.historico
					.filter(
						item =>
							item.temperaturaMedia !== null &&
							item.temperaturaMedia > this.temperatura_minima
					)
					.map(item => ({
						nomeMunicipio: item.nomeMunicipio,
						geocodigo: item.geocodigo,
						precipitacaoAcumulada: numberToBrNumberTables(
							item.precipitacaoAcumulada as string | number,
							1
						),
						temperaturaMedia: numberToBrNumberTables(
							item.temperaturaMedia as string | number,
							1
						),
						nomeEstacao: item.nomeEstacao,
						tipoEstacao: item.tipoEstacao,
						data: DateTimeUtils.formatarData(
							mosaico.data.toString(),
							formatoData
						),
					}))
			);
		});
		return mosaicos;
	}

	async exportarXLSX() {
		const nomeArquivo = `Mosaico de Variáveis Metereológicas - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};

		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};

		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'nomeMunicipio',
				text: 'Município',
				size: 48,
			},

			{ label: 'geocodigo', text: 'Geocódigo', size: 24 },

			{ label: 'nomeEstacao', text: 'Nome da estação', size: 24 },

			{ label: 'tipoEstacao', text: 'Tipo da estação', size: 24 },
			{
				label:
					this.variavelSelcionada === 'PRECIPITACAO'
						? 'precipitacaoAcumulada'
						: 'temperaturaMedia',

				text:
					this.variavelSelcionada === 'PRECIPITACAO'
						? 'Precipitação acumulada  (mm)'
						: 'Temperatura média (°C)',

				size: 24,
			},
			{ label: 'data', text: 'Data', size: 24 },
		];

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},

			sheet: [
				{
					shiftTop: 3,

					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],

					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},

					headers: headers,
					data: this.getMosaicos(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],

			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
