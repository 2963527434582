export const Meses = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

export const MesesNaoAbreviados = [
	'',
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
];

export enum MesesNumericos {
	JANEIRO = 0,
	FEVEREIRO = 1,
	MARCO = 2,
	ABRIL = 3,
	MAIO = 4,
	JUNHO = 5,
	JULHO = 6,
	AGOSTO = 7,
	SETEMBRO = 8,
	OUTUBRO = 9,
	NOVEMBRO = 10,
	DEZEMBRO = 11,
}

export const DIAS_NO_MES: { [key in MesesNumericos]: number } = {
	[MesesNumericos.JANEIRO]: 31,
	[MesesNumericos.FEVEREIRO]: 28,
	[MesesNumericos.MARCO]: 31,
	[MesesNumericos.ABRIL]: 30,
	[MesesNumericos.MAIO]: 31,
	[MesesNumericos.JUNHO]: 30,
	[MesesNumericos.JULHO]: 31,
	[MesesNumericos.AGOSTO]: 31,
	[MesesNumericos.SETEMBRO]: 30,
	[MesesNumericos.OUTUBRO]: 31,
	[MesesNumericos.NOVEMBRO]: 30,
	[MesesNumericos.DEZEMBRO]: 31,
};
