import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HttpErrorResponse } from '@angular/common/http';
import * as Highcharts from 'highcharts/highstock';
import { Select } from '@layout/interfaces/select';
import { Chart } from 'highcharts';
import { SweetAlertOptions } from 'sweetalert2';
import {
	Agrupamento,
	AgrupamentoEspecial,
	AgrupamentoResponse,
} from '@modulos/home/submodulos/dados-meteorologicos/interfaces';

export function isNuloOuUndefined(valor: any): valor is undefined | null {
	return valor === null || valor === undefined;
}

export function isNotNuloOuUndefined<T>(
	valor: T | undefined | null
): valor is T {
	return !isNuloOuUndefined(valor);
}

export function isVazio(valor: any): valor is undefined | null | '' {
	return isNuloOuUndefined(valor) || valor === '';
}

export function removerNulosEUndefinedFilter<T>(
	valor: T | undefined
): valor is T {
	return isNotNuloOuUndefined(valor);
}
export function setAgrupamento(agrupamento: any) {
	const tituloAgrupamento =
		Agrupamento[agrupamento as keyof typeof Agrupamento];

	return tituloAgrupamento;
}
export function setAgrupamentoEspecial(agrupamento: any) {
	const tituloAgrupamento =
		AgrupamentoEspecial[agrupamento as keyof typeof AgrupamentoEspecial];

	return tituloAgrupamento;
}
export function removerNaoNumericosFilter(valor: any): valor is number {
	return valor !== 0 && !isNuloOuUndefined(valor) && !isNaN(valor);
}

export const defaultLanguageOptionsDatatablesPtBr = {
	search:
		'<span class="me-2">Pesquisar:</span> <div class="form-control-feedback form-control-feedback-end flex-fill">_INPUT_<div class="form-control-feedback-icon"><i class="ph-magnifying-glass opacity-50"></i></div></div>',
	lengthMenu: `<div class="d-flex align-items-center justify-content-between" style="max-width: 200px">
        <span style="margin-right: 4px">Itens por página</span>
        <div style="min-width: 73px">
            _MENU_
        </div>
    </div>`,
	paginate: {
		first: 'Primeiro',
		last: 'Último',
		next: '<i class="ph-caret-right"></i>',
		previous: '<i class="ph-caret-left"></i>',
	},
	emptyTable: 'Nenhum registro encontrado',
	info: '_START_ - _END_ de _TOTAL_',
	infoEmpty: '0 - 0 de 0',
	infoFiltered: '(Filtrados de _MAX_ registros)',
	loadingRecords: 'Carregando...',
	processing: `<div class="text-center">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Carregando...</span>
    </div>
</div>`,
	zeroRecords: 'Nenhum registro encontrado',
	aria: {
		sortAscending: ': Ordenar colunas de forma ascendente',
		sortDescending: ': Ordenar colunas de forma descendente',
	},
	select: {
		rows: '%d linhas selecionadas',
	},
	buttons: {
		copySuccess: {
			1: 'Uma linha copiada com sucesso',
			_: '%d linhas copiadas com sucesso',
		},
		collection:
			'Coleção  <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span>',
		colvis: 'Visibilidade da coluna',
		colvisRestore: 'Restaurar visibilidade',
		copy: 'Copiar',
		copyKeys:
			'Pressione ctrl ou u2318 + C para copiar os dados da tabela para a área de transferência do sistema. Para cancelar, clique nesta mensagem ou pressione Esc..',
		copyTitle: 'Copiar para a área de transferência',
		csv: 'CSV',
		excel: 'Excel',
		pageLength: {
			'-1': 'Mostrar todos os registros',
			_: 'Mostrar %d registros',
		},
		pdf: 'PDF',
		print: 'Imprimir',
		createState: 'Criar estado',
		removeAllStates: 'Remover todos os estados',
		removeState: 'Remover',
		renameState: 'Renomear',
		savedStates: 'Estados salvos',
		stateRestore: 'Estado %d',
		updateState: 'Atualizar',
	},
};
export function getBase64ImageFromURL(url: string): Promise<string> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.setAttribute('crossOrigin', 'anonymous');

		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;

			const ctx = canvas.getContext('2d')!;
			ctx.drawImage(img, 0, 0);

			const dataURL = canvas.toDataURL('image/png');

			resolve(dataURL);
		};

		img.onerror = error => {
			reject(error);
		};

		img.src = url;
	});
}
export function obter_erro_request(err: any | HttpErrorResponse): string {
	let msg_erro: string;
	if (isNotNuloOuUndefined(err.detail)) {
		msg_erro = err.detail;
	} else if (isNotNuloOuUndefined(err.error)) {
		const mensagemErroDeValidacao =
			err.error[0]?.mensagem ?? err.error?.mensagem;
		msg_erro = mensagemErroDeValidacao ?? err.error;
	} else {
		msg_erro = `${err.status}`;
	}
	return msg_erro;
}
export async function modalConfirmacao(
	nomeTela: string,
	options: SweetAlertOptions = {}
) {
	const resultado = await Swal.fire({
		title: `Remover ${nomeTela} `,
		text: 'Você deseja realmente remover esse registro?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Sim, remova',
		cancelButtonText: 'Cancelar',
		buttonsStyling: false,
		focusCancel: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {},
		customClass: {
			confirmButton: 'btn btn-danger',
			cancelButton: 'btn btn-light',
			denyButton: 'btn btn-light',
		},
		...options,
	});
	return resultado.isConfirmed;
}

export function enumAsSelectOptions<T>(enumValues: {
	[chave: string]: string;
}): Select<T>[] {
	const array: Select<T>[] = [];
	for (const enumValuesKey in enumValues) {
		array.push({ value: enumValuesKey as T, name: enumValues[enumValuesKey] });
	}
	return array;
}

export function filterSelectOptions<T>(
	originalValues: {
		[chave: string]: string;
	},
	...valuesToMantain: string[]
): Select<T>[] {
	return enumAsSelectOptions<T>(originalValues).filter(agrupamento =>
		valuesToMantain.includes(agrupamento.name)
	);
}

export function capitalizeFirstLetter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}
export function formatarPrecipitacaoAcumulada(valor: number) {
	if (valor % 1 === 0) {
		return `${valor},0`;
	} else {
		return valor.toString();
	}
}
export function decapitalizeFirstLetter(text: string) {
	return text.charAt(0).toLowerCase() + text.slice(1);
}

export function fileToBase64(file: File): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
}

export const ptBrLang: Highcharts.Options['lang'] = {
	noData: 'Sem dados',
	loading:
		'<div class="spinner-border" role="status"><span class="visually-hidden">Carregando...</span></div>',
	accessibility: {
		rangeSelector: {
			dropdownLabel: 'Período',
			clickButtonAnnouncement: 'Visualizando...',
			maxInputLabel: 'Selecione ...',
			minInputLabel: 'Selecione ...',
		},
	},
	months: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	],
	shortMonths: [
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	],
	weekdays: [
		'Domingo',
		'Segunda',
		'Terça',
		'Quarta',
		'Quinta',
		'Sexta',
		'Sábado',
	],
	contextButtonTitle: 'Exportar gráfico',
	decimalPoint: ',',
	thousandsSep: '.',
	downloadJPEG: 'Baixar imagem JPEG',
	downloadPDF: 'Baixar arquivo PDF',
	downloadPNG: 'Baixar imagem PNG',
	downloadSVG: 'Baixar vetor SVG',
	downloadCSV: 'Baixar arquivo CSV',
	downloadXLS: 'Baixar arquivo XLS',
	printChart: 'Imprimir gráfico',
	rangeSelectorFrom: 'De',
	rangeSelectorTo: 'Para',
	rangeSelectorZoom: 'Zoom',
	resetZoom: 'Limpar zoom',
	resetZoomTitle: 'Voltar zoom para nível 1:1',
	exitFullscreen: 'Sair da tela cheia',
	viewFullscreen: 'Ver em tela cheia',
	viewData: 'Ver tabela',
	hideData: 'Esconder tabela',
};

export function cpfMask(cpf: number | string): string {
	cpf = cpf !== null ? cpf : '';
	let stringCpf = String(cpf).padStart(11, '0');
	stringCpf = stringCpf.replace(/(\d{3})(\d)/, '$1.$2');
	stringCpf = stringCpf.replace(/(\d{3})(\d)/, '$1.$2');
	stringCpf = stringCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	return stringCpf;
}

export function cnpjMask(cnpj: number | string): string {
	cnpj = cnpj !== null ? cnpj : '';
	let stringCnpj = String(cnpj).padStart(14, '0');
	stringCnpj = stringCnpj.replace(/^(\d{2})(\d)/, '$1.$2');
	stringCnpj = stringCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
	stringCnpj = stringCnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
	stringCnpj = stringCnpj.replace(/(\d{4})(\d)/, '$1-$2');
	return stringCnpj;
}
export function hexToRgb(hex: string) {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

function componentToHex(c: number) {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
	return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function numberToBrNumber(
	value?: number | string | null,
	decimalPlaces = 0
): any {
	if (value === undefined || value === null || value === '') {
		return null;
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	}).format(+value);
}
export function numberToBrNumberTables(
	value?: number | string | null,
	decimalPlaces = 0
): any {
	if (value === undefined || value === null || value === '') {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	}).format(+value);
}
export function idetifierToBaciaSubBaciaRegioes(item: AgrupamentoResponse) {
	if (item.dadosPosto.bacia !== null) {
		if (
			item.TipoVisualizacao === 'Bacia' &&
			!item.dadosPosto.bacia.includes('Região')
		) {
			return 'Bacia';
		} else {
			return 'Região de Curso';
		}
	} else if (
		item.TipoVisualizacao === 'SubBacia' ||
		item.dadosPosto.subbacia != null
	) {
		return 'Sub-bacia';
	} else {
		return '-';
	}
}
export function removerAcentos(value: string) {
	// Matches a character in the range "̀ " to "ͯ " (char code 768 to 879). Case sensitive.
	const RegEx = /[\u0300-\u036f]/g;
	return value.normalize('NFD').replace(RegEx, '');
}

export function compararStrings(
	string1: string | number,
	string2: string | number
) {
	if (string1 && string2) {
		return removerAcentos(string1.toString())
			.toLowerCase()
			.includes(removerAcentos(string2.toString()).toLowerCase());
	} else {
		return string1;
	}
}
export function compararStringsIguais(
	string1: string | number,
	string2: string | number
) {
	if (string1 && string2) {
		return (
			removerAcentos(string1.toString()).toLowerCase() ===
			removerAcentos(string2.toString()).toLowerCase()
		);
	} else {
		return false;
	}
}

export function acessarAtributo(objeto: Object, caminho: string) {
	const partes = caminho.split('.'); // Divide o caminho em partes usando o ponto como separador
	let valor = objeto;
	for (const parte of partes) {
		if (valor && valor.hasOwnProperty(parte)) {
			// @ts-ignore
			valor = valor[parte]; // Acesse a propriedade correspondente
		} else {
			return undefined; // Retorna undefined se a propriedade não existir
		}
	}

	return valor; // Retorna o valor da propriedade
}
export type ImportError = {
	campo: string;
	linha: number;
	mensagem: string;
};
export async function criarImagemBase64FromChart(chart: Chart, width = 1200) {
	const chartOptions = {
		chart: {
			width: width,
		},
		exporting: {
			sourceWidth: width,
		},
	};

	const svg = chart.getSVG(chartOptions) || '';
	const blob = new Blob([svg], { type: 'image/svg+xml' });

	return getBase64ImageFromURL(URL.createObjectURL(blob));
}

export function transformaEmDecimal(
	value: string | number,
	casasDecimais = 1
): number {
	if (value === null || value === undefined) {
		return 0;
	}

	// Converte para string e substitui vírgula por ponto para formato numérico correto
	const stringValue = value.toString().replace(',', '.');

	// Converte para número mantendo o ponto decimal
	const numericValue = parseFloat(stringValue);

	// Se não for um número válido, retorna 0 para evitar erros
	if (isNaN(numericValue)) {
		return 0;
	}

	// Retorna o número formatado com as casas decimais corretas
	return parseFloat(numericValue.toFixed(casasDecimais));
}

export function isColorDarkOrLight(
	color: string
): 'clara' | 'escura' | 'desconhecida' {
	// Remove espaços em branco e converte a cor para minúsculas para garantir consistência
	color = color.replace(/\s/g, '').toLowerCase();

	// Verifica se a cor é RGB
	if (/^rgb/.test(color)) {
		const [r, g, b] = color.match(/\d+/g)!.map(Number);
		const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
		return luminance > 0.5 ? 'clara' : 'escura';
	}

	// Verifica se a cor é hexadecimal
	if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
		const hex = color.replace(/^#/, '');
		const rgb = hex.match(/.{2}/g)!.map(x => parseInt(x, 16));
		return isColorDarkOrLight(`rgb(${rgb.join(', ')})`);
	}

	// Caso a cor não seja válida, retorna "desconhecida"
	return 'desconhecida';
}

export function toCamelCase(text: string) {
	return text
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()
		.replace(/\W+(.)/g, function (match, chr) {
			return chr.toUpperCase();
		});
}

export const blobToBase64 = (blob: Blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

export function verificaSePDF(
	tableData: any[][],
	colunas: { text: string; fillColor: string }[],
	isPdf: boolean
) {
	if (isPdf) {
		tableData.push(colunas);
	} else {
		tableData.push(
			colunas.map(coluna => {
				return coluna.text;
			})
		);
	}
}
