import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { HomeRoutingModule } from './home-routing.module';
import { ImagensSateliteComponent } from '@home/submodulos/imagens-satelite/imagens-satelite.component';
import { ParaVoceComponent } from '@home/submodulos/para-voce/para-voce.component';
import { ZoneamentosComponent } from './submodulos/zoneamentos/pages/index/zoneamentos.component';
import { PrevisaoClimaticaComponent } from './submodulos/previsao-climatica/pages/index/previsao-climatica.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DadosHidrologicosComponent } from '@home/submodulos/dados-hidrologicos/pages/index/dados-hidrologicos.component';
import { DadosMeteorologicosComponent } from '@home/submodulos/dados-meteorologicos/pages/index/dados-meteorologicos.component';
import { DadosMeteorologicosModule } from './submodulos/dados-meteorologicos/dados-meteorologicos.module';
import { ModoVisualizacaoComponent } from './componentes/modo-visualizacao/modo-visualizacao.component';
import { ModoExibicaoComponent } from './componentes/modo-exibicao/modo-exibicao.component';
import { PrevisaoNumericaTempoComponent } from './submodulos/previsao-numerica-tempo/pages/index/previsao-numerica-tempo.component';

@NgModule({
	declarations: [
		HomePageComponent,
		DadosMeteorologicosComponent,
		DadosHidrologicosComponent,
		PrevisaoNumericaTempoComponent,
		ZoneamentosComponent,
		ImagensSateliteComponent,
		ParaVoceComponent,
		PrevisaoClimaticaComponent,
		ModoVisualizacaoComponent,
		ModoExibicaoComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		RouterModule,
		HomeRoutingModule,
		DadosMeteorologicosModule,
	],
	exports: [ModoVisualizacaoComponent, ModoExibicaoComponent],
})
export class HomeModule {}
