import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'seira-public-select',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => PublicSelectComponent),
		},
	],
})
export class PublicSelectComponent<T = any, V = T>
	implements ControlValueAccessor
{
	@Input() options!: T[];
	@Input() notFoundText = 'Nenhuma opção foi encontrada!';
	@Input() optionLabel = 'label';
	@Input() optionValue = '-';
	@Input() placeholder = '';
	@Input() class = '';
	@Input() label?: string;
	@Input() id = `select-${uuidv4()}`;
	@Input() required = false;
	@Input() isDesabilitado = false;
	@Input() multiple = false;
	@Output() disabledChange = new EventEmitter<boolean>();
	@Input() searchable = true;
	@Input() clearable = true;
	@Input() value?: V;
	@Input() carregando = false;
	@Output() carregandoChange = new EventEmitter<boolean>();
	@Input() carregandoLabel = 'Carregando';
	@Input() maxShownValues = 3;
	@Input() optionsCentralized = true;

	public onValueChange = (obj: V) => {
		obj;
	};

	public onTouched(): void {}

	registerOnChange(fn: (changedValue: V) => void): void {
		this.onValueChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabledChange.emit(isDisabled);
	}

	writeValue(obj: V): void {
		this.value = obj;
	}

	selectAll() {
		this.writeValue(this.options.map((o: any) => o[this.optionValue]) as V);
		this.onValueChange(this.value as V);
	}
}
