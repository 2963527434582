<div>
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Estações Automáticas
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informacoesGerais"></seira-public-page-about>
				</div>
			</div>
		</seira-page-title>
	</div>

	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-4 col-xl-3">
				<div class="col-lg-11 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="opcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectVariaveisMapa">
					<seira-public-select-variavel-estacoes-automaticas
						[clearable]="false"
						formControlName="variavelMapa"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectVariaveisMosaico">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveisMosaico"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma variável encontrada"
						formControlName="variaveisMosaico"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectJanelaTempo">
					<seira-public-select
						placeholder="Informe a janela de tempo"
						[options]="janelasTempo"
						label="Janela de tempo"
						optionValue="name"
						optionLabel="value"
						notFoundText="Nenhuma janela de tempo encontrada"
						formControlName="janelaTempo"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectVariaveisGrafico">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveisGrafico"
						optionValue="value"
						optionLabel="name"
						formControlName="variavelGrafico"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectAgrupamento">
					<seira-public-select
						placeholder="Selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectPostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Município/Posto"
						[searchable]="true"
						[options]="municipioPostos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMicrorregioes">
					<seira-public-select
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectRegioes">
					<seira-public-select
						placeholder="Selecione uma região pluviométrica"
						label="Região pluviométrica"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região pluviométrica encontrada"
						formControlName="regiao"
						[searchable]="true"
						[options]="regioes"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectBacias">
					<seira-public-select
						placeholder="Selecione uma bacia"
						label="Bacia"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma bacia encontrada"
						formControlName="bacia"
						[searchable]="true"
						[options]="bacias"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMesorregioes">
					<seira-public-select
						placeholder="Selecione uma mesorregião"
						label="Mesorregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma mesorregião encontrada"
						formControlName="mesorregiao"
						[searchable]="true"
						[options]="mesorregioes"
						[isDesabilitado]="loadingForm" />
				</div>
				<div class="col-lg-11 mt-1" *ngIf="mostrarSelectMunicipios">
					<seira-public-select
						placeholder="Selecione um município"
						label="Município"
						optionValue="id"
						optionLabel="nome"
						notFoundText="Nenhum município encontrado"
						formControlName="municipio"
						[searchable]="true"
						[options]="municipios"
						[isDesabilitado]="loadingForm" />
				</div>

				<div class="col-lg-12 col-md-4 col-sm-6" *ngIf="mostrarPeriodoBusca">
					<seira-public-radio-group
						[controlName]="FormularioRelatorio.PERIODO_BUSCA"
						[options]="opcoesPeriodo"
						[isDesabilitado]="loadingForm" />
				</div>

				<ng-container
					*ngIf="
						(relatorioAtual?.periodos || exibirRangePicker) &&
						labelAdptativaPeriodo
					">
					<ng-container
						*ngIf="labelAdptativaPeriodo.mensal_por_nome; else unicoPicker">
						<div class="col-lg-11 d-flex flex-column">
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês inicial"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesInicio"
								[searchable]="true"
								[options]="meses"
								[isDesabilitado]="loadingForm" />
							<seira-public-select
								class="mt-1"
								placeholder="Selecione um mês"
								label="Mês final"
								optionValue="value"
								optionLabel="name"
								notFoundText="Nenhum mês encontrado"
								formControlName="mesFim"
								[searchable]="true"
								[options]="meses"
								[isDesabilitado]="loadingForm" />
						</div>
					</ng-container>

					<ng-template #unicoPicker>
						<ng-container *ngIf="labelAdptativaPeriodo.unico; else rangePicker">
							<div class="col-lg-11 d-flex flex-column mt-1">
								<seira-public-date-picker
									class="w-100 mt-1"
									controlName="periodo"
									[label]="labelAdptativaPeriodo.unico.label"
									[placeholder]="labelAdptativaPeriodo.unico.placeholder || ''"
									[datePickerType]="
										labelAdptativaPeriodo.unico.dataType || 'month'
									"
									[tipoFormulario]="getFormItemValue('tipo')"
									[isDesabilitado]="loadingForm" />
							</div>
						</ng-container>
					</ng-template>

					<ng-template #rangePicker>
						<div class="col-lg-11 d-flex flex-column mt-1">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataInicio"
								[label]="labelAdptativaPeriodo.inicio?.label"
								[placeholder]="labelAdptativaPeriodo.inicio?.placeholder || ''"
								[datePickerType]="
									labelAdptativaPeriodo.inicio?.dataType || 'year'
								"
								[tipoFormulario]="getFormItemValue('tipo')"
								[isDesabilitado]="loadingForm" />
						</div>
						<div class="col-lg-11 d-flex flex-row align-items-end">
							<seira-public-date-picker
								class="w-100 mt-1"
								controlName="dataFim"
								[label]="labelAdptativaPeriodo.fim?.label"
								[placeholder]="labelAdptativaPeriodo.fim?.placeholder || ''"
								[datePickerType]="labelAdptativaPeriodo.fim?.dataType || 'year'"
								[minDate]="dataInicio"
								[tipoFormulario]="getFormItemValue('tipo')"
								[maxDate]="limiteDataFimSuperior"
								[isDesabilitado]="loadingForm" />
						</div>
					</ng-template>
				</ng-container>

				<div
					class="col-lg-11 mt-1 d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
				</div>
			</div>
			<div class="label-botoes p-3 col-xl-4 col-lg-6 col-md-8 col-sm-12 mt-3">
				<div
					class="d-flex flex-colum justify-content-end mb-2"
					*ngIf="
						relatoriosService.getExibirBotoesExportacao() && form.value.tipo
					">
					<seira-public-button-group
						[buttons]="botoesDeExportacao"
						[disabledButtons]="loadingForm" />
				</div>

				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>

		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar</seira-public-button
			>
		</ng-template>
	</form>
	<div
		class="d-flex w-100 justify-content-start"
		*seiraIfViewportSize="'desktop'">
		<div class="col-lg-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informacoesGerais"></seira-info-gerais>
		</div>
	</div>
</div>
