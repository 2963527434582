import {
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../../interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { Estacao } from '../../../interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { GraficosPrecipitacaoService } from '../../../services/graficos-precipitacao.service';
import {
	criarImagemBase64FromChart,
	enumAsSelectOptions,
	isNotNuloOuUndefined,
	numberToBrNumber,
	obter_erro_request,
	verificaSePDF,
} from '@utils';
import { ToastrService } from 'ngx-toastr';
import {
	ConfiguracaoGrafico,
	RespostaGraficoVariaveis,
	Variavel,
	VariavelUnidade,
} from '../../../interfaces/VariaveisMeteorologicas';
import { FabricaGraficoVariavel } from '@componentes/charts/fabrica-grafico-variavel';
import {
	obterDadosSeriePorResultado,
	transformarDados,
} from './obter-serie-resultado';
import { normalizeCommonJSImport } from '@utils/normalize-commonjs-import';
import { rangeSelector5Dias } from '@componentes/charts/range-selector';
import { DateTimeUtils } from '@utils/datetime-util';
import { StockChart } from 'highcharts';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { RelatoriosService } from '../../../services';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { format } from 'date-fns';
import { DocumentExporter } from '@utils/document-exporter';
import * as ExcelTable from 'mr-excel';

const importHighcharts = normalizeCommonJSImport(
	import('highcharts/highstock')
);

const importWindbarbHighchartsModule = normalizeCommonJSImport(
	import('highcharts/modules/windbarb')
);

@Component({
	selector: 'seira-grafico-variaveis-meteorologicas',
	templateUrl: './grafico-variaveis-meteorologicas.component.html',
	styleUrls: ['./grafico-variaveis-meteorologicas.component.scss'],
})
export class GraficoVariaveisMeteorologicasComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	descricaoRelatorio =
		'Define-se como variáveis meteorológicas os parâmetros atmosféricos, como temperatura, umidade, e velocidade do vento, que influenciam as condições climáticas de um local ou região.';
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	inputs = inject(INPUTS_RELATORIOS);
	estacoes: Estacao[] = [];
	dados!: RespostaGraficoVariaveis;
	chart!: StockChart;
	loading = false;

	variaveisUnidade: Select<string>[] = [];
	variaveisArray: Select<string>[] = [];
	variavelSelecionada = '';
	isVariavelVento = false;
	tituloGrafico = '';

	constructor(
		private relatoriosService: RelatoriosService,
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService,
		private cdr: ChangeDetectorRef
	) {
		this.setValidators();
	}

	ngOnInit() {
		this.variaveisArray = enumAsSelectOptions(Variavel);
		this.variaveisUnidade = enumAsSelectOptions(VariavelUnidade);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEL_GRAFICO)
			?.clearValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.VARIAVEL_GRAFICO)
			?.setValidators(Validators.required);
	}

	get getVariavelName() {
		return this.variaveisArray.find(
			value => value.value === this.variavelSelecionada
		);
	}

	get getVariavelUnidade() {
		return this.variaveisUnidade.find(
			value => value.value === this.variavelSelecionada
		)?.name;
	}

	private getConfiguracoesGrafico(): ConfiguracaoGrafico {
		const botao5Dias = rangeSelector5Dias.buttons.findIndex(
			botao => botao.count === 5
		);
		const todosResultados = rangeSelector5Dias.buttons.findIndex(
			botao => botao.type === 'all'
		);
		return new ConfiguracaoGrafico('grafico', '', '', '', {
			exporting: {
				enabled: false,
			},
			rangeSelector: {
				selected:
					this.variavelSelecionada === Variavel.VENTO_2M
						? botao5Dias
						: todosResultados,
			},
			time: {
				useUTC: true,
			},
		});
	}

	getDadosTabelaParaExportacao(isPdf: boolean) {
		const tableData: any[][] = [];
		const colunas = [{ text: 'Data', fillColor: '#DCDCDC' }];

		if (this.isVariavelVento) {
			colunas.push({
				text: `Direção ${VariavelUnidade.VENTO_DIRECAO}`,
				fillColor: '#DCDCDC',
			});
			colunas.push({
				text: `Velocidade ${VariavelUnidade.VENTO}`,
				fillColor: '#DCDCDC',
			});
		} else {
			colunas.push({
				text: `${this.getVariavelName?.name} ${this.getVariavelUnidade}`,
				fillColor: '#DCDCDC',
			});
		}

		verificaSePDF(tableData, colunas, isPdf);

		const dadosGrafico = transformarDados(
			this.variavelSelecionada,
			this.dados
		) as any;

		dadosGrafico.datas.forEach((data: number, index: number) => {
			const dataFormatada = format(new Date(data), 'dd/MM/yyyy HH:mm');

			if (this.isVariavelVento) {
				const valorDirecao = dadosGrafico['direcaoVento'][index];
				const valorVelocidade = dadosGrafico['velocidadeVento'][index];
				const valorDirecaoFormatado = isNotNuloOuUndefined(valorDirecao)
					? numberToBrNumber(valorDirecao, 2)
					: '-';
				const valorVelocidadeFormatado = isNotNuloOuUndefined(valorVelocidade)
					? numberToBrNumber(valorVelocidade, 2)
					: '-';
				tableData.push([
					dataFormatada,
					valorDirecaoFormatado,
					valorVelocidadeFormatado,
				]);
			} else {
				const variavel = this.variavelSelecionada.split('_')[0].toLowerCase();
				const valor = dadosGrafico[variavel][index];
				const valorFormatado = isNotNuloOuUndefined(valor)
					? numberToBrNumber(valor, 2)
					: '-';
				tableData.push([dataFormatada, valorFormatado]);
			}
		});

		return tableData;
	}

	retornarDadosParaXLSX() {
		const tableData: any[] = [];
		const dadosGrafico = transformarDados(
			this.variavelSelecionada,
			this.dados
		) as any;

		dadosGrafico.datas.forEach((data: number, index: number) => {
			const dataFormatada = format(new Date(data), 'dd/MM/yyyy HH:mm');

			if (this.isVariavelVento) {
				const valorDirecao = dadosGrafico['direcaoVento'][index];
				const valorDirecaoFormatado = isNotNuloOuUndefined(valorDirecao)
					? numberToBrNumber(valorDirecao, 2)
					: '-';

				const valorVelocidade = dadosGrafico['velocidadeVento'][index];
				const valorVelocidadeFormatado = isNotNuloOuUndefined(valorVelocidade)
					? numberToBrNumber(valorVelocidade, 2)
					: '-';

				tableData.push({
					data: dataFormatada,
					valorDirecao: valorDirecaoFormatado,
					valorVelocidade: valorVelocidadeFormatado,
				});
			} else {
				const valor =
					dadosGrafico[this.variavelSelecionada.toLowerCase()][index];
				const valorFormatado = isNotNuloOuUndefined(valor)
					? numberToBrNumber(valor, 2)
					: '-';

				tableData.push({ data: dataFormatada, valor: valorFormatado });
			}
		});

		return tableData;
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 400,
			width: 750,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'landscape'
		);
		documentDefinition.pageOrientation = 'landscape';

		const content = [
			{
				text: this.tituloGrafico,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		documentDefinition.content.push(content);

		const tableData = this.getDadosTabelaParaExportacao(true);

		documentDefinition.content.push(
			{
				text: '',
				alignment: 'center',
				fontSize: 10,
			},
			{
				table: {
					widths: this.isVariavelVento ? ['33%', '33%', '33%'] : ['50%', '50%'],
					body: tableData,
					fontSize: 12,
				},
				marginTop: 100,
			}
		);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		DocumentExporter.gerarCSV(tableData, this.tituloGrafico);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(false);
		let txtData = '';

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(txtData, this.tituloGrafico);
	}

	exportarXLSX() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const headers = [{ label: 'data', text: 'Data', size: 24 }];

		if (this.isVariavelVento) {
			headers.push({
				label: 'valorDirecao',
				text: `Direção ${VariavelUnidade.VENTO_DIRECAO}`,
				size: 24,
			});
			headers.push({
				label: 'valorVelocidade',
				text: `Velocidade ${VariavelUnidade.VENTO}`,
				size: 24,
			});
		} else {
			headers.push({
				label: 'valor',
				text: `${this.getVariavelName?.name} ${this.getVariavelUnidade}`,
				size: 24,
			});
		}

		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarDadosParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: this.tituloGrafico,
						styleId: 'title',
					},
				},
			],
			fileName: this.tituloGrafico,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	gerarRelatorio() {
		this.variavelSelecionada = this.inputs.form.get('variavelGrafico')?.value;
		const estacao = Number.parseInt(this.inputs.form.get('estacao')?.value);
		const dataInicio = this.inputs.form.get('dataInicio')?.value;
		const dataFim = this.inputs.form.get('dataFim')?.value;
		const periodoBusca = this.inputs.form.get('periodoBusca')?.value;
		this.isVariavelVento = this.variavelSelecionada.split('_')[0] === 'VENTO';

		this.loading = true;
		this.inputs.setLoading(true);

		this.tituloGrafico = `Variáveis meteorológicas - ${this.getVariavelName
			?.name} ${this.getVariavelUnidade} - ${DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			periodoBusca
		)}`;

		this.graficosPrecipitacaoService
			.buscarGraficoVariaveisMetereologicas(estacao, dataInicio, dataFim)
			.subscribe({
				next: resp => {
					this.dados = resp;
					this.relatoriosService.verificaExistenciaDados(resp.data);
				},
				error: erro => {
					this.loading = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toastr.error(
						msg_erro,
						'Erro ao obter informações do posto selecionado'
					);
				},
				complete: () => {
					this.loading = false;
					this.inputs.setLoading(false);
					this.montarGrafico();
				},
			});
	}

	async montarGrafico() {
		if (this.variavelSelecionada) {
			const fabricaGrafico = new FabricaGraficoVariavel();
			const dadosGrafico = obterDadosSeriePorResultado(
				this.variavelSelecionada,
				0,
				this.dados
			);

			this.cdr.detectChanges();
			const Chart = await importHighcharts;
			if (this.variavelSelecionada === 'VENTO') {
				const { default: WindbarbModule } =
					await importWindbarbHighchartsModule;
				WindbarbModule(Chart);
			}

			const estacaoId = Number.parseInt(this.inputs.form.get('estacao')?.value);
			const estacao = this.estacoes.find(value => value.id === estacaoId);

			const config = fabricaGrafico.obterConfiguracao(
				`${this.getVariavelName?.name} ${this.getVariavelUnidade} de ${estacao?.nomePosto}`,
				this.getConfiguracoesGrafico(),
				dadosGrafico
			);
			this.chart = Chart.stockChart(config);
		}
	}
}
