import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { TableComponent } from '@componentes/table/table.component';
import {
	Estacao,
	Municipio,
} from '@modulos/home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import {
	PostosRelatorios,
	RelatorioPrecipitacaoAnual,
} from '@modulos/home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@modulos/home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@modulos/home/submodulos/dados-meteorologicos/services/relatorios.service';
import { Select } from '@layout/interfaces/select';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { DateTimeUtils } from '@utils/datetime-util';
import { DocumentExporter } from '@utils/document-exporter';
import * as pdfseira from '@utils/pdf-seira';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Subscription } from 'rxjs';
import { corrigeDuplicacaoNome } from '@modulos/home/submodulos/dados-meteorologicos/utils';
import * as ExcelTable from 'mr-excel';
import { DataModel } from 'mr-excel';

@Component({
	selector: 'seira-pluviometro-tabela-precipitacao-anual',
	templateUrl: './pluviometro-tabela-precipitacao-anual.component.html',
	styleUrls: ['./pluviometro-tabela-precipitacao-anual.component.scss'],
})
export class PluviometroTabelaPrecipitacaoAnualComponent
	implements InstanciaRelatorio, OnDestroy
{
	descricaoRelatorio =
		'Define-se como pluviometria observada anual a soma da quantidade de chuva registrada em um período específico, como semanas ou meses, em uma determinada área.';

	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaPrecipitacaoAnual?: RelatorioPrecipitacaoAnual;
	carregandoRelatorio = true;
	private subscription = new Subscription();
	microrregioes: Select[] = [];
	periodo = '';
	@ViewChild('tabela-precipitacao-anual') tabela!: TableComponent;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportarPDF(this.dadosTabelaPrecipitacaoAnual!);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV(this.dadosTabelaPrecipitacaoAnual!);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT(this.dadosTabelaPrecipitacaoAnual!);
			},
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	form?: FormGroup<any> | undefined;
	regioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;

	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.setValidators();
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.POSTO)
			?.setValidators(Validators.required);
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	public gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}

		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);

		const postosIds = this.postos.map(posto => posto.id);

		const dataFim = this.getFormItemValue('periodo');

		this.periodo = DateTimeUtils.formatarDataPeriodo(dataFim, '', '', true);

		this.relatoriosService
			.relatorioPrecipitacaoAnual(postosIds, null, dataFim.toISOString())
			.subscribe({
				next: prec => {
					prec == null
						? this.relatoriosService.setExibirBotoesExportacao(false)
						: this.relatoriosService.setExibirBotoesExportacao(true);
					this.dadosTabelaPrecipitacaoAnual = prec;
					this.dadosTabelaPrecipitacaoAnual.dataBusca = dataFim;
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.carregandoRelatorio = false;
				},
			});
	}

	getPeriodo() {
		return DateTimeUtils.formatarDataPeriodo(
			new Date(this.inputs.form.get('periodo')?.value),
			'',
			'anual',
			true
		);
	}

	retornarPrecipitacaoAnualParaXLSX() {
		const tableData: any[] = [];

		this.dadosTabelaPrecipitacaoAnual?.chuvasAcumuladas.forEach(
			(dadoRelatorio: any) => {
				tableData.push({
					municipio: dadoRelatorio.detalhesPosto.municipio,
					posto: dadoRelatorio.detalhesPosto.posto,
					tipoEstacao:
						dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Pluviômetro convencional'
							: 'PCD',
					precipitacaoAcumulada: isNotNuloOuUndefined(
						dadoRelatorio.precipitacaoAcumulada
					)
						? numberToBrNumber(dadoRelatorio.precipitacaoAcumulada, 1)
						: '-',
				});
			}
		);

		return tableData;
	}

	async exportarPDF(dados: RelatorioPrecipitacaoAnual) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Pluviometria observada anual (mm) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dados) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}
		const tableData: (
			| { text: string; fillColor: string }
			| string
			| number
		)[][] = [];

		tableData.push([
			{ text: 'Município', fillColor: '#DCDCDC' },
			{ text: 'Posto', fillColor: '#DCDCDC' },
			{ text: 'Tipo de estação', fillColor: '#DCDCDC' },
			{ text: 'Precipitação  (mm)', fillColor: '#DCDCDC' },
		]);
		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			tableData.push([
				dadoRelatorio.detalhesPosto.municipio,
				dadoRelatorio.detalhesPosto.posto,
				dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'Pluviômetro convencional'
					: 'PCD',
				isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
					? numberToBrNumber(dadoRelatorio.precipitacaoAcumulada, 1)
					: '-',
			]);
		});

		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(dados: RelatorioPrecipitacaoAnual) {
		const tableData = this.setupTabelaPrecipitacao(dados);
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-pluviometria-observada-anual-${this.getPeriodo()}`
		);
	}

	exportarTXT(dados: RelatorioPrecipitacaoAnual) {
		let txtData = '';

		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			txtData +=
				`Município/Posto: ${corrigeDuplicacaoNome(
					`${dadoRelatorio.detalhesPosto.municipio}/${dadoRelatorio.detalhesPosto.posto}`
				)}\n` +
				`Tipo de estação: ${
					dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
						? 'Pluviômetro convencional'
						: 'PCD'
				}\n` +
				`Precipitação anual (mm): ${
					isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
						? numberToBrNumber(dadoRelatorio.precipitacaoAcumulada, 1)
						: '-'
				}\n\n`;
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-pluviometria-observada-anual-${this.getPeriodo()}`
		);
	}

	exportarXLSX() {
		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};
		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as DataModel.AlignmentOption,
		};
		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as DataModel.AlignmentOption,
		};
		const headers = [
			{
				label: 'municipio',
				text: 'Município',
				size: 24,
			},
			{
				label: 'posto',
				text: 'Posto',
				size: 24,
			},
			{ label: 'tipoEstacao', text: 'Tipo de estação', size: 24 },
			{
				label: 'precipitacaoAcumulada',
				text: 'Precipitação anual (mm)',
				size: 24,
			},
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarPrecipitacaoAnualParaXLSX(),
					columns: [],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `Precipitação anual - ${this.getPeriodo()}`,
						styleId: 'title',
					},
				},
			],
			fileName: `relatorio-precipitacao-anual-${this.getPeriodo()}`,
		};

		ExcelTable.generateExcel(dataExcel);
	}

	setupTabelaPrecipitacao(dados: RelatorioPrecipitacaoAnual) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Município',
			'Posto',
			'Tipo de estação',
			'Precipitação anual (mm)',
		]);
		dados.chuvasAcumuladas.forEach((dadoRelatorio: any) => {
			tableData.push([
				dadoRelatorio.detalhesPosto.municipio,
				dadoRelatorio.detalhesPosto.posto,
				dadoRelatorio.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'Pluviômetro convencional'
					: 'PCD',
				isNotNuloOuUndefined(dadoRelatorio.precipitacaoAcumulada)
					? numberToBrNumber(dadoRelatorio.precipitacaoAcumulada, 1)
					: '-',
			]);
		});

		return tableData;
	}
}
