import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
} from '@utils/interpolacao/interpolacao';
import { GeoJSON } from '@shared/interfaces/geometry';
import { HttpClient } from '@angular/common/http';
import { Polygon } from 'geojson';
import { Iapm } from '@home/submodulos/dados-meteorologicos/interfaces/iapm';
import { GraficosPrecipitacaoService } from '@home/submodulos/dados-meteorologicos/services/graficos-precipitacao.service';
import {
	endOfMonth,
	format,
	formatISO,
	startOfMonth,
	subMonths,
} from 'date-fns';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { numberToBrNumber, obter_erro_request, verificaSePDF } from '@utils';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import ptBrLocale from 'date-fns/locale/pt-BR';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';
import { RelatoriosService } from '../../../services';
import * as ExcelTable from 'mr-excel';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';
import { bootstrapLarguraSM } from '@utils/constants';
import Position = GeoJSON.Position;
import FeatureCollection = GeoJSON.FeatureCollection;

@Component({
	selector: 'seira-mapa-iapm',
	templateUrl: './mapa-iapm.component.html',
	styleUrls: ['./mapa-iapm.component.scss'],
})
export class MapaIapmComponent implements OnInit, OnDestroy {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	interpolacaoPrecipitacao?: InterpolacaoCanvasBounds;
	interpolacaoIAPM?: InterpolacaoCanvasBounds;
	carregando = true;
	valoresPrecipitacao: Iapm[] = [];
	valoresIAPM: Iapm[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	medicoes: Iapm[] = [];
	regiao?: Position[][];
	descricaoRelatorio =
		'Define-se como IAPM, Índice de Anomalia de Precipitação Modificado, a variação da pluviometria em comparação com valores médios históricos a fim de caracterizar as diferentes intensidades de eventos secos e chuvosos no tempo e no espaço.';

	referenciaEstudo =
		'Os métodos utilizados para a análise da precipitação e cálculo do Índice de Anomalia de Chuva (IAC) foram baseados no estudo intitulado: "Índice de Anomalia de Chuva (IAC) como Indicador para Análise da Variabilidade Climática na Bacia Hidrográfica do Rio Pajeú-PE", de autoria de J. M. O. Assis, W. M. Souza, M. C. M. Sobral, G. L. Melo e R. A. Irmão. Este trabalho foi apresentado no XX Simpósio Brasileiro de Recursos Hídricos em 2013 e fornece uma aplicação prática do IAC para a análise da variabilidade climática. O estudo está disponível no repositório da Associação Brasileira de Recursos Hídricos (ABRHidro).';

	periodoTitulo = '';
	relatorioService = inject(RelatoriosService);

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () => this.exportarXLSX(),
		},
	];

	constructor(
		private httpClient: HttpClient,
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {
		this.setValidators();
	}

	ngOnInit() {
		const inicioDefault = startOfMonth(subMonths(new Date(), 6));
		const fimDefault = endOfMonth(subMonths(new Date(), 1));

		this.inputs.form.patchValue({
			dataInicio: inicioDefault,
			dataFim: fimDefault,
		});

		this.initialValues();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	gerarRelatorio() {
		if (this.inputs.form.invalid) {
			return;
		}

		const dataInicio = this.inputs.form.get('dataInicio')?.value;
		const dataFim = this.inputs.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;
		this.periodoTitulo = DateTimeUtils.formatarDataPeriodo(
			dataInicio,
			dataFim,
			'diario'
		);

		this.graficosPrecipitacaoService
			.buscarIAPM(formatISO(new Date(dataInicio)), formatISO(new Date(dataFim)))
			.subscribe({
				next: (resp: Iapm[]) => {
					this.relatorioService.verificaExistenciaDados(resp);
					this.medicoes = resp;
				},
				error: erro => {
					this.carregando = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toastr.error(msg_erro, 'Erro ao obter informações de IAPM');
				},
				complete: () => {
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
			});
	}

	async interpolarValores() {
		try {
			const [interpolacaoPrecipitacao, interpolacaoIAPM] = await Promise.all([
				this.criarImagemInterpolacao('precipitacao1Mes', true),
				this.criarImagemInterpolacao('mrai', false),
			]);

			this.interpolacaoPrecipitacao =
				interpolacaoPrecipitacao as InterpolacaoCanvasBounds;
			this.interpolacaoIAPM = interpolacaoIAPM as InterpolacaoCanvasBounds;
		} catch (error) {
			console.error('Erro ao interpolar valores:', error);
		}
	}

	valoresParaInterpolacao(isPrecipitacao: boolean) {
		return this.medicoes.map(value => ({
			lat: value.latitude,
			lng: value.longitude,
			value: isPrecipitacao ? value.acumulado : value.mrai,
			nomePosto: value.nomePosto,
			isPrecipitacao: isPrecipitacao,
		}));
	}
	criarImagemInterpolacao(
		variavel: VariavelMeteorologica,
		isPrecipitacao: boolean
	): Promise<InterpolacaoCanvasBounds> {
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				this.valoresParaInterpolacao(isPrecipitacao),
				variavel,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject(`Erro ao criar imagem de interpolação de ${variavel}.`);
			}
			resolve(interpolacao);
		});
	}

	async getMapImage() {
		const elementoHtml = document.getElementById('mapa-legenda');
		if (elementoHtml) {
			const canva = await html2canvas(elementoHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			});

			return canva.toDataURL('image/png', 1);
		} else {
			return null;
		}
	}

	getDadosTabelaParaExportacao(dados: Iapm[], isPdf: boolean) {
		const tableData: any[][] = [];

		const colunas = [
			{ text: 'Nome do posto', fillColor: '#DCDCDC' },
			{ text: 'Latitude', fillColor: '#DCDCDC' },
			{ text: 'Longitude', fillColor: '#DCDCDC' },
			{ text: 'Acumulado', fillColor: '#DCDCDC' },
			{ text: 'IAPM', fillColor: '#DCDCDC' },
		];

		verificaSePDF(tableData, colunas, isPdf);

		dados.forEach((item: Iapm) => {
			const rowData = [
				item.nomePosto,
				item.latitude,
				item.longitude,
				numberToBrNumber(item.acumulado, 1),
				numberToBrNumber(item.mrai, 1),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	retornarIAPMParaXLSX() {
		const iapms: Iapm[] = [];

		this.medicoes.forEach(iapm => {
			iapms.push({
				estacaoId: iapm.estacaoId,
				nomePosto: iapm.nomePosto,
				latitude: iapm.latitude,
				longitude: iapm.longitude,
				acumulado: numberToBrNumber(iapm.acumulado, 1) as any,
				mrai: numberToBrNumber(iapm.mrai, 1) as any,
			});
		});

		return iapms;
	}

	async exportarPDF() {
		format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
		const documentDefinition: any = await pdfseira.documentDefinitions();
		const img = await this.getMapImage();

		documentDefinition.content.push([
			{
				text: `Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				image: img,
				width: window.innerWidth > bootstrapLarguraSM ? 500 : 200,
				heigth: window.innerWidth > bootstrapLarguraSM ? 400 : undefined,
				alignment: 'center',
			},
		]);

		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, true);

		documentDefinition.content.push({
			table: {
				widths: ['20%', '20%', '20%', '20%', '20%'],
				body: tableData,
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
			marginLeft: 15,
			marginRight: 15,
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}

	exportarCSV() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		DocumentExporter.gerarCSV(
			tableData,
			`Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`
		);
	}

	exportarTXT() {
		const tableData = this.getDadosTabelaParaExportacao(this.medicoes, false);

		let txtData = '';
		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}

		DocumentExporter.gerarTXT(
			txtData,
			`Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`
		);
	}

	exportarXLSX() {
		const nomeArquivo = `Índice de anomalia de precipitação modificado (IAPM) - ${this.periodoTitulo}`;

		const colorPalette = {
			c1: '2C3639',
			c2: 'FFFFFF',
			c3: '000000',
			c4: 'EEEEEE',
		};

		const rowStyle = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
		};
		const headerStyle = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
		};
		const headerStyleCenter = {
			backgroundColor: colorPalette.c4,
			color: colorPalette.c1,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};
		const rowStyleCenter = {
			backgroundColor: colorPalette.c2,
			color: colorPalette.c3,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const title = {
			backgroundColor: colorPalette.c2,
			whiteSpace: 'pre',
			color: colorPalette.c3,
			bold: true,
			alignment: {
				horizontal: 'center',
				vertical: 'center',
				wrapText: 1,
			} as ExcelTable.DataModel.AlignmentOption,
		};

		const headers = [
			{
				label: 'nomePosto',
				text: 'Nome do posto',
				size: 48,
			},
			{ label: 'latitude', text: 'Latitude', size: 24 },
			{ label: 'longitude', text: 'Longitude', size: 24 },
			{ label: 'acumulado', text: 'Acumulado', size: 24 },
			{ label: 'mrai', text: 'IAPM', size: 24 },
		];
		const dataExcel = {
			styles: {
				headerStyle,
				headerStyleCenter,
				rowStyle,
				rowStyleCenter,
				title,
			},
			sheet: [
				{
					shiftTop: 3,
					images: [
						{
							url: 'assets/images/cabecalho/cabeçalho_excel.png',
							from: 'A1',
							to: 'C3',
						},
					],
					styleCellCondition(
						data: any,
						object: any,
						rowIndex: number,
						colIndex: number,
						fromHeader: boolean,
						styleKeys: string[]
					) {
						if (data && data.label) {
							return 'headerStyleCenter';
						} else {
							if (colIndex === 1) {
								return 'rowStyleCenter';
							} else {
								return 'rowStyle';
							}
						}
					},
					headers: headers,
					data: this.retornarIAPMParaXLSX(),
					columns: [
						{ key: 'nomePosto' },
						{
							key: 'latitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'longitude',
							style: { numFmt: '0.0' },
						},
						{
							key: 'acumulado',
							style: { numFmt: '0' },
						},
						{
							key: 'mrai',
							style: { numFmt: '0' },
						},
					],
					title: {
						consommeRow: 3,
						consommeCol: 3,
						text: `${nomeArquivo}`,
						styleId: 'title',
					},
				},
			],
			fileName: nomeArquivo,
		};

		ExcelTable.generateExcel(dataExcel);
	}
}
