export enum Formato {
	TABELA = 'Tabela',
	GRAFICO = 'Gráfico',
	MAPA = 'Mapa',
}

export enum TipoGraficoPCD {
	VARIAVEL_METEOROLOGICA = 'Variável meteorológica',
}

export enum TipoMapaPCD {
	VARIAVEL_METEOROLOGICA_INTERPOLADA = 'Variável meteorológica interpolada',
	MOSAICO_VARIAVEL_METEOROLOGICA = 'Mosaico de variável meteorológica',
}

export enum TipoTabelaPCD {
	EVAPOTRANSPIRACAO = 'Evapotranspiração',
	VALORES_EXTREMOS = 'Valores extremos',
	VARIAVEL_METEOROLOGICA = 'Variável meteorológica',
}

export enum TipoGrafico {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	IAPM = 'IAPM',
	SPI = 'SPI',
	DESVIO_POR_PERIODO_MM = 'Desvio por período (mm)',
	DESVIO_POR_PERIODO_PORCENTAGEM = 'Desvio por período (%)',
}

export enum TipoMapa {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	PLUVIOMETRIA_INTERPOLADA = 'Pluviometria interpolada',
	MOSAICO_CHUVAS = 'Mosaico de chuvas',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	IAPM = 'IAPM',
}

export enum TipoTabela {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	NUMERO_DIAS_COM_CHUVA = 'Número de dias com chuva (NDC)',
	CLIMATOLOGIA = 'Climatologia',
	DESVIO = 'Desvios',
	IAPM = 'IAPM',
	SPI = 'SPI',
	HISTORICO_DA_SUDENE = 'Histórico da SUDENE',
	PRECIPITACAO_DIARIA = 'Pluviometria observada diária',
	PRECIPITACAO_MENSAL = 'Pluviometria observada mensal',
	//PRECIPITACAO_ACUMULADA = 'Pluviometria observada anual',
	PRECIPITACAO_ANUAL = 'Pluviometria observada anual',
	ESTATISTICA_MENSAL = 'Estatística mensal',
	DESVIOS_MENSAIS_ANUAIS = 'Desvios mensais e anuais',
	VALORES_EXTREMOS_PLUVIOMETRIA = 'Valores Extremos de Chuva',
	DESVIO_POR_PERIODO = 'Desvios por período',
}

export enum TipoMapaGraficoTabelaQuantis {
	QUANTIS = 'Quantis',
}

export enum Agrupamento {
	MUNICIPIO_POSTO = 'Município/Posto',
	MUNICIPIO = 'Município',
	MICRORREGIAO = 'Microrregião',
	MESORREGIAO = 'Mesorregião',
	BACIA = 'Bacia',
	// REGIAO_GEOADMINISTRATIVA = 'Região geoadministrativa',
	REGIAO_PLUVIOMETRICA = 'Região pluviométrica',
	SUBBACIA = 'Sub-bacia',
}

export enum AgrupamentoEspecial {
	MUNICIPIO_POSTO = 'Município/Posto',
	MUNICIPIO = 'Município',
	MICRORREGIAO = 'Microrregião',
	MESORREGIAO = 'Mesorregião',
	// REGIAO_GEOADMINISTRATIVA = 'Região geoadministrativa',
	BACIA_SUBBACIA = 'Bacia/Sub-bacia',
	REGIAO_PLUVIOMETRICA = 'Região pluviométrica',
}

export enum AgrupamentoQuantis {
	MUNICIPIO_POSTO = 'Município/Posto',
	REGIAO_PLUVIOMETRICA = 'Região pluviométrica',
}

export enum Meses {
	JANEIRO = 'Janeiro',
	FEVEREIRO = 'Fevereiro',
	MARCO = 'Março',
	ABRIL = 'Abril',
	MAIO = 'Maio',
	JUNHO = 'Junho',
	JULHO = 'Julho',
	AGOSTO = 'Agosto',
	SETEMBRO = 'Setembro',
	OUTUBRO = 'Outubro',
	NOVEMBRO = 'Novembro',
	DEZEMBRO = 'Dezembro',
}
