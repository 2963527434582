export * from './agrupamento';
export * from './boletim';
export * from './quantis-graficos-config';
export * from './chuva-estado';
export * from './desvioDTO';
export * from './filtros-opcoes';
export * from './iapm';
export * from './legenda-de-cores';
export * from './mapa-quantis-mensal';
export * from './quantis-anual';
export * from './quantis-responses';
export * from './detalhes-posto';
export * from './relatorioEnum';
export * from './response';
export * from './tabela-relatorio';
export * from './texto-informativo';
export * from './tipos-relatorios';
export * from './VariaveisMeteorologicas';
export * from './variavel-meteorologica-mapa';
export * from './detalhes-posto';
export * from './quantis-regiao-pluviometrica';
export * from './relatorio-vec';
export * from './iapm-dto';
