import { Component, Input, OnInit } from '@angular/core';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import {
	LabelsHistoricoVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodoETipoVariavel,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import {
	decapitalizeFirstLetter,
	isColorDarkOrLight,
	isNotNuloOuUndefined,
} from '@utils';
import { FormBuilder, FormControl } from '@angular/forms';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { corrigeDuplicacaoNome, gerarFilenameGrafico } from '../../utils';
import { capitalizeFirstLetter } from '../../../../../../shared/utils/index';
import { ValuesVariaveis } from '../../submodulos/monitoramento/interfaces/estacao-monitorada';

@Component({
	selector: 'seira-modal-relatorio-historico-imagens',
	templateUrl: './modal-relatorio-historico-imagens.component.html',
	styleUrls: ['./modal-relatorio-historico-imagens.component.scss'],
})
export class ModalRelatorioHistoricoImagensComponent implements OnInit {
	@Input() imagem!: VariaveisMeteorologicasPorPeriodoETipoVariavel;
	@Input() legenda!: LegendaOptions;
	@Input() funcaoCoresMapa!: (valor: number) => string;
	@Input() close!: () => void;
	tabelaRef!: PublicTableComponent;
	botoesExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
		{
			label: '.svg',
			size: 'small',
			icon: 'ph ph-file-image',
			onClick: () => {
				this.exportMap().then();
			},
		},
	];
	labelTipoVariavel!: string;
	OpcoesTipoVisualizacao = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];
	colunasTabela: ADTColumns[] = [];
	form = this.fb.group({
		visualizacao: new FormControl<'mapa' | 'tabela'>('mapa'),
	});
	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.labelTipoVariavel =
			this.imagem.tipoVariavel === ValuesVariaveis.PRECIPITACAO
				? LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO
				: LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA;
		this.setColumnsTable();
	}
	getMapaElemento() {
		const svgContainer = document.getElementById('mapa-svg');
		const svgContent = svgContainer?.querySelector('svg')!;
		return svgContent.outerHTML;
	}

	async exportPdf() {
		const svg = this.getMapaElemento();
		const pdfDefinitions: any = await pdfseira.documentDefinitions();

		pdfDefinitions.content.push({
			text: [
				{ text: 'Relatório: ', bold: true },
				this.imagem.historico[0].tipoEstacao === 'PCD'
					? `Mosaico de variável meteorológica`
					: `Mosaico de chuva`,
			],
			fontSize: 10,
			alignment: 'start',
			margin: [0, 10, 0, 0],
		});
		pdfDefinitions.content.push({
			text: [{ text: 'Formato: ', bold: true }, 'Mapa'],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{ text: 'Tipo de variável: ', bold: true },
				`${this.labelTipoVariavel}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{ text: 'Agrupado por: ', bold: true },
				`${
					this.imagem.historico[0].periodo === 'ANUAL'
						? 'Ano'
						: this.imagem.historico[0].periodo === 'MENSAL'
						? 'Mês'
						: this.imagem.historico[0].periodo === 'DIARIO'
						? 'Dia'
						: 'Dia'
				}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{
					text: `${
						this.imagem.historico[0].periodo === 'ANUAL'
							? 'Ano'
							: this.imagem.historico[0].periodo === 'MENSAL'
							? 'Mês'
							: this.imagem.historico[0].periodo === 'DIARIO'
							? 'Dia'
							: 'Dia'
					}`,
					bold: true,
				},
				{ text: ': ', bold: true },
				`${this.formatDate(this.imagem.historico[0].periodo)}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			svg: svg,
			width: 500,
			height: 300,
			alignment: 'center',
		});

		const legendaHtml = document.getElementById('legenda');
		if (legendaHtml) {
			const img = await html2canvas(legendaHtml, {
				useCORS: true,
				allowTaint: true,
				logging: false,
				scale: 2,
			}).then(canvas => canvas.toDataURL('image/png', 1));
			pdfDefinitions.content.push({
				image: img,
				margin: [0, 10, 0, 10],
				alignment: 'center',
				width: 500,
			});
		}
		if (this.imagem.historico[0].tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL') {
			pdfDefinitions.content.push({
				text: 'PCO: Pluviômetro Convencional.',
				fontSize: 9,
				margin: [0, 5, 70, 10],
			});
		} else {
			pdfDefinitions.content.push({
				text: 'PCD: Plataforma de Coleta de Dados.',
				fontSize: 9,
				margin: [0, 5, 70, 10],
			});
		}

		pdfDefinitions.content.push({
			table: {
				widths: ['*', 'auto', '*'],
				body: this.tableData(),
				layout: {
					alignment: 'center',
					noWrap: false,
					fontSize: 5,
				},
			},
		});
		pdfseira.generatePdf(pdfDefinitions);
	}
	async exportMap() {
		const svg = this.getMapaElemento();
		const blob = new Blob([svg], { type: 'image/svg+xml' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		const nome = gerarFilenameGrafico(
			this.imagem.historico[0].tipoEstacao === 'PCD'
				? `mosaico_variavel_meteorologica`
				: `mosaico_chuva`
		);
		a.download = `${nome}.svg`;
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	tableData() {
		const dadosTabela: Array<any[]> = [];
		const cabecalho = this.colunasTabela.map(col => ({
			text: col.title,
			fillColor: '#DCDCDC',
		}));
		dadosTabela.push(cabecalho);

		const dadosHistorico = this.imagem.historico
			.map(hist => [
				corrigeDuplicacaoNome(`${hist.nomeMunicipio}/${hist.nomeEstacao}`),
				hist.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
					? 'PCO'
					: hist.tipoEstacao,
				{
					text: this.formatDecimal(hist.valor),
					alignment: 'center',
				},
			])
			.sort((a: any, b: any) => {
				const nomeA = a[0];
				const nomeB = b[0];

				if (nomeA < nomeB) {
					return -1;
				} else if (nomeA > nomeB) {
					return 1;
				} else {
					return 0;
				}
			});

		dadosTabela.push(...dadosHistorico);
		return dadosTabela;
	}
	get tipoVisualizacao() {
		return this.form.get('visualizacao')?.value;
	}

	handleTextColorBasedOnBackgroundColor(
		backgroundColor: string
	): string | undefined {
		if (isColorDarkOrLight(backgroundColor) == 'escura') {
			return 'text-white';
		}
		return;
	}

	setColumnsTable() {
		this.colunasTabela = [
			{
				title: 'Município/Posto',
				data: null,
				render: (
					data: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'][0]
				) => {
					const municipio = data.nomeMunicipio;
					const estacao = data.nomeEstacao;
					return corrigeDuplicacaoNome(`${municipio}/${estacao}`);
				},
				className: 'text-tertiary text-start',
			},

			{
				title: 'Tipo',
				data: null,
				render: (
					data: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'][0]
				) => {
					return `${TipoEstacao[data.tipoEstacao]}`;
				},
				className: 'text-tertiary text-center',
			},
			{
				title: this.labelTipoVariavel,
				data: 'valor',
				render: valor => {
					return this.formatDecimal(valor);
				},
				className: 'text-tertiary text-center',
			},
		];
	}

	formatDecimal(valor: any) {
		return isNotNuloOuUndefined(valor)
			? valor.toFixed(1).replace('.', ',')
			: null;
	}

	labelValorMedido(value?: number) {
		return this.labelTipoVariavel ===
			LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO
			? `${this.formatDecimal(value)} mm`
			: `${this.formatDecimal(value)} °C`;
	}
	getDateFormat(periodo: string): string {
		switch (periodo.toUpperCase()) {
			case 'ANUAL':
				return 'yyyy';
			case 'MENSAL':
				return 'MM/yyyy';
			case 'DIARIO':
				return 'dd/MM/yyyy';
			default:
				return 'dd/MM/yyyy';
		}
	}

	formatDate(periodo: string): string {
		if (periodo.toUpperCase() === 'DIARIO') {
			return moment(this.imagem.data).format('DD/MM/yyyy').toString();
		}
		const formatString = this.getDateFormat(periodo);
		return moment(this.imagem.data).format(formatString).toString();
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly decapitalizeFirstLetter = decapitalizeFirstLetter;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
