import { Injectable } from '@angular/core';
import {
	ExecucaoMeteogramaDTO,
	Meteograma,
	MunicipioMeteograma,
	ExecucaoMeteograma,
} from '@home/submodulos/previsao-numerica-tempo/interfaces/meteograma';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';

@Injectable({
	providedIn: 'root',
})
export class MeteogramaService {
	constructor(private http: HttpClientProtectedService) {}

	listExecucoes(): Observable<ExecucaoMeteograma[]> {
		return this.http.get<ExecucaoMeteogramaDTO[]>(
			Rotas.METEOGRAMA + '/execucoes'
		);
	}

	listMunicipios(idExecucao: number) {
		return this.http.get<MunicipioMeteograma[]>(
			Rotas.METEOGRAMA + '/municipios',
			{ idExecucao }
		);
	}

	getMeteograma(idMunicipio: number) {
		return this.http.get<Meteograma>(Rotas.METEOGRAMA + '/meteograma', {
			idMunicipio,
		});
	}

	getMeteogramaByGeocodigo(geocodigo: number, idResultadoModelo: number) {
		return this.http.get<Meteograma>(
			Rotas.METEOGRAMA + '/meteogramaByGeocodigo',
			{
				geocodigo,
				idExecucao: idResultadoModelo,
			}
		);
	}
}
