import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterContentInit, Component, Input } from '@angular/core';
import { legendaInfoPluvObservadaPeriodo } from '@componentes/mapa-paraiba-svg/legenda';
import { enumAsSelectOptions } from '@utils';
import { LegendaCor } from '@utils/interpolacao/interpolacao';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'seira-monitoramento-legenda-horizontal',
	templateUrl: './monitoramento-legenda-horizontal.component.html',
	styleUrls: ['./monitoramento-legenda-horizontal.component.scss'],
})
export class MonitoramentoLegendaHorizontalComponent
	implements AfterContentInit
{
	@Input()
	valores: LegendaCor[] = [];
	@Input()
	carregando!: boolean;
	@Input()
	mapaNome = 'Interpolação';
	legendaInfo = enumAsSelectOptions(legendaInfoPluvObservadaPeriodo);
	@Input()
	muitosItensLegenda = false;
	_destroyed = new Subject();
	isTelaPequena = false;

	constructor(private readonly bpObserver: BreakpointObserver) {}

	ngAfterContentInit(): void {
		this.bpObserver
			.observe(['(max-width: 715px)'])
			.pipe(takeUntil(this._destroyed))
			.subscribe(res => {
				this.isTelaPequena = res.matches;
			});
	}

	get temInfoAdicional() {
		const a = this.valores.filter(v => v.infoAdicional !== '');

		if (a.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	getKeys() {
		return Object.keys(this.valores).map(Number);
	}

	get width() {
		return `${100 / this.valores.length}vw`;
	}

	borderRadius(index: number) {
		const isFirst = index === 0;
		const isLast = index === this.valores.length - 1;

		const topLeft = isFirst ? '10px' : '0';
		const topRight = isFirst ? '10px' : '0';
		const bottomRight = isLast ? '10px' : '0';
		const bottomLeft = isLast ? '10px' : '0';

		return `border-radius: ${topRight} ${bottomRight} ${bottomLeft} ${topLeft} ;`;
	}
}
