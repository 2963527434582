<div class="d-flex align-items-center">
	<seira-page-title class="w-100">
		<div id="row-titulo" class="row">
			<div
				id="titulo-pagina-rede-monitoramento"
				class="col-12 col-sm-6 justify-content-center">
				<span>
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Rede de monitoramento
				</span>
			</div>
			<div class="col-12 col-sm-6 justify-content-center">
				<div id="sobre-e-opcoes" class="col-12 d-flex">
					<div class="flex">
						<seira-public-page-about
							class="text-titulo"
							[textosInformativos]="informacoesGerais" />
					</div>
					<seira-public-a
						*seiraIfViewportSize="'mobile'"
						href
						(click)="abrirModalOpcoesDaPagina($event)">
						<div class="flex flex-wrap content-center">
							<i class="ph-list"></i>
							<span class="text-titulo" id="opcoes-desta-pagina">
								Opções desta página
							</span>
						</div>
					</seira-public-a>
				</div>
			</div>
		</div>
	</seira-page-title>
</div>

<seira-estacoes-monitoramento-mapa
	class="col col-xl-12 col-lg-12 col-12 d-flex flex-column"
	[homePage]="true"></seira-estacoes-monitoramento-mapa>
<ng-template #legendaMapaMobile>
	<seira-legenda-estacoes></seira-legenda-estacoes>
</ng-template>

<div
	class="d-flex w-100 justify-content-start"
	*seiraIfViewportSize="'desktop'">
	<div class="col-9">
		<seira-info-gerais
			id="informacoes-gerais"
			[textosInformativos]="informacoesGerais"></seira-info-gerais>
	</div>
</div>
