<ng-container *ngIf="mode === 'full'; else dropdownMode">
	<ng-container *ngTemplateOutlet="fullMode"></ng-container>
</ng-container>

<ng-template #fullMode>
	<div
		id="btn-group-exportacao"
		class="btn-group align-items-center shadow-lg"
		role="group">
		<ng-container *ngFor="let button of buttons">
			<button
				type="button"
				[style]="
					button.size === 'big' ? 'max-width:70px; max-height: 85px' : ''
				"
				class="btn button-fill btn-primary d-flex align-items-center {{
					getButtonClasses(button)
				}}"
				(click)="button.onClick()"
				[disabled]="disabledButtons">
				<i
					[ngStyle]="{ fontSize: getIconSize(button) }"
					class="{{ button.icon }}"></i>
				{{ button.label }}
			</button>
			<seira-vertical-separator
				*ngIf="buttons.indexOf(button) !== buttons.length - 1"
				color="rgba(255, 255, 255, 0.25)"></seira-vertical-separator>
		</ng-container>
	</div>
</ng-template>

<ng-template #dropdownMode>
	<div
		dropdown
		#dropdown="bs-dropdown"
		class="dropdown"
		[container]="dropdownContainer">
		<button
			class="btn btn-primary button-fill shadow-lg"
			type="button"
			id="exportMenuButton"
			dropdownToggle>
			Exportar
			<i
				class="ms-2"
				[class.ph-caret-up]="dropdown.isOpen"
				[class.ph-caret-down]="!dropdown.isOpen"></i>
		</button>
		<div
			*dropdownMenu
			class="dropdown-menu"
			style="height: 135px"
			aria-labelledby="exportMenuButton">
			<a
				*ngFor="let button of buttons"
				class="dropdown-item text-primary"
				role="button"
				(click)="button.onClick()">
				<i class="{{ button.icon }} me-1"></i> {{ button.label }}
			</a>
		</div>
	</div>
</ng-template>
