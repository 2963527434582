<div>
	<div class="leaft-control-container">
		<div
			style="max-width: 100%"
			class="mapa-interpolacao rounded"
			[ngStyle]="{ opacity: carregando ? 0.5 : 1 }"
			[id]="mapaId"
			leaflet
			[leafletOptions]="options"
			(leafletMapReady)="setupMap($event)"
			#mapaDiv>
			<div *seiraIfViewportSize="'desktop'">
				<div *ngIf="mapaId === 'mapa-interpolacao'" class="legenda-pcd">
					<seira-monitoramento-legenda-pcd> </seira-monitoramento-legenda-pcd>
				</div>
				<div *ngIf="mapaId === 'desvio'"></div>
				<div class="buttons" *ngIf="exportButtons.length > 0">
					<seira-public-button-group
						[buttons]="exportButtons"
						mode="dropdown"
						dropdownContainer="#map-container"></seira-public-button-group>
				</div>
			</div>
			<div *ngIf="carregando" class="loading-overlay">
				<seira-loading></seira-loading>
			</div>
			<ng-content />
		</div>
	</div>
	<seira-monitoramento-legenda-horizontal
		*ngIf="!isLegendaOculta"
		[mapaNome]="mapaNome"
		[valores]="interpolacao ? interpolacao.legenda : valores"
		[carregando]="carregando">
	</seira-monitoramento-legenda-horizontal>
</div>
