import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import {
	Estacao,
	Municipio,
} from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';

import {
	capitalizeFirstLetter,
	compararStrings,
	numberToBrNumber,
} from '@utils';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Select } from '@layout/interfaces/select';
import { Agrupamento, Meses } from '../../../interfaces';
import {
	exportarCSV,
	exportarPDF,
	exportarTXT,
	exportarXLSX,
} from '@home/submodulos/dados-meteorologicos/utils/exportacao-tabelas';

@Component({
	selector: 'seira-tabela-climatologia',
	templateUrl: './tabela-climatologia.component.html',
	styleUrls: ['./tabela-climatologia.component.scss'],
})
export class TabelaClimatologiaComponent
	implements OnDestroy, InstanciaRelatorio
{
	@ViewChild('tabelaClimatologia', { static: false })
	tabela: PublicTableComponent;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaClimatologia?: any[];
	loading = true;
	periodo = '';
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	tituloTabela = '';
	descricaoRelatorio =
		'Define-se como climatologia pluviométrica, a média histórica de pluviometria para períodos longos, preferencialmente de 30 anos.';

	dadosColunas: any[] = [];
	colunas: ADTColumns[] = [];
	_destroyed = new Subject();
	colunasSelecionadas: string[] = [];
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	meses = [
		{ n: 1, mes: 'Janeiro' },
		{ n: 2, mes: 'Fevereiro' },
		{ n: 3, mes: 'Março' },
		{ n: 4, mes: 'Abril' },
		{ n: 5, mes: 'Maio' },
		{ n: 6, mes: 'Junho' },
		{ n: 7, mes: 'Julho' },
		{ n: 8, mes: 'Agosto' },
		{ n: 9, mes: 'Setembro' },
		{ n: 10, mes: 'Outubro' },
		{ n: 11, mes: 'Novembro' },
		{ n: 12, mes: 'Dezembro' },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () =>
				exportarPDF(
					this.dadosTabelaClimatologia,
					this.tabela,
					this.periodoAgrupamento,
					this.tituloTabela
				),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () =>
				exportarCSV(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () =>
				exportarTXT(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
		{
			label: '.xlsx',
			size: 'small',
			icon: 'ph-file-xls',
			onClick: () =>
				exportarXLSX(this.tabela, this.periodoAgrupamento, this.tituloTabela),
		},
	];
	disabledSelectList = false;
	tituloColunaAgrupamento = 'Nome';
	periodoTitulo = '';
	periodoAgrupamento = '';

	constructor(
		private toastrService: ToastrService,
		private relatoriosService: RelatoriosService
	) {
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.colunasSelecionadas = this.opcoesColunas
			.filter(option => option.isFixed)
			.map(option => option.name);
		this.setValidators();
	}
	form?: FormGroup<any> | undefined;
	regioes?: Select<string>[] | undefined;
	microrregioes?: Select<string>[] | undefined;
	mesorregioes?: Select<string>[] | undefined;
	municipios?: Municipio[] | undefined;
	precipitacao?: Select<string>[] | undefined;

	setColunasSelecionadas(colunas: string[]) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.MES_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MES_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	setValidators() {
		this.inputs.form
			.get(FormularioRelatorio.MES_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MES_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		this.tituloColunaAgrupamento =
			Agrupamento[agrupamento as keyof typeof Agrupamento];
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: 'nome',
					title: 'Município/Posto',
					type: 'string',
					className: 'text-left',
				},
				{
					data: 'latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
				},
				{
					data: 'microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
				},
				{
					data: 'subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'string',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'nmes',
					title: 'Mês',
					className: 'text-center',
					type: 'number',
					render: (data: number, type: string) => {
						return type === 'display' ? this.meses[data - 1]?.mes : data;
					},
				},
				{
					data: 'climatologia',
					title: 'Climatologia (mm)',
					className: 'text-center',
				},
			]
		);
	}

	gerarRelatorio(): void {
		if (this.inputs.form.invalid) {
			return;
		}

		this.periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		const mesInicio = this.inputs.form.get(FormularioRelatorio.MES_INICIO)
			?.value;
		const mesFim = this.inputs.form.get(FormularioRelatorio.MES_FIM)?.value;

		if (!mesInicio || !mesFim) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);

		const mesInicioFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesInicio)
		);
		const mesFimFormatado = Object.values(Meses).find(mes =>
			compararStrings(mes, mesFim)
		);

		this.periodo = ` ${mesInicioFormatado} a ${mesFimFormatado}`;
		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;
		this.tituloTabela = `Climatologia - ${this.periodo}`;

		this.relatoriosService
			.buscarClimatologiaPorMunicipio(
				mesInicio.toLowerCase(),
				mesFim.toLowerCase(),
				this.tipoAgrupamento
			)
			.subscribe({
				next: climatologias => {
					this.relatoriosService.verificaExistenciaDados(climatologias);
					const agrupamento: string = this.tipoAgrupamento;
					if (agrupamento == 'MUNICIPIO_POSTO') {
						this.dadosColunas = this.ordenaMes(climatologias, false);
					} else {
						this.dadosColunas = this.ordenaMes(climatologias, true);
					}
					this.atualizarTituloColuna();
					this.atualizarColunasTabela();
					this.dadosTabelaClimatologia = this.padronizarResultados(
						this.dadosColunas,
						agrupamento
					);
				},
				error: () => {
					this.toastrService.error(
						'Erro ao gerar tabela de Climatologia, tente novamente'
					);
					this.inputs.setLoading(false);
					this.loading = false;
					this.dadosTabelaClimatologia = undefined;
				},
				complete: () => {
					this.inputs.setLoading(false);
					this.loading = false;
				},
			});
	}

	private padronizarResultados(
		resultados: ClimatologiaResponseDTO[],
		agrupamento: string
	) {
		return resultados.map(resultado => {
			const base = {
				nome: resultado.nome,
				mes: resultado.mes,
				nmes: resultado.nmes,
				climatologia: numberToBrNumber(resultado.climatologia, 1),
			};

			return agrupamento === 'MUNICIPIO_POSTO'
				? {
						...base,
						...this.obterDetalhesPosto(resultado.nome, resultado.dadosPosto),
				  }
				: base;
		});
	}

	private obterDetalhesPosto(nome: string, dadosPosto: any) {
		return {
			nome:
				nome === dadosPosto.municipio
					? nome
					: `${dadosPosto.municipio}/${nome}`,
			municipio: dadosPosto.municipio || '-',
			latitude: numberToBrNumber(dadosPosto.latitude, 4) || '-',
			longitude: numberToBrNumber(dadosPosto.longitude, 4) || '-',
			altitude: numberToBrNumber(dadosPosto.altitude, 1) || '-',
			microrregiao: dadosPosto.microrregiao || '-',
			mesorregiao: dadosPosto.mesorregiao || '-',
			regiao: dadosPosto.regiao || '-',
			bacia: dadosPosto.bacia || '-',
			subbacia: dadosPosto.subbacia || '-',
		};
	}

	getFormItemValue(field: string) {
		return this.inputs.form.get(field)!.value;
	}

	private ordenaMes(dados: any[], possuiNome: boolean) {
		dados.forEach(item => {
			if (item.mes === 'marco') {
				item.mes = 'Março';
			} else {
				item.mes = capitalizeFirstLetter(item.mes);
			}

			const mesEncontrado = this.meses.find(m => m.mes === item.mes);
			if (mesEncontrado) {
				item.nmes = mesEncontrado.n;
			}
		});

		const dadosOrdenados = dados.sort((a, b) => {
			const municipioA = possuiNome ? a?.nome : a?.dadosPosto?.municipio;
			const municipioB = possuiNome ? b?.nome : a?.dadosPosto?.municipio;
			const nomeComparacao = municipioA.localeCompare(municipioB);

			if (nomeComparacao !== 0) {
				return nomeComparacao;
			}

			return a.nmes - b.nmes;
		});

		return dadosOrdenados;
	}
}
