import {
	Component,
	HostListener,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core';

@Component({
	selector: 'seira-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
	@Input() cardHeaderBackgroud = '';
	@Input() iconHeader = '';
	@Input() textHeader = '';
	@Input() footerContent?: TemplateRef<void>;
	@Input() bodyClass?: string = '';
	@Input() hasButton?: boolean = false;
	@Input() buttonLabel?: string = '';
	@Input() buttonLink?: string = '';
	@Input() hasSecondaryButton = false;
	@Input() secondaryButtonLabel = '';
	@Input() secondaryButtonLink = '';
	@Input() secondaryButtonIcon = '';
	isSmallScreen = false;
	resolutionSmall = 730;

	ngOnInit(): void {
		this.checkScreenSize();
	}

	@HostListener('window:resize')
	onResize(): void {
		this.checkScreenSize();
	}

	private checkScreenSize(): void {
		this.isSmallScreen = window.innerWidth <= this.resolutionSmall;
	}
}
