import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';

@Component({
	selector: 'seira-select-postos-relatorio',
	templateUrl: './select-postos-relatorio.component.html',
	styleUrls: ['./select-postos-relatorio.component.scss'],
})
export class SelectPostosRelatorioComponent implements OnInit {
	@Input() modoSelecao: 'lote' | 'individual' = 'lote';
	@Input() postos: PostosRelatorios[] = [];
	@Input() selectedPostos: PostosRelatorios[] = [];
	@Output() postosSelecionados = new EventEmitter<PostosRelatorios[]>();

	ngOnInit() {
		this.setSelectedPostos();
	}

	setSelectedPostos() {
		this.selectedPostos = this.postos.filter(posto => posto.selected);
		this.postosSelecionados.emit(this.selectedPostos);
	}

	get textSelecteds() {
		const postos = this.postos.length;
		return `${this.selectedPostos.length} de ${postos} posto${
			postos !== 1 ? 's' : ''
		} selecionado${postos !== 1 ? 's' : ''}`;
	}

	select(index: number) {
		this.postos[index].selected = true;
		this.setSelectedPostos();
	}

	remove(index: number) {
		this.postos[index].selected = false;
		this.setSelectedPostos();
	}

	selectAll() {
		for (const posto of this.postos) {
			posto.selected = true;
		}
		this.setSelectedPostos();
	}

	removeAll() {
		for (const posto of this.postos) {
			posto.selected = false;
		}
		this.setSelectedPostos();
	}
}
