import { Historico } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';

export interface InformacaoSensores {
	indice: number;
	atributo: string;
	nome: string;
	unidadeDeMedida: string;
}
export interface DadosRelatorioVariaveis {
	informacaoSensores: InformacaoSensores[] | [];
	historico: Historico[] | [];
	municipio: string;
	nomeposto: string;
}
export interface DadosConsultaTodosOsSensores {
	tipo: TipoEstacao;
	diaInicio: string;
	diaFim: string;
}
export interface DadosPluviometriaRelatorioSudene {
	posto: string;
	valor: number;
	id: number;
	data: Date;
	latitude: number;
	longitude: number;
	altitude: number;
}

export interface PostoSudene {
	codigo: number;
	latitude: number;
	longitude: number;
	altitude: number;
	nome: string;
	id: number;
}

export interface IBaseRelatorioDesvio {
	desvioMilimetro: number;
	desvioPorcentagem: number;
	latitude: number;
	longitude: number;
	altitude: number;
	municipio: string;
	estacao: string;
	bacia: string;
	subBacia: string;
	regiaoPluviometrica: string;
	mesorregiao: string;
	microrregiao: string;
}

export interface RelatorioDesvio extends IBaseRelatorioDesvio {
	desvios: DadosDesvioRelatorio[];
}

/**
 * @description Essa classe foi criada para suprir a necessidade do agrupamento Município/Posto
 * que a estrutura de dados que é enviada do backend não supria os padrões do frontend para
 * colunas dinamicas corretamente.
 *
 * @todo Refatorar os métodos no backend para retornar de forma correta os dados
 */
export class RelatorioDesvioMunicipioDTO implements IBaseRelatorioDesvio {
	desvioMilimetro: number;
	desvioPorcentagem: number;
	data: Date;
	latitude: number;
	longitude: number;
	altitude: number;
	municipio: string;
	estacao: string;
	bacia: string;
	subBacia: string;
	regiaoPluviometrica: string;
	mesorregiao: string;
	microrregiao: string;

	constructor(
		private readonly relatorio: RelatorioDesvio,
		private readonly dadosDesvios: DadosDesvioRelatorio
	) {
		this.desvioMilimetro = this.dadosDesvios.desvioMilimetro;
		this.desvioPorcentagem = this.dadosDesvios.desvioPorcentagem;
		this.data = this.dadosDesvios.data;
		this.latitude = this.relatorio.latitude;
		this.longitude = this.relatorio.longitude;
		this.altitude = this.relatorio.altitude;
		this.municipio = this.relatorio.municipio;
		this.microrregiao = this.relatorio.microrregiao;
		this.mesorregiao = this.relatorio.mesorregiao;
		this.regiaoPluviometrica = this.relatorio.regiaoPluviometrica;
		this.bacia = this.relatorio.bacia;
		this.estacao = this.relatorio.estacao;
		this.subBacia = this.relatorio.subBacia;
	}
}

export interface DadosDesvioRelatorio {
	desvioMilimetro: number;
	desvioPorcentagem: number;
	climatologia: number;
	chuva: number;
	data: Date;
}
